//////////////////////////////////////////
//		  ooOOOO BOILERPLATE FILE		//
//		 oo		 _____					//
//		_I__n_n__||_|| ________			//
//	  >(_________|_7_|-|______|			//
//	   /o ()() ()() o   oo  oo			//
//////////////////////////////////////////

///////////////////////////////
// Description
///////////////////////////////

	/*
		DESCRIPTION / USAGE:
			Example documentation for tables

		TODO:

	*/


///////////////////////////////
// Imports
///////////////////////////////

import {
	DatabaseRef_Items_Collection
} from 'rfbp_aux/services/database_endpoints/standard_database_endpoints' // OUTSIDE BOILERPLATE
import {
	ComponentDocumentation,
	TsInterface_ComponentDocumentation
} from 'rfbp_core/components/_example_components'
import {
	Icon
} from 'rfbp_core/components/icons'
import {
	TableBasic,
	TableCellBasic,
	TableCellManage,
	TableDatabase,
	TsInterface_TableAdditionalData,
	TsInterface_TableColumns,
	TsInterface_TableData,
	TsInterface_TableDatabaseEndpointQueryObject,
	TsInterface_TableDatabaseSettings,
	TsInterface_TableDataRow,
	TsInterface_TableHooks,
	TsInterface_TableSettings,
	TsType_TableDatabaseEndpoint
} from 'rfbp_core/components/table'
import {
	generateDatabaseQuery,
	TsInterface_OrderByArray,
	TsInterface_QueryCursorsObject,
	TsInterface_QueryOperatorsArray
} from 'rfbp_core/services/database_management'
import {
	getProp
} from 'rfbp_core/services/helper_functions'
import {
	TsType_JSX
} from 'rfbp_core/typescript/global_types'
import {
	Box,
	Typography
} from '@mui/material/'

///////////////////////////////
// Typescript
///////////////////////////////

	interface TsInterface_ComponentProps {}


///////////////////////////////
// Variables
///////////////////////////////

	// Displayed Translatable Strings
	// { sort-start } - displayed text - scoped sort plugin

	// { sort-end } - displayed text

	// Html strings
	const TableBasicHtmlExample =
	`    <TableBasic
	tableAdditionalData={ tableAdditionalData }
	tableColumns={ tableColumns }
	tableData={ tableData }
	tableSettings={ tableSettings }
	/>`

	const TableDatabaseHtmlExample =
	`    <TableDatabase
	tableAdditionalData={ tableAdditionalData }
	tableColumns={ tableColumns }
	tableDatabaseEndpoint={ tableDatabaseEndpoint }
	tableSettings={ tableSettings }
	/>`

///////////////////////////////
// Functions
///////////////////////////////


///////////////////////////////
// Component
///////////////////////////////

	export const ExampleTables = ( props: TsInterface_ComponentProps ): TsType_JSX => {

		// Props

		// Hooks - useContext, useState, useReducer, other
		// { sort-start } - hooks

		// { sort-end } - hooks

		// Hooks - useEffect

		// Other Variables
		const tableBasicAdditionalData: TsInterface_TableAdditionalData = {}
		const tableBasicColumns: TsInterface_TableColumns = {
			name: {
				header: {
					header_jsx: ( tableAdditionalData: TsInterface_TableAdditionalData ) => { return <Typography className="tw-font-bold">Name</Typography> },
					header_css: ( tableAdditionalData: TsInterface_TableAdditionalData ) => { return "tw-text-right tw-bg-success_main" },
					header_sort_by: "name"
				},
				cell: {
					cell_jsx: ( rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks ) => { return <>{rowData.name}</> },
				}
			},
			email: {
				header: {
					header_jsx: ( tableAdditionalData: TsInterface_TableAdditionalData ) => { return <Typography className="tw-font-bold">Email</Typography> },
					header_sort_by: "email"
				},
				cell: {
					cell_jsx: ( rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks ) => { return <>{rowData.email}</> },
				},
			},
			phone: {
				header: {
					header_jsx: ( tableAdditionalData: TsInterface_TableAdditionalData ) => { return <Typography className="tw-font-bold">Phone</Typography> },
					header_sort_by: "phone"
				},
				cell: {
					cell_jsx: ( rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks ) => { return <>{rowData.phone}</> },
				}
			},
			status: {
				header: {
					header_jsx: ( tableAdditionalData: TsInterface_TableAdditionalData ) => { return <Typography className="tw-font-bold">Status</Typography> },
					header_sort_by: "status"
				},
				cell: {
					cell_jsx: ( rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks ) => {
						let cellJSX = <></>
						if ( rowData.status === "active" ){
							cellJSX =
							<Box className="tw-text-left tw-my-1">
								<Box component="span" className="tw-p-2 tw-bg-success_main tw- tw-rounded-lg tw-text-center tw-text-white">
									ACTIVE
								</Box>
							</Box>
						} else if ( rowData.status === "inactive" ){
							cellJSX =
							<Box className="tw-text-left tw-my-1">
								<Box component="span" className="tw-p-2 tw-bg-gray_600 tw- tw-rounded-lg tw-text-center tw-text-white">
									INACTIVE
								</Box>
							</Box>
						} else if ( rowData.status === "deleted" ){
							cellJSX =
							<Box className="tw-text-left tw-my-1">
								<Box component="span" className="tw-p-2 tw-bg-error_main tw- tw-rounded-lg tw-text-center tw-text-white">
									LOCKED
								</Box>
							</Box>
						}
						return cellJSX
					},
					cell_css: ( rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData ) => {
						let cellCSS = ""
						if (rowData.status === "active"){
							cellCSS = "tw-bg-success_wash"
						}
						return cellCSS
					}
				},
			},
			role: TableCellBasic("role", "Role", null),
			manage: TableCellManage({
				view: {
					icon: <Icon icon="magnifying-glass"/>,
					label: <>View</>,
					onClick: ( rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks ) => {

					}
				},
				edit: {
					icon: <Icon icon="pen-to-square"/>,
					label: <>Edit</>,
					onClick: ( rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks ) => {

					}
				},
			})
		}
		const tableBasicData: TsInterface_TableData = [
			{ name: "John Doe 1", email: "A@email.com", phone: "100-00-0000", status: "active", role: "admin" },
			{ name: "John Doe 2", email: "S@email.com", phone: "400-00-0000", status: "inactive", role: "locked" },
			{ name: "John Doe 3", email: "D@email.com", phone: "200-00-0000", status: "active", role: "basic" },
			{ name: "John Doe 4", email: "F@email.com", phone: "300-000-000", status: "deleted", role: "deleted" },
			{ name: "John Doe 5", email: "B@email.com", phone: "250-000-000", status: "active", role: "basic" },
		]
		const tableBasicSettings: TsInterface_TableSettings = {
			size: "small",
			sortable: true,
			sort_property_default: "email",
			paginated: true,
			pagination_rows_per_page_default: 10,
			pagination_rows_per_page_options: [ 10, 25, 50, 100 ],
		}

		const tableDatabaseAdditionalData: TsInterface_TableAdditionalData = {}


		// TODO - just move this stuff to tableSettings?

		const tableDatabaseEndpoint: TsType_TableDatabaseEndpoint = ( queryGenerationData: TsInterface_TableDatabaseEndpointQueryObject, tableAdditionalData: TsInterface_TableAdditionalData ) => {
			// usually get client key here
			let clientKey = "jmorrell"
			let queryOperatorsArray: TsInterface_QueryOperatorsArray = []
			let orderByArray: TsInterface_OrderByArray = [ { prop: "name", desc: false } ]
			let queryCursorsObject: TsInterface_QueryCursorsObject = {}
			if (queryGenerationData["startAfter"] != null){	 	queryCursorsObject["startAfter"] = queryGenerationData.startAfter }
			if (queryGenerationData["startAt"] != null){ 		queryCursorsObject["startAt"] = queryGenerationData.startAt }
			if (queryGenerationData["endAt"] != null){ 			queryCursorsObject["endAt"] = queryGenerationData.endAt }
			if (queryGenerationData["endBefore"] != null){ 		queryCursorsObject["endBefore"] = queryGenerationData.endBefore }
			let limit = getProp( queryGenerationData, "limit", 5 )
			return generateDatabaseQuery(
				DatabaseRef_Items_Collection( clientKey ),
				queryOperatorsArray,
				orderByArray,
				queryCursorsObject,
				limit
			)
		}
		const tableDatabaseColumns: TsInterface_TableColumns = {
			name: TableCellBasic("name", "Name", "name"),
			key: TableCellBasic("key", "Key", null),
			status: TableCellBasic("status", "Status", null),
			manage: TableCellManage({
				view: {
					icon: <Icon icon="magnifying-glass"/>,
					label: <>View</>,
					onClick: ( rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks ) => {
					}
				},
			})
		}
		const tableDatabaseSettings: TsInterface_TableDatabaseSettings = {
			size: "small",
			rows_per_page: 100,
			sort_direction: "asc",
			sort_property: "name",
		}

		const documentationObject: TsInterface_ComponentDocumentation = {
			group_name: "Tables",
			group_description: "Various kinds of tables",
			components: {
				TableBasic: {
					component_name:
					<Box component="span">
						<span className="tw-font-bold tw-inline-block tw-align-top tw-normal-case">TableBasic</span>
					</Box>,
					component_description: "Simple table where all data must be loaded into the current state to operate",
					component_example_jsx_string: TableBasicHtmlExample,
					component_jsx:
					<Box>
						 <TableBasic
							tableAdditionalData={tableBasicAdditionalData}
							tableColumns={tableBasicColumns}
							tableData={tableBasicData}
							tableSettings={tableBasicSettings}
						/>
					</Box>,
					component_props: {
						tableAdditionalData: {
							propKey: "tableAdditionalData",
							propTypeOrInterface: "TsInterface_TableAdditionalData",
							propDescription: "Any additional data that may be needed for internal functions can be passed in via this object (i.e. info from a form on other state in the container)",
							propExampleValue: tableBasicAdditionalData
						},
						tableColumns: {
							propKey: "tableColumns",
							propTypeOrInterface: "TsInterface_TableColumns",
							propDescription: "The config object that specifies the table columns (check file since the jsx props don't render on the preview very well)",
							propExampleValue: tableBasicColumns
						},
						tableData: {
							propKey: "tableData",
							propTypeOrInterface: "TsInterface_TableData",
							propDescription: "The data that is rendered as rows in the table",
							propExampleValue: tableBasicData
						},
						tableSettings: {
							propKey: "tableSettings",
							propTypeOrInterface: "TsInterface_TableSettings",
							propDescription: "All other configurations like pagination, sorts, header, etc that are independant of an individual column",
							propExampleValue: tableBasicSettings
						},
					},
					component_todo: {
						1: "Nothing?",
					}
				},
				TableDatabase: {
					component_name:
					<Box component="span">
						<span className="tw-font-bold tw-inline-block tw-align-top tw-normal-case">TableDatabase</span>
					</Box>,
					component_description: "Table that pulls directly from a collection or query database endpoint",
					component_example_jsx_string: TableDatabaseHtmlExample,
					component_jsx:
					<Box>
						<TableDatabase
							tableAdditionalData={tableDatabaseAdditionalData}
							tableColumns={tableDatabaseColumns}
							tableDatabaseEndpoint={ tableDatabaseEndpoint }
							tableSettings={tableDatabaseSettings}
						/>
					</Box>,
					component_props: {
						tableAdditionalData: {
							propKey: "tableAdditionalData",
							propTypeOrInterface: "TsInterface_TableAdditionalData",
							propDescription: "Any additional data that may be needed for internal functions can be passed in via this object (i.e. info from a form on other state in the container)",
							propExampleValue: tableDatabaseAdditionalData
						},
						tableColumns: {
							propKey: "tableColumns",
							propTypeOrInterface: "TsInterface_TableColumns",
							propDescription: "The config object that specifies the table columns (check file since the jsx props don't render on the preview very well)",
							propExampleValue: tableDatabaseColumns
						},
						tableDatabaseEndpoint: {
							propKey: "tableDatabaseEndpoint",
							propTypeOrInterface: "TsInterface_TableDatabaseEndpoint",
							propDescription: "A function that takes an object of query markers, loimit, etc and returns a database query that is used to pull live collection data",
							propExampleValue: "tableDatabaseEndpoint"
						},
						tableSettings: {
							propKey: "tableSettings",
							propTypeOrInterface: "TsInterface_TableSettings",
							propDescription: "All other configurations like pagination, sorts, header, etc that are independant of an individual column",
							propExampleValue: tableDatabaseSettings
						},
					},
					component_todo: {
						1: "Nothing?",
					}
				},
			}
		}

		// Functions



		// JSX Generation
		const returnJSX_Component = (): TsType_JSX => {
			let componentJSX =
			<Box>
				<ComponentDocumentation documentation={ documentationObject } />
			</Box>
			return componentJSX
		}

		// Render
		return <>{ returnJSX_Component() }</>
	}