//////////////////////////////////////////
//		  ooOOOO BOILERPLATE FILE		//
//		 oo		 _____					//
//		_I__n_n__||_|| ________			//
//	  >(_________|_7_|-|______|			//
//	   /o ()() ()() o   oo  oo			//
//////////////////////////////////////////

///////////////////////////////
// Description
///////////////////////////////

	/*
		DESCRIPTION / USAGE:
			Helper Functions related to chats

		TODO:

	*/


///////////////////////////////
// Imports
///////////////////////////////

import {
	Trans
} from 'react-i18next'
import {
	TsInterface_ChatThread,
	TsInterface_StringAvatar
} from 'rfbp_core/components/chat'
import {
	returnFormattedDate,
	returnTimestampFromUnknownDateFormat
} from 'rfbp_core/services/helper_functions'
import {
	TsType_Date,
	TsType_JSX,
	TsType_Number,
	TsType_String,
	TsType_Undefined
} from 'rfbp_core/typescript/global_types'
import {
	Avatar,
	AvatarGroup,
	Badge
} from '@mui/material/'

///////////////////////////////
// Typescript
///////////////////////////////


///////////////////////////////
// Variables
///////////////////////////////

	// Displayed Translatable Strings
	// { sort-start } - displayed text - scoped sort plugin
	const s_NO_MESSAGE = <Trans>No Message</Trans>
	const s_YESTERDAY = <Trans>Yesterday</Trans>
	// { sort-end } - displayed text


///////////////////////////////
// Functions
///////////////////////////////


///////////////////////////////
// Exports
///////////////////////////////

	export const stringAvatar = ( name: TsType_String ): TsInterface_StringAvatar => {
		let initials: TsType_String
		if ( name != null && typeof name.split === "function" && name.split(' ')[0] != null && name.split(' ')[1] ){
			initials = `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`
		} else if ( name != null && typeof name.split === "function" && name.split(' ')[0] != null ) {
			initials = `${name.split(' ')[0][0]}`
		} else {
			initials = "?"
			name = "?"
		}
		return {
			sx: {
				bgcolor: stringToColor( name ),
			},
			children: initials
		}
	}

	export const stringAvatarWithSpecificColor = ( name: TsType_String, color: TsType_String ): TsInterface_StringAvatar => {
		let initials: TsType_String
		if ( name != null && typeof name.split === "function" && name.split(' ')[0] != null && name.split(' ')[1] ){
			initials = `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`
		} else if ( name != null && typeof name.split === "function" && name.split(' ')[0] != null ) {
			initials = `${name.split(' ')[0][0]}`
		} else {
			initials = "?"
			name = "?"
		}
		return {
			sx: {
				bgcolor: color,
			},
			children: initials
		}
	}

	export const stringToColor = ( string: TsType_String ): TsType_String => {
		let hash: TsType_Number = 0
		let color: TsType_String = '#'
		if ( string == null ){ string = "" }
		for ( let i = 0; i < string.length; i += 1 ) {
			hash = string.charCodeAt( i ) + (( hash << 5 ) - hash)
		}
		for ( let i = 0; i < 3; i += 1 ) {
			const value = (hash >> ( i * 8 )) & 0xff
			color += `00${value.toString( 16 )}`.substr( -2 )
		}
		return color
	}

	export const returnFormattedDateKey = ( date: TsType_Date ): TsType_String => {
		let day: TsType_Number = date.getDate()
		let month: TsType_Number = date.getMonth() + 1
		let year: TsType_Number = date.getFullYear()
		let compositeDate: TsType_String = year.toString() + "-"
		if ( month < 10 ){
			compositeDate += "0" + month.toString() + "-"
		} else {
			compositeDate += month.toString() + "-"
		}
		if ( day < 10 ){
			compositeDate += "0" + day.toString()
		} else {
			compositeDate += day.toString()
		}
		return compositeDate
	}

	export const getRelativeTime = ( date: TsType_Date ): TsType_String | TsType_JSX => {
		let relativeTime: TsType_String | TsType_JSX = <></>
		let timestamp = returnTimestampFromUnknownDateFormat(date)
		// Today
		let todayDate = new Date()
		let todayDay = todayDate.getDate()
		let todayMonth = todayDate.getMonth()
		let todayYear = todayDate.getFullYear()
		let yesterdayDateSanitized = new Date( todayYear, todayMonth, todayDay - 1 ).getTime()
		// Timestamp
		let timestampDate = new Date( timestamp )
		let timestampDay = timestampDate.getDate()
		let timestampMonth = timestampDate.getMonth()
		let timestampYear = timestampDate.getFullYear()
		let timestampDateSanitized = new Date( timestampYear, timestampMonth, timestampDay ).getTime()
		// Set Relative Time String
		if ( todayDay === timestampDay && todayMonth === timestampMonth && todayYear === timestampYear ){
			relativeTime = returnFormattedDate(timestamp, 'h:mm a' )
		} else if ( yesterdayDateSanitized === timestampDateSanitized ) {
			relativeTime = s_YESTERDAY
		} else {
			relativeTime = returnFormattedDate(timestamp, 'M/D/YY' )
		}
		return relativeTime
	}

	export const returnJSX_Avatar = ( threadMembers: TsInterface_ChatThread["associated_member_names"], avatarClass: TsType_String, unreadCount: TsType_Number, associatedViewerKey: TsType_String ): TsType_JSX => {
		// Generate sorted list of other thread memebers
		let cleanThreadMembers = []
		for ( let userKey in threadMembers ){
			if ( userKey !== associatedViewerKey ){
				cleanThreadMembers.push( threadMembers[ userKey ] )
			}
		}
		cleanThreadMembers.sort()
		// Generate jsx avatar group
		let avatarGroupJSX =
		<Badge color="error" overlap="circular" badgeContent={unreadCount} invisible={unreadCount === 0} anchorOrigin={{ vertical: 'top', horizontal: 'left'}}>
			<AvatarGroup max={ 2 } classes={{ avatar: avatarClass }}>
				{cleanThreadMembers.map(( name ) => (
					<Avatar key={name} {...stringAvatar( name )} sx={{ bgcolor: stringToColor( name ) }} />
				))}
			</AvatarGroup>
		</Badge>

		return avatarGroupJSX
	}

	export const generateMessageThreadName = ( threadNameOverride: TsType_String | TsType_Undefined, threadMembers: TsInterface_ChatThread["associated_member_names"], associatedViewerKey: TsType_String ): TsType_String => {
		let threadName
		if ( threadNameOverride == null ){
			let cleanThreadMembersString = ""
			let cleanThreadMembers = []
			for ( let userKey in threadMembers ){
				if ( userKey !== associatedViewerKey ){
					cleanThreadMembers.push( threadMembers[ userKey ] )
				}
			}
			cleanThreadMembers.sort()
			let memberCount = 0
			for ( let memberIndex in cleanThreadMembers ){
				if ( memberCount > 0 ){
					cleanThreadMembersString += ", "
				}
				let memberName = cleanThreadMembers[memberIndex]
				cleanThreadMembersString += memberName
				memberCount++
			}
			threadName = cleanThreadMembersString
		} else {
			threadName = threadNameOverride
		}
		return threadName
	}

	export const generateLastMessageText = ( messageThread: TsInterface_ChatThread ): TsType_JSX => {
		let lastMessageJSX
		if ( messageThread.last_message_timestamp != null && messageThread.last_message_text != null ){
			lastMessageJSX =
			<span>
				<span>{ getRelativeTime( messageThread.last_message_timestamp ) }: </span>
				<span>{ messageThread.last_message_text }</span>
			</span>
		} else {
			lastMessageJSX =
			<span>
				<em className="tw-opacity-50">{ s_NO_MESSAGE }</em>
			</span>
		}
		return lastMessageJSX
	}