//======================================//
//		  ooOOOO USED BY BOILERPLATE    //
//		 oo		 _____					//
//		_I__n_n__||_|| ________			//
//	  >(_________|_7_|-|______|			//
//	   /o ()() ()() o   oo  oo			//
//======================================//

///////////////////////////////
// Description
///////////////////////////////

	/*
		DESCRIPTION / USAGE:
			Used to Initialize Firebase Instance

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////


import {
	initializeApp
} from 'firebase/app'

///////////////////////////////
// Variables
///////////////////////////////

	const firebaseConfig = {
		apiKey: "AIzaSyB4ooZgS4RYPKQ1qrxI8MtBc-3B4a6K3zs",
		authDomain: "john-morrell.firebaseapp.com",
		projectId: "john-morrell",
		storageBucket: "john-morrell.appspot.com",
		messagingSenderId: "810348637006",
		appId: "1:810348637006:web:5b0ac4aff84ecf49520c1f",
		measurementId: "G-YMS8E7N359"
	}


///////////////////////////////
// Functions
///////////////////////////////

	initializeApp(firebaseConfig)


///////////////////////////////
// Exports
///////////////////////////////

	export default function FirebaseInit(){
		// Initialization occurs just from loading file BEFORE app.js file is loaded
	}