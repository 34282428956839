//////////////////////////////////////////
//		  ooOOOO BOILERPLATE FILE		//
//		 oo		 _____					//
//		_I__n_n__||_|| ________			//
//	  >(_________|_7_|-|______|			//
//	   /o ()() ()() o   oo  oo			//
//////////////////////////////////////////

///////////////////////////////
// Description
///////////////////////////////

	/*
		DESCRIPTION / USAGE:
			Vertical tabs with nav on left

		TODO:

	*/


///////////////////////////////
// Imports
///////////////////////////////

import PropTypes from 'prop-types'
import {
	useState
} from 'react'
import {
	themeVariables
} from 'rfbp_aux/config/app_theme' // OUTSIDE BOILERPLATE
import {
	TsInterface_TabContentArray,
	TsInterface_TabPanelVertical,
	TsInterface_TabsSettingsVertical
} from 'rfbp_core/components/tabs'
import {
	getProp
} from 'rfbp_core/services/helper_functions'
import {
	TsType_JSX,
	TsType_Number
} from 'rfbp_core/typescript/global_types'
import {
	Box,
	Tab,
	Tabs
} from '@mui/material/'

///////////////////////////////
// Typescript
///////////////////////////////

	interface TsInterface_ComponentProps {
		tabs: TsInterface_TabContentArray
		tabsSettings: TsInterface_TabsSettingsVertical
	}


///////////////////////////////
// Variables
///////////////////////////////

	// Displayed Translatable Strings
	// { sort-start } - displayed text - scoped sort plugin

	// { sort-end } - displayed text


///////////////////////////////
// Functions
///////////////////////////////

	// Tabs - Copied from MUI
	const TabPanel = ( props: TsInterface_TabPanelVertical ) => {
		const { children, value, index, settings, ...other } = props
		const tabPanelWidth = "calc(100% - " + settings.nav_width + ")"
		return (
			<Box
				sx={{ "width": tabPanelWidth }}
				role="tabpanel"
				hidden={ value !== index }
				id={`simple-tabpanel-${index}`}
				aria-labelledby={`simple-tab-${index}`}
				{...other}
			>
				{value === index && (
					<Box component='div' sx={{ p: 0 }}>
						{ children }
					</Box>
				)}
			</Box>
		)
	}

	TabPanel.propTypes = {
		children: PropTypes.node,
		index: PropTypes.number.isRequired,
		value: PropTypes.number.isRequired,
	}

	const accessibilityProps = ( index: TsType_Number ) => {
		return {
			id: `simple-tab-${index}`,
			'aria-controls': `simple-tabpanel-${index}`,
		}
	}
	// End Tabs

///////////////////////////////
// Component
///////////////////////////////

	export const TabsVertical = ( props: TsInterface_ComponentProps ): TsType_JSX => {

		// Props
		let pr_tabs: TsInterface_ComponentProps["tabs"] = 							getProp( props, "tabs", [] )
		let pr_tabsSettings: TsInterface_ComponentProps["tabsSettings"] = 			getProp( props, "tabsSettings", {} )
		let pr_tabsColor: TsInterface_ComponentProps["tabsSettings"]["color"] = 	getProp( pr_tabsSettings, "color", "primary" )

		// Hooks - useContext, useState, useReducer, other
		// { sort-start } - hooks
		const [ us_tabIndex, us_setTabIndex ] = 					useState< TsType_Number >(0)
		// { sort-end } - hooks

		// Hooks - useEffect

		// Other Variables

		// Functions



		// JSX Generation
		const returnJSX_Component = (): TsType_JSX => {

			// TODO
			// let tempBackgroundHighlight = "#eeeeee"

			let componentJSX: TsType_JSX =
			<Box sx={{ flexGrow: 1, display: 'flex'}}>
				<Tabs
					value={ us_tabIndex }
					onChange={ ( event, value ) => {
						us_setTabIndex( value )
						if ( pr_tabs != null && pr_tabs[ value ] != null && pr_tabs[ value ]["tabOnChange"] != null ){
							pr_tabs[ value ]["tabOnChange"]?.()
						}
					} }
					aria-label="tabs"
					variant="scrollable"
					scrollButtons="auto"
					orientation="vertical"
					className="bp_vertical_tabs"
					textColor={ pr_tabsColor }
					indicatorColor={ pr_tabsColor }
					sx={{
						borderRight: 1,
						borderColor: 'divider',
						width: pr_tabsSettings.nav_width
					}}
				>
					{pr_tabs.map(( tab, index ) => (
						<Tab key={ index } label={ tab.tabHeader } disabled={ tab.tabDisabled } {...accessibilityProps( index )} />
					))}
				</Tabs>
				{pr_tabs.map(( tab, index ) => (
					<TabPanel key={ index } value={ us_tabIndex } index={ index } settings={ pr_tabsSettings }>
						{ tab.tabContent }
					</TabPanel>
				))}
				{/* <style>{".bp_vertical_tabs button.Mui-selected{ background:" + tempBackgroundHighlight + "; }"}</style> */}
				<style>{".bp_vertical_tabs button.Mui-selected{ background:" + themeVariables.background_highlight + "; }"}</style>
			</Box>
			return componentJSX
		}

		// Render
		return <>{ returnJSX_Component() }</>
	}