///////////////////////////////
// Description
///////////////////////////////

	/*
		DESCRIPTION / USAGE:
			containers are pages / views used in the app and are made up of components and can interact with services and models

		TODO:

	*/


///////////////////////////////
// Imports
///////////////////////////////

import {
	Trans
} from 'react-i18next'
import {
	ApplicationPages
} from 'rfbp_aux/data/application_structure'
import {
	TsType_JSX,
	TsType_String
} from 'rfbp_core/typescript/global_types'
import {
	Box,
	Button
} from '@mui/material/'

///////////////////////////////
// Typescript
///////////////////////////////


///////////////////////////////
// Variables
///////////////////////////////

	// Authenticated Nav Data

	// Displayed Translatable Strings
	// { sort-start } - displayed text - scoped sort plugin
	// const s_PAGE_NAME: TsType_JSX = 		<Trans>Page Name</Trans>
	// { sort-end } - displayed text


///////////////////////////////
// Functions
///////////////////////////////


///////////////////////////////
// Container
///////////////////////////////

	export const Container: React.FC = (): TsType_JSX => {

		// Props
		// const params = useParams()
		// const itemKey: TsType_String = params.id as TsType_String

		// Hooks - useContext, useState, useReducer, other
		// { sort-start } - hooks
		// const [ items, setItems ] = 			useState<TsInterface_UnspecifiedObject>({})
		// const ur_forceRerender = 				useReducer(() => ({}), {})[1] as () => TsType_Void
		// { sort-end } - hooks

		// Hooks - useEffect
		// useEffect(() => { }, [])

		// Other Variables

		// Functions

		// Call Functions

		// JSX Generation
		const returnJSX_Page = (): TsType_JSX => {
			let pageJSX =
			<Box className="tw-p-16 tw-text-center">
				<Button
					variant="contained"
					className="tw-m-4"
					color="primary"
					onClick={() => {
						let url = "https://firebasestorage.googleapis.com/v0/b/john-morrell.appspot.com/o/minecraft%2FRealm%20v4.3.zip?alt=media&token=6a05b242-0580-4da7-a0e5-5940adf55e56"
						window.open(url, '_blank')
					}}
				>
					V 4.3
				</Button>
				<Button
					variant="contained"
					className="tw-m-4"
					color="primary"
					onClick={() => {
						let url = "https://firebasestorage.googleapis.com/v0/b/john-morrell.appspot.com/o/minecraft%2FPoggys-Luminous-Dreams-Shader-MCPE-1.20.mcpack?alt=media&token=ba64b33c-8c9a-4a23-a1c0-fe97a8fadb3f"
						window.open(url, '_blank')
					}}
				>
					Shaders
				</Button>
				<Button
					variant="contained"
					className="tw-m-4"
					color="primary"
					onClick={() => {
						let url = "https://firebasestorage.googleapis.com/v0/b/john-morrell.appspot.com/o/minecraft%2FPoggys-Luminous-Dreams-Adaptive-Fog-Script-MCPE-1.20.mcpack?alt=media&token=4b20a5d0-46c9-477b-b3bb-f331f4dbfa4f"
						window.open(url, '_blank')
					}}
				>
					Shader Fog
				</Button>
			</Box>
			return pageJSX
		}

		// Render
		return <>{returnJSX_Page()}</>

	}