///////////////////////////////
// Description
///////////////////////////////

	/*
		DESCRIPTION / USAGE:
			Model files contains data and business logic specific to an individual database collection type

		TODO:

	*/


///////////////////////////////
// Imports
///////////////////////////////

import {
	Trans
} from 'react-i18next'
import {
	TsInterface_DataViewerLines,
	TsInterface_DataViewerSettings,
	ViewerLineBasicData,
	ViewerLineHeader,
	ViewerLinePhone
} from 'rfbp_core/components/data_viewer'
import {
	TsType_JSX
} from 'rfbp_core/typescript/global_types'

///////////////////////////////
// Typescript
///////////////////////////////


///////////////////////////////
// Variables
///////////////////////////////

	// Displayed Translatable Strings
	const s_EMAIL: TsType_JSX = 				<Trans>Email</Trans>
	const s_PHONE: TsType_JSX = 				<Trans>Phone</Trans>
	const s_USER: TsType_JSX = 					<Trans>User</Trans>
	const s_USER_NAME: TsType_JSX = 			<Trans>User Name</Trans>
	const s_USER_TYPE: TsType_JSX = 			<Trans>User Type</Trans>


///////////////////////////////
// Functions
///////////////////////////////


///////////////////////////////
// Exports
///////////////////////////////

	export const viewerSettings_User: TsInterface_DataViewerSettings = {
		// Nothing
	}

	export const viewerLines_User: TsInterface_DataViewerLines = {
		header_user: ViewerLineHeader( s_USER ),
		name: ViewerLineBasicData( s_USER_NAME, "name", {} ),
		email: ViewerLineBasicData( s_EMAIL, "email", {} ),
		phone: ViewerLinePhone( s_PHONE, "phone" ),
		user_role: ViewerLineBasicData( s_USER_TYPE, "user_role", {} ), // TODO - chip
	}