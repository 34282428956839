///////////////////////////////
// Description
///////////////////////////////

	/*
		DESCRIPTION / USAGE:
			containers are pages / views used in the app and are made up of components and can interact with services and models

		TODO:

			HEADER
			Main status - heats, color, name, coins, etc
			------
			Current Location
			Current Mission

			Movement directions and amount

			Yes / No to initate battle
			Battle Card Selection

			New Mission


	*/


///////////////////////////////
// Imports
///////////////////////////////

import {
	useContext,
	useEffect,
	useReducer,
	useState
} from 'react'
import {
	Trans
} from 'react-i18next'
import {
	useParams
} from 'react-router-dom'
import {
	themeVariables
} from 'rfbp_aux/config/app_theme'
import {
	DatabaseRef_Game_Document
} from 'rfbp_aux/services/database_endpoints/games'
import {
	Icon
} from 'rfbp_core/components/icons'
import {
	Context_UserInterface_ErrorDialog
} from 'rfbp_core/services/context'
import {
	DatabaseGetLiveDocument,
	DatabaseSetMergeDocument
} from 'rfbp_core/services/database_management'
import {
	getProp
} from 'rfbp_core/services/helper_functions'
import {
	TsInterface_UnspecifiedObject,
	TsType_JSX,
	TsType_String,
	TsType_Void,
	TsType_VoidFunction
} from 'rfbp_core/typescript/global_types'
import {
	Box,
	Stack,
	Typography
} from '@mui/material/'
import Grid2 from '@mui/material/Unstable_Grid2'

///////////////////////////////
// Typescript
///////////////////////////////


///////////////////////////////
// Variables
///////////////////////////////

	// Authenticated Nav Data

	// Displayed Translatable Strings
	// { sort-start } - displayed text - scoped sort plugin
	const s_WAITING_TO_START: TsType_JSX = 		<Trans>Waiting to Start</Trans>
	// { sort-end } - displayed text


///////////////////////////////
// Functions
///////////////////////////////


///////////////////////////////
// Container
///////////////////////////////

	export const Container: React.FC = (): TsType_JSX => {

		// Props
		const params = useParams()
		const gameKey: TsType_String = params.id as TsType_String
		const userKey: TsType_String = params.uid as TsType_String

		// Hooks - useContext, useState, useReducer, other
		// { sort-start } - hooks
		// const un_routerNaviation = 								useNavigate()
		// const { uc_setUserInterface_ErrorDialogDisplay } = 		useContext( Context_UserInterface_ErrorDialog )
		const [ us_diceFaceClassName, us_setDiceFaceClassName ] = 			useState<TsType_String>("dice-one")
		const [ us_rootGame, us_setRootGame ] = 							useState<TsInterface_UnspecifiedObject>({})
		const ur_forceRerender = 											useReducer(() => ({}), {})[1] as () => TsType_Void
		// { sort-end } - hooks

		// Hooks - useEffect
		useEffect(() => {
			let unsubscribeLiveData: TsType_VoidFunction
			const updateLiveData = ( newData: TsInterface_UnspecifiedObject ) => {
				us_setRootGame( newData )
				ur_forceRerender()
			}
			unsubscribeLiveData = DatabaseGetLiveDocument( DatabaseRef_Game_Document( gameKey ), updateLiveData )
			return () => {
				if (typeof unsubscribeLiveData === 'function'){
					unsubscribeLiveData()
				}
			}
		}, [gameKey, ur_forceRerender])

		// useEffect(() => {
			// // Used to render clock
			// let diceOptions: TsType_String[] = [
			// 	"dice-one",
			// 	"dice-two",
			// 	"dice-three",
			// 	"dice-four",
			// 	"dice-five",
			// 	"dice-six",
			// ]
			// const interval = setInterval(() => {
			// 	let randomIndex = Math.floor(Math.random() * diceOptions.length);
			// 	us_setDiceFaceClassName( diceOptions[randomIndex] )
			// }, 300);
			// return () => {
			// 	clearInterval(interval);
			// };
		// }, [])

		// Other Variables

		// Functions

		// Call Functions

		// JSX Generation
		const returnJSX_WaitingToStartPage = (): TsType_JSX => {
			let pageJSX =
			<Box
				sx={{
					height: "100vh",
					width: "100%",
					backgroundColor: "#000"
				}}
			>
				<Box
					className="tw-p-4"
					sx={{
						width: "100%",
						height: "100vh",
						background: "#71482E",
					}}
				>
					<Box
						sx={{
							maxWidth: "500px",
							margin: "auto",
						}}
					>

						<Box className="tw-text-center tw-py-2">
							<Typography className="font-pirata-one" sx={{ color: "#000", fontSize: "32px" }}>
								{ s_WAITING_TO_START }
							</Typography>
							<Typography className="font-pirata-one" sx={{ color: "#000", fontSize: "32px" }}>
								{ userKey }
							</Typography>
						</Box>

					</Box>
				</Box>
			</Box>
			return pageJSX
		}

		const returnJSX_RollingDiceAnimation = (): TsType_JSX => {
			let animationJSX = <></>

			animationJSX =
			<Icon icon={ us_diceFaceClassName } sx={{ color: themeVariables.info_main, fontSize: "32px" }} className="tw-mr-2 tw-inline-flex fa-spin fa-pulse" />

			return animationJSX
		}

		const returnJSX_ActiveGamePage = (): TsType_JSX => {
			let pageJSX = <></>

			// TODO - lots of sub components depending on game state

			pageJSX =
			<Box
				sx={{
					height: "100vh",
					width: "100%",
					backgroundColor: "#000"
				}}
			>
				<Box
					className="tw-p-4"
					sx={{
						width: "100%",
						height: "100vh",
						background: "#71482E",
					}}
				>
					<Box
						sx={{
							maxWidth: "500px",
							margin: "auto",
							background: "#F5D3A0",
						}}
						className="tw-py-2"
					>

						<Grid2 container>
							<Grid2 xs={6} sm={6} md={6} lg={6} className="tw-inline-block tw-text-left">
								<Box className="tw-ml-2 tw-mb-2">
									<Icon icon="sailboat" sx={{ color: themeVariables.info_main, fontSize: "64px" }} className="tw-ml-5" />
								</Box>
								<Box className="tw-ml-2">
									<Icon icon="heart" sx={{ color: themeVariables.error_main, fontSize: "32px" }} className="tw-mr-2" />
									<Icon icon="heart" sx={{ color: themeVariables.error_main, fontSize: "32px" }} className="tw-mr-2" />
									<Icon icon="heart-crack" sx={{ color: "#000", fontSize: "32px" }} className="tw-mr-2" />
								</Box>
							</Grid2>
							<Grid2 xs={6} sm={6} md={6} lg={6} className="tw-inline-block tw-text-right">





									<Box className="tw-text-right">
										<Box className="tw-mr-2">
											<Icon icon="sailboat" sx={{ color: themeVariables.info_main, fontSize: "32px" }} className="tw-mr-2 tw-inline-flex" />
											<Typography className="font-pirata-one tw-inline-flex" sx={{ color: "#000", fontSize: "32px", marginTop: "-8px" }}>
												G7
											</Typography>
										</Box>
										<Box className="tw-mr-2">
											<Icon icon="crosshairs" sx={{ color: themeVariables.error_main, fontSize: "32px" }} className="tw-mr-2 tw-inline-flex" />
											<Typography className="font-pirata-one tw-inline-flex" sx={{ color: "#000", fontSize: "32px", marginTop: "-8px" }}>
												B6
											</Typography>
										</Box>
										<Box className="tw-mr-2">
											<Icon icon="coins" sx={{ color: themeVariables.warning_main, fontSize: "32px" }} className="tw-mr-2 tw-inline-flex" />
											<Typography className="font-pirata-one tw-inline-flex" sx={{ color: "#000", fontSize: "32px", marginTop: "-8px" }}>
												1112
											</Typography>
										</Box>
									</Box>

							</Grid2>
						</Grid2>


					</Box>
					<Box
						sx={{
							maxWidth: "500px",
							margin: "auto",
							background: "#F5D3A0",
						}}
						className="tw-py-2 tw-mt-4 tw-text-center"
					>

						<Typography className="font-pirata-one" sx={{ color: "#000", fontSize: "32px" }}>
							{ "CURRENT ACTION" }
						</Typography>






					</Box>
				</Box>
			</Box>

			return pageJSX
		}

		const returnJSX_GameOverPage = (): TsType_JSX => {
			let pageJSX = <></>

			return pageJSX
		}

		const returnJSX_Page = (): TsType_JSX => {
			let pageJSX = <></>
			if( us_rootGame != null ){
				switch( us_rootGame.status ){
					case "new":
						pageJSX = returnJSX_WaitingToStartPage()
						break
					case "active":
						pageJSX = returnJSX_ActiveGamePage()
						break
					case "complete":
						pageJSX = returnJSX_GameOverPage()
						break
				}
			}
			return pageJSX
		}

		// Render
		return <>{returnJSX_Page()}</>

	}