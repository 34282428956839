//////////////////////////////////////////
//		  ooOOOO BOILERPLATE FILE		//
//		 oo		 _____					//
//		_I__n_n__||_|| ________			//
//	  >(_________|_7_|-|______|			//
//	   /o ()() ()() o   oo  oo			//
//////////////////////////////////////////

///////////////////////////////
// Description
///////////////////////////////

	/*
		DESCRIPTION / USAGE:
			Example documentation for forms

		TODO:

	*/


///////////////////////////////
// Imports
///////////////////////////////

import {
	ComponentDocumentation,
	TsInterface_ComponentDocumentation
} from 'rfbp_core/components/_example_components'
import {
	Form,
	TsInterface_FormAdditionalData,
	TsInterface_FormData,
	TsInterface_FormHooksObject,
	TsInterface_FormInputs,
	TsInterface_FormSettings,
	TsInterface_FormSubmittedData,
	TsType_FormOnChange,
	TsType_FormSubmission
} from 'rfbp_core/components/form'
import {
	TsType_JSX
} from 'rfbp_core/typescript/global_types'
import {
	Box
} from '@mui/material/'

///////////////////////////////
// Typescript
///////////////////////////////

	interface TsInterface_ComponentProps {}


///////////////////////////////
// Variables
///////////////////////////////

	// Displayed Translatable Strings
	// { sort-start } - displayed text - scoped sort plugin

	// { sort-end } - displayed text

	// Html strings
	const FormHtmlExample =
	`    <Form
	formAdditionalData={ formAdditionalData_FormName }
	formData={ formData_FormName }
	formInputs={ formInputs_FormName }
	formOnChange={ formOnChange_FormName }
	formSettings={ formSettings_FormName }
	formSubmission={ formSubmission_FormName }
	/>`


///////////////////////////////
// Functions
///////////////////////////////


///////////////////////////////
// Component
///////////////////////////////

	export const ExampleForm = ( props: TsInterface_ComponentProps ): TsType_JSX => {

		// Props

		// Hooks - useContext, useState, useReducer, other
		// { sort-start } - hooks

		// { sort-end } - hooks

		// Hooks - useEffect

		// Other Variables
		const formAdditionalData_Example: TsInterface_FormAdditionalData = {}
		const formData_Example: TsInterface_FormData = {}
		const formInputs_Example: TsInterface_FormInputs = {}
		const formOnChange_Example: TsType_FormOnChange = ( formAdditionalData: TsInterface_FormAdditionalData, formData: TsInterface_FormData, formInputs: TsInterface_FormInputs, formSettings: TsInterface_FormSettings ) => { }
		const formSettings_Example: TsInterface_FormSettings = {}
		const formSubmission_Example: TsType_FormSubmission = ( formSubmittedData: TsInterface_FormSubmittedData, formAdditionalData: TsInterface_FormAdditionalData, formHooks: TsInterface_FormHooksObject ) => { return new Promise( ( resolve, reject ) => { resolve( { success: true } ) }) }

		const documentationObject: TsInterface_ComponentDocumentation = {
			group_name: "Forms",
			group_description: "Forms are used to enable users to input information. Unlike other example components, this section will show each form input type as a tab below (first tab will be the form in general)",
			components: {
				Form: {
					component_name:
					<Box component="span">
						<span className="tw-font-bold tw-inline-block tw-align-top tw-normal-case">Form</span>
					</Box>,
					component_description: "Base form (input types on subsequent tabs)",
					component_example_jsx_string: FormHtmlExample,
					component_jsx:
					<Form
						formAdditionalData={ formAdditionalData_Example }
						formData={ formData_Example }
						formInputs={ formInputs_Example }
						formOnChange={ formOnChange_Example }
						formSettings={ formSettings_Example }
						formSubmission={ formSubmission_Example }
					/>,
					component_props: {
						formAdditionalData: { 	propKey: "formAdditionalData", 		propTypeOrInterface: "TsInterface_FormAdditionalData", 		propDescription: "Additional Data that might be useful for custom inputs", 			propExampleValue: formAdditionalData_Example 	},
						formData: {				propKey: "formData",				propTypeOrInterface: "TsInterface_FormData",				propDescription: "Form data to be preset",											propExampleValue: formData_Example				},
						formInputs: {			propKey: "formInputs",				propTypeOrInterface: "TsInterface_FormInputs",				propDescription: "The inputs that a user can fill out",								propExampleValue: formInputs_Example			},
						formOnChange: {			propKey: "formOnChange",			propTypeOrInterface: "TsType_FormOnChange",					propDescription: "Callback that is triggered on each change to the form",			propExampleValue: formOnChange_Example			},
						formSettings: {			propKey: "formSettings",			propTypeOrInterface: "TsInterface_FormSettings",			propDescription: "Global settings for the form (i.e. submit button text)",			propExampleValue: formSettings_Example			},
						formSubmission: {		propKey: "formSubmission",			propTypeOrInterface: "TsType_FormSubmission",				propDescription: "Function called on form submission",								propExampleValue: formSubmission_Example		},
					},
					component_todo: {
						1: "Nothing?",
					}
				},
				AssociationAutocompleteAsyncSearch: { 	component_name: <Box component="span"><span className="tw-font-bold tw-inline-block tw-align-top tw-normal-case">AssociationAutocompleteAsyncSearch</span></Box>, 	component_description: "In progress", component_example_jsx_string: "", component_jsx: <Box></Box>, component_props: {}, component_todo: { 0: "BUILD" } },
				AssociationAutocompleteComboBox: { 		component_name: <Box component="span"><span className="tw-font-bold tw-inline-block tw-align-top tw-normal-case">AssociationAutocompleteComboBox</span></Box>, 		component_description: "In progress", component_example_jsx_string: "", component_jsx: <Box></Box>, component_props: {}, component_todo: { 0: "BUILD" } },
				AssociationMultipleChoiceRadio: { 		component_name: <Box component="span"><span className="tw-font-bold tw-inline-block tw-align-top tw-normal-case">AssociationMultipleChoiceRadio</span></Box>, 		component_description: "In progress", component_example_jsx_string: "", component_jsx: <Box></Box>, component_props: {}, component_todo: { 0: "BUILD" } },
				AssociationMultipleChoiceSelect: { 		component_name: <Box component="span"><span className="tw-font-bold tw-inline-block tw-align-top tw-normal-case">AssociationMultipleChoiceSelect</span></Box>, 		component_description: "In progress", component_example_jsx_string: "", component_jsx: <Box></Box>, component_props: {}, component_todo: { 0: "BUILD" } },
				AutocompleteComboBox: { 				component_name: <Box component="span"><span className="tw-font-bold tw-inline-block tw-align-top tw-normal-case">AutocompleteComboBox</span></Box>, 				component_description: "In progress", component_example_jsx_string: "", component_jsx: <Box></Box>, component_props: {}, component_todo: { 0: "BUILD" } },
				BooleanCheckbox: { 						component_name: <Box component="span"><span className="tw-font-bold tw-inline-block tw-align-top tw-normal-case">BooleanCheckbox</span></Box>, 						component_description: "In progress", component_example_jsx_string: "", component_jsx: <Box></Box>, component_props: {}, component_todo: { 0: "BUILD" } },
				BooleanSwitch: { 						component_name: <Box component="span"><span className="tw-font-bold tw-inline-block tw-align-top tw-normal-case">BooleanSwitch</span></Box>, 						component_description: "In progress", component_example_jsx_string: "", component_jsx: <Box></Box>, component_props: {}, component_todo: { 0: "BUILD" } },
				CustomFormInputJSX: { 					component_name: <Box component="span"><span className="tw-font-bold tw-inline-block tw-align-top tw-normal-case">CustomFormInputJSX</span></Box>, 					component_description: "In progress", component_example_jsx_string: "", component_jsx: <Box></Box>, component_props: {}, component_todo: { 0: "BUILD" } },
				DisplayFormAdditionalDataJson: { 		component_name: <Box component="span"><span className="tw-font-bold tw-inline-block tw-align-top tw-normal-case">DisplayFormAdditionalDataJson</span></Box>, 		component_description: "In progress", component_example_jsx_string: "", component_jsx: <Box></Box>, component_props: {}, component_todo: { 0: "BUILD" } },
				DisplayFormDataJson: { 					component_name: <Box component="span"><span className="tw-font-bold tw-inline-block tw-align-top tw-normal-case">DisplayFormDataJson</span></Box>, 					component_description: "In progress", component_example_jsx_string: "", component_jsx: <Box></Box>, component_props: {}, component_todo: { 0: "BUILD" } },
				DisplayFormSettingsJson: { 				component_name: <Box component="span"><span className="tw-font-bold tw-inline-block tw-align-top tw-normal-case">DisplayFormSettingsJson</span></Box>, 				component_description: "In progress", component_example_jsx_string: "", component_jsx: <Box></Box>, component_props: {}, component_todo: { 0: "BUILD" } },
				FileUpload: { 							component_name: <Box component="span"><span className="tw-font-bold tw-inline-block tw-align-top tw-normal-case">FileUpload</span></Box>, 							component_description: "In progress", component_example_jsx_string: "", component_jsx: <Box></Box>, component_props: {}, component_todo: { 0: "BUILD" } },
				MultipleChoiceRadio: { 					component_name: <Box component="span"><span className="tw-font-bold tw-inline-block tw-align-top tw-normal-case">MultipleChoiceRadio</span></Box>, 					component_description: "In progress", component_example_jsx_string: "", component_jsx: <Box></Box>, component_props: {}, component_todo: { 0: "BUILD" } },
				MultipleChoiceSelectStateUSA: { 		component_name: <Box component="span"><span className="tw-font-bold tw-inline-block tw-align-top tw-normal-case">MultipleChoiceSelectStateUSA</span></Box>, 		component_description: "In progress", component_example_jsx_string: "", component_jsx: <Box></Box>, component_props: {}, component_todo: { 0: "BUILD" } },
				MultipleChoiceSelect: { 				component_name: <Box component="span"><span className="tw-font-bold tw-inline-block tw-align-top tw-normal-case">MultipleChoiceSelect</span></Box>, 				component_description: "In progress", component_example_jsx_string: "", component_jsx: <Box></Box>, component_props: {}, component_todo: { 0: "BUILD" } },
				MultipleSelectChecklist: { 				component_name: <Box component="span"><span className="tw-font-bold tw-inline-block tw-align-top tw-normal-case">MultipleSelectChecklist</span></Box>, 				component_description: "In progress", component_example_jsx_string: "", component_jsx: <Box></Box>, component_props: {}, component_todo: { 0: "BUILD" } },
				MultipleSelectDropdownChips: { 			component_name: <Box component="span"><span className="tw-font-bold tw-inline-block tw-align-top tw-normal-case">MultipleSelectDropdownChips</span></Box>, 			component_description: "In progress", component_example_jsx_string: "", component_jsx: <Box></Box>, component_props: {}, component_todo: { 0: "BUILD" } },
				MultipleSelectDropdown: { 				component_name: <Box component="span"><span className="tw-font-bold tw-inline-block tw-align-top tw-normal-case">MultipleSelectDropdown</span></Box>, 				component_description: "In progress", component_example_jsx_string: "", component_jsx: <Box></Box>, component_props: {}, component_todo: { 0: "BUILD" } },
				NumberRating: { 						component_name: <Box component="span"><span className="tw-font-bold tw-inline-block tw-align-top tw-normal-case">NumberRating</span></Box>, 						component_description: "In progress", component_example_jsx_string: "", component_jsx: <Box></Box>, component_props: {}, component_todo: { 0: "BUILD" } },
				NumberSlider: { 						component_name: <Box component="span"><span className="tw-font-bold tw-inline-block tw-align-top tw-normal-case">NumberSlider</span></Box>, 						component_description: "In progress", component_example_jsx_string: "", component_jsx: <Box></Box>, component_props: {}, component_todo: { 0: "BUILD" } },
				PhoneNumber: { 							component_name: <Box component="span"><span className="tw-font-bold tw-inline-block tw-align-top tw-normal-case">PhoneNumber</span></Box>, 							component_description: "In progress", component_example_jsx_string: "", component_jsx: <Box></Box>, component_props: {}, component_todo: { 0: "BUILD" } },
				Signature: { 							component_name: <Box component="span"><span className="tw-font-bold tw-inline-block tw-align-top tw-normal-case">Signature</span></Box>, 							component_description: "In progress", component_example_jsx_string: "", component_jsx: <Box></Box>, component_props: {}, component_todo: { 0: "BUILD" } },
				TextBasic: { 							component_name: <Box component="span"><span className="tw-font-bold tw-inline-block tw-align-top tw-normal-case">TextBasic</span></Box>, 							component_description: "In progress", component_example_jsx_string: "", component_jsx: <Box></Box>, component_props: {}, component_todo: { 0: "BUILD" } },
				TextMultiline: { 						component_name: <Box component="span"><span className="tw-font-bold tw-inline-block tw-align-top tw-normal-case">TextMultiline</span></Box>, 						component_description: "In progress", component_example_jsx_string: "", component_jsx: <Box></Box>, component_props: {}, component_todo: { 0: "BUILD" } },
				TextNumber: { 							component_name: <Box component="span"><span className="tw-font-bold tw-inline-block tw-align-top tw-normal-case">TextNumber</span></Box>, 							component_description: "In progress", component_example_jsx_string: "", component_jsx: <Box></Box>, component_props: {}, component_todo: { 0: "BUILD" } },
				TextPassword: { 						component_name: <Box component="span"><span className="tw-font-bold tw-inline-block tw-align-top tw-normal-case">TextPassword</span></Box>, 						component_description: "In progress", component_example_jsx_string: "", component_jsx: <Box></Box>, component_props: {}, component_todo: { 0: "BUILD" } },
				TimestampDatePicker: { 					component_name: <Box component="span"><span className="tw-font-bold tw-inline-block tw-align-top tw-normal-case">TimestampDatePicker</span></Box>, 					component_description: "In progress", component_example_jsx_string: "", component_jsx: <Box></Box>, component_props: {}, component_todo: { 0: "BUILD" } },
				TimestampDatetime: { 					component_name: <Box component="span"><span className="tw-font-bold tw-inline-block tw-align-top tw-normal-case">TimestampDatetime</span></Box>, 					component_description: "In progress", component_example_jsx_string: "", component_jsx: <Box></Box>, component_props: {}, component_todo: { 0: "BUILD" } },
				TimestampTimePicker: { 					component_name: <Box component="span"><span className="tw-font-bold tw-inline-block tw-align-top tw-normal-case">TimestampTimePicker</span></Box>, 					component_description: "In progress", component_example_jsx_string: "", component_jsx: <Box></Box>, component_props: {}, component_todo: { 0: "BUILD" } },
			}
		}

		// Functions



		// JSX Generation
		const returnComponentJSX = (): TsType_JSX => {
			let componentJSX =
			<Box>
				<ComponentDocumentation documentation={ documentationObject } />
			</Box>
			return componentJSX
		}

		// Render
		return <>{ returnComponentJSX() }</>
	}