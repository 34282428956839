///////////////////////////////
// Description
///////////////////////////////

	/*
		DESCRIPTION / USAGE:
			containers are pages / views used in the app and are made up of components and can interact with services and models

		TODO:

	*/


///////////////////////////////
// Imports
///////////////////////////////

import {
	useContext,
	useEffect
} from 'react'
import {
	useNavigate
} from 'react-router-dom'
import {
	ApplicationPages
} from 'rfbp_aux/data/application_structure'
import {
	DatabaseRef_Game_Document
} from 'rfbp_aux/services/database_endpoints/games'
import {
	Context_UserInterface_ErrorDialog
} from 'rfbp_core/services/context'
import {
	DatabaseSetMergeDocument
} from 'rfbp_core/services/database_management'
import {
	generateRandomString
} from 'rfbp_core/services/helper_functions'
import {
	TsType_JSX
} from 'rfbp_core/typescript/global_types'
import {
	Box,
	CircularProgress
} from '@mui/material/'

///////////////////////////////
// Typescript
///////////////////////////////


///////////////////////////////
// Variables
///////////////////////////////

	// Authenticated Nav Data

	// Displayed Translatable Strings
	// { sort-start } - displayed text - scoped sort plugin
	// const s_PAGE_NAME: TsType_JSX = 		<Trans>Page Name</Trans>
	// { sort-end } - displayed text


///////////////////////////////
// Functions
///////////////////////////////


///////////////////////////////
// Container
///////////////////////////////

	export const Container: React.FC = (): TsType_JSX => {

		// Props
		// const params = useParams()
		// const itemKey: TsType_String = params.id as TsType_String

		// Hooks - useContext, useState, useReducer, other
		// { sort-start } - hooks
		// const [ items, setItems ] = 			useState<TsInterface_UnspecifiedObject>({})
		// const ur_forceRerender = 				useReducer(() => ({}), {})[1] as () => TsType_Void
		const un_routerNaviation = 								useNavigate()
		const { uc_setUserInterface_ErrorDialogDisplay } = 		useContext( Context_UserInterface_ErrorDialog )
		// { sort-end } - hooks

		// Hooks - useEffect
		useEffect(() => {
			// Create New Game
			let inviteCode = generateRandomString(6, null)
			let dateCreated = new Date()
			let gameKey = dateCreated.getTime().toString() + "_" + inviteCode
			let updateObject = {
				key: gameKey,
				timestamp_created: dateCreated,
				type: "pirate_v4",
				status: "new",
				invite_code: inviteCode,
			}
			DatabaseSetMergeDocument( DatabaseRef_Game_Document( gameKey ), updateObject, {} ).then( ( res_DSMD ) => {
				un_routerNaviation( ApplicationPages.PirateGameDesktopLobbyPage.url( gameKey ) )
			}).catch( ( rej_DSMD ) => {
				uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
			})
		// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [])

		// Other Variables

		// Functions

		// Call Functions

		// JSX Generation
		const returnJSX_Page = (): TsType_JSX => {
			let pageJSX =
			<Box
				sx={{
					height: "100vh",
					width: "100%",
					backgroundColor: "#000"
				}}
			>
				<Box sx={{ width: "100%", height: "100%", textAlign: "center", color: "#fff", paddingTop: "40vh", fontSize: "32px"  }}>
					<CircularProgress />
				</Box>
			</Box>
			return pageJSX
		}

		// Render
		return <>{returnJSX_Page()}</>

	}