//////////////////////////////////////////
//		  ooOOOO BOILERPLATE FILE		//
//		 oo		 _____					//
//		_I__n_n__||_|| ________			//
//	  >(_________|_7_|-|______|			//
//	   /o ()() ()() o   oo  oo			//
//////////////////////////////////////////

///////////////////////////////
// Description
///////////////////////////////

	/*
		DESCRIPTION / USAGE:
			Wrapper that includes navigation and page header for authenticated parts of the software

		TODO:
			[ ] Typescript - 5 instances of @ts-expect-error - on functions / components copied from mui examples

	*/


///////////////////////////////
// Imports
///////////////////////////////

// Components
import {
	LogoMain
} from 'app/images/logos/logo_main'
// React
import {
	useContext,
	useEffect
} from 'react'
import {
	Link,
	Navigate,
	useNavigate
} from 'react-router-dom'
// Config
import {
	ApplicationMajorPages,
	ApplicationNavPages,
	ApplicationPages,
	EmptyApplicationNavigationObject,
	generateActiveUserApplicationPermissions,
	generateApplicationNavigationObject,
	TsInterface_NavigationObject,
	TsInterface_NavPage
} from 'rfbp_aux/data/application_structure' // OUTSIDE BOILERPLATE
import {
	Icon
} from 'rfbp_core/components/icons'
// Context
import {
	Context_RootData_AuthenticatedUser,
	Context_RootData_ClientKey,
	Context_RootData_ClientPermissions,
	Context_RootData_ClientUser,
	Context_RootData_GlobalUser,
	Context_RootData_UserPermissions,
	Context_UserInterface_LoadingBar,
	Context_UserInterface_NavBar
} from 'rfbp_core/services/context'
import {
	cloneObjectWithoutReference,
	getProp,
	objectToArray
} from 'rfbp_core/services/helper_functions'
import {
	getClientKey
} from 'rfbp_core/services/user_authentication'
// Typescript
import {
	TsInterface_UnspecifiedObject,
	TsType_Any,
	TsType_Boolean,
	TsType_JSX,
	TsType_Number,
	TsType_String,
	TsType_Void
} from 'rfbp_core/typescript/global_types'
// MUI Components
import {
	Box,
	CircularProgress,
	Divider,
	Drawer,
	IconButton,
	LinearProgress,
	List,
	ListItem,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	styled,
	Typography
} from '@mui/material/'
import MuiAppBar from '@mui/material/AppBar'
import {
	themeVariables
} from '../config/app_theme'

		// Third Party


///////////////////////////////
// Typescript
///////////////////////////////

	interface TsInterface_ComponentProps {
		content: TsType_JSX
		pageHeader: TsType_JSX
		pageKey: TsType_String
	}

	interface TsInterface_NavLinkStyle {
		py: TsType_String
		px: TsType_Number
		color?: TsType_String
		fontWeight?: TsType_Number
		'&:hover, &:focus': {
			bgcolor: TsType_String
		}
		borderLeft: TsType_String
	}

	interface TsInterface_ItemCategoryStyle {
		boxShadow: TsType_String
		py: TsType_Number
		px: TsType_Number
	}


///////////////////////////////
// Variables
///////////////////////////////

	// Displayed Translatable Strings

	// Other
	const drawerWidth: TsType_Number = 250
	const itemCategory: TsInterface_ItemCategoryStyle = {
		boxShadow: '0 -1px 0 rgb(255,255,255,0.1) inset',
		py: 1.5,
		px: 3,
	}

	// Colors
	const unselectedNavColor = themeVariables.white
	const selectedNavColor = themeVariables.warning_main
	const appBarBackgroundColor = themeVariables.background_default
	const logoPrimaryDarkColor = themeVariables.primary_dark
	const navGradientTopColor = themeVariables.info_main
	const navGradientBottomColor = themeVariables.info_dark

	// const unselectedNavColor: TsType_String = themeVariables.white
	// const selectedNavColor: TsType_String = themeVariables.white
	// const appBarBackgroundColor: TsType_String = themeVariables.background_default
	// const logoPrimaryDarkColor: TsType_String = "#3c82c4"
	// const logoSecondaryColor: TsType_String = "#3c82c4"
	// const logoAdditionalColor: TsType_String = "#3c82c4"
	// const navGradientTopColor: TsType_String = "#77a8d6"
	// const navGradientBottomColor: TsType_String = "#2a5b8a"


///////////////////////////////
// Functions
///////////////////////////////

	const DrawerHeader = styled( 'div' )(({ theme }) => ({
		display: 'flex',
		alignItems: 'center',
		padding: theme.spacing( 0, 1 ),
		// necessary for content to be below app bar
		...theme.mixins.toolbar,
		justifyContent: 'flex-end',
	}))

	const getMainWidth = ( open: TsType_Boolean ): TsType_String => {
		let width = "100%"
		if ( open ){
			width = `calc(100% - ${drawerWidth + 16}px)`
		}
		return width
	}

	const getMainLeftMargin = ( open: TsType_Boolean ): TsType_String => {
		let width = `-${drawerWidth}px`
		if ( open ){
			width = `-${drawerWidth + 16}px`
		}
		return width
	}

	// @ts-expect-error
	const Main = styled('main', { shouldForwardProp: ( prop: TsType_String ) => prop !== 'open' })(({ theme, open }) => ({
		flexGrow: 1,
		width: getMainWidth( open ),
		// padding: theme.spacing( 3 ),
		transition: theme.transitions.create( 'margin', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		marginLeft: getMainLeftMargin( open ),
		...( open && {
			transition: theme.transitions.create('margin', {
				easing: theme.transitions.easing.easeOut,
				duration: theme.transitions.duration.enteringScreen,
			}),
			marginLeft: 0,
		}),
	}))

	const AppBar = styled( MuiAppBar, {
		shouldForwardProp: ( prop: TsType_String) => prop !== 'open',
	// @ts-expect-error
	})(({ theme, open }) => ({
		transition: theme.transitions.create( ['margin', 'width'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		...( open && {
			width: `calc(100% - ${drawerWidth + 0}px)`,
			marginLeft: `${drawerWidth + 0}px`,
			transition: theme.transitions.create( ['margin', 'width'], {
				easing: theme.transitions.easing.easeOut,
				duration: theme.transitions.duration.enteringScreen,
			}),
		}),
	}))

	const  NavOpenKeyListener = ( props: TsType_Any ) => {

		// Props
		const openNav: TsType_Any = 			getProp( props, "openNav", () => {} )
		const closeNav: TsType_Any = 			getProp( props, "closeNav", () => {} )
		const navOpen: TsType_Any = 			getProp( props, "navOpen", null )

		// Hooks - useEffect
		useEffect(() => {
			const handleKeyDown = (event: TsType_Any ) => {
				if (event.metaKey && event.keyCode === 66) {
					setTimeout( () => {
						if( navOpen === true ){
							closeNav()
						}
						if( navOpen === false ){
							openNav( true )
						}
					}, 1)
				}
			}
			document.addEventListener("keydown", handleKeyDown)
			return () => {
				document.removeEventListener("keydown", handleKeyDown)
			}
		}, [ openNav, closeNav, navOpen]);

		return null;
	}


///////////////////////////////
// Component
///////////////////////////////

	export const AuthenticatedContainer = ( props: TsInterface_ComponentProps ): TsType_JSX => {

		// Props
		const pr_pageContent: TsInterface_ComponentProps["content"] = 			getProp( props, "content", <></> )
		const pr_pageHeader: TsInterface_ComponentProps["pageHeader"] = 		getProp( props, "pageHeader", <></> )
		const pr_pageKey: TsInterface_ComponentProps["pageKey"] = 				getProp( props, "pageKey", "" )

		// Hooks - useContext, useState, useReducer, other
		// { sort-start } - hooks
		const un_routerNaviation = 																useNavigate()
		const { uc_RootData_AuthenticatedUser } = 												useContext( Context_RootData_AuthenticatedUser )
		const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = 							useContext( Context_RootData_ClientKey )
		const { uc_RootData_ClientPermissions } = 												useContext( Context_RootData_ClientPermissions )
		const { uc_RootData_ClientUser } = 														useContext( Context_RootData_ClientUser )
		const { uc_RootData_GlobalUser } = 														useContext( Context_RootData_GlobalUser )
		const { uc_RootData_UserPermissions, uc_setRootData_UserPermissions } = 				useContext( Context_RootData_UserPermissions )
		const { uc_UserInterface_LoadingBarDisplay } = 											useContext( Context_UserInterface_LoadingBar )
		const { uc_UserInterface_NavBarDisplay, uc_setUserInterface_NavBarDisplay } = 			useContext( Context_UserInterface_NavBar )
		// { sort-end } - hooks

		// Hooks - useEffect
		useEffect(() => {
			getClientKey( uc_RootData_ClientKey, uc_setRootData_ClientKey ).then(( getResult ) => {
				// Nothing
			}).catch(( getReject) => {
				un_routerNaviation( ApplicationPages.UnauthenticatedLoginPage.url() )
			})

		}, [ un_routerNaviation, uc_RootData_ClientKey, uc_setRootData_ClientKey ])

		useEffect(() => {
			generateActiveUserApplicationPermissions( uc_RootData_ClientUser, uc_RootData_GlobalUser, uc_RootData_ClientPermissions ).then(( permissionResult ) => {
				if ( permissionResult.success ){
					uc_setRootData_UserPermissions( permissionResult.permissions )
				}
			})
			return () => { }
		}, [ uc_RootData_ClientUser, uc_RootData_GlobalUser, uc_RootData_ClientPermissions, uc_setRootData_UserPermissions ])

		// Other Variables
		const pageRootNavPermission: TsType_String = getProp( ApplicationPages[ pr_pageKey ], "root_nav_page_permission", "" )
		const pageRoleAccessPermissions: TsInterface_NavPage["page_role_access_permissions"] = getProp( ApplicationNavPages[ pageRootNavPermission ], "page_role_access_permissions", {} )
		let topLoadingBar: TsType_JSX
		let sideBarNavObject: TsInterface_NavigationObject
		let authorizedToViewPage: TsType_Boolean = false
		let clientType: TsType_String
		let userRole: TsType_String

		// Functions
		const determineClientTypeAndUserRole = (): TsType_Void => {
			if ( uc_RootData_ClientPermissions != null && uc_RootData_ClientPermissions.client_type != null ){
				clientType = uc_RootData_ClientPermissions.client_type
			}
			if ( uc_RootData_ClientUser != null && uc_RootData_ClientUser.user_role != null ){
				userRole = uc_RootData_ClientUser.user_role
			} else if ( uc_RootData_GlobalUser != null && uc_RootData_GlobalUser.user_role != null ){
				userRole = uc_RootData_GlobalUser.user_role
			}
		}

		const applyPermissionsToSideNavBarObject = (): TsType_Void => {
			let initialApplicationNavigationObject = generateApplicationNavigationObject( clientType, userRole )
			let permittedApplicationNavigationObject: TsInterface_NavigationObject = {}
			for ( let loopSectionKey in initialApplicationNavigationObject ){
				let loopSection = initialApplicationNavigationObject[ loopSectionKey ]
				for ( let loopLinkKey in loopSection["links"] ){
					let loopLink = loopSection["links"][ loopLinkKey ]
					if ( uc_RootData_UserPermissions != null && uc_RootData_UserPermissions[ loopLinkKey ] === true && loopLink != null ){
						if ( permittedApplicationNavigationObject[ loopSectionKey ] == null ){
							permittedApplicationNavigationObject[ loopSectionKey ] = cloneObjectWithoutReference( loopSection )
							permittedApplicationNavigationObject[ loopSectionKey ]["links"] = {}
						}
						permittedApplicationNavigationObject[ loopSectionKey ]["links"][ loopLinkKey ] = loopLink
					}
				}
			}
			if ( permittedApplicationNavigationObject == null || objectToArray( permittedApplicationNavigationObject ).length === 0 ){
				permittedApplicationNavigationObject = EmptyApplicationNavigationObject
			}
			sideBarNavObject = permittedApplicationNavigationObject
		}

		const openNav = (): TsType_Void => {
			uc_setUserInterface_NavBarDisplay( true )
		}

		const closeNav = (): TsType_Void => {
			uc_setUserInterface_NavBarDisplay( false )
		}

		const checkIfNavSectionIsActive = ( sectionKey: TsType_String, linkKey: TsType_String ): TsType_Boolean => {
			let active = false
			if ( sectionKey === "home" && linkKey === "home" && pr_pageKey === "HomePage" ){
				active = true
			} else if ( clientType != null && userRole != null && pageRoleAccessPermissions != null && pageRoleAccessPermissions[ clientType + "_" + userRole ] != null ){
				let compositeKey: TsType_String = clientType + "_" + userRole
				if ( pageRoleAccessPermissions[ compositeKey ]["highlighted_nav_section"] === sectionKey && pageRoleAccessPermissions[ compositeKey ]["highlighted_nav_page"] === linkKey ){
					active = true
				}
			}
			return active
		}

		const returnNavLinkStyle = ( selected: TsType_Boolean ): TsInterface_NavLinkStyle => {
			let style
			if ( selected === true ){
				style = {
					py: '2px',
					px: 3,
					color: selectedNavColor,
					fontWeight: 700,
					// color: 'rgba(255, 255, 255, 0.7)',
					'&:hover, &:focus': {
						bgcolor: 'rgba(255, 255, 255, 0.08)',
					},
					"borderLeft": "6px solid " + selectedNavColor
				}
			} else {
				style = {
					py: '2px',
					px: 3,
					color: 'rgba(255, 255, 255, 0.7)',
					fontWeight: 700,
					'&:hover, &:focus': {
						bgcolor: 'rgba(255, 255, 255, 0.08)',
					},
					"borderLeft": "6px solid rgba(0,0,0,0)"
				}
			}
			return style
		}

		const returnNavIconStyle = ( selected: TsType_Boolean ): TsInterface_UnspecifiedObject => {
			let navIconSX = {
				color: unselectedNavColor
			}
			if( selected === true ){
				navIconSX["color"] = selectedNavColor
			}
			return navIconSX
		}

		const determineLoadingBarVisibility = (): TsType_Void => {
			if ( uc_UserInterface_LoadingBarDisplay === true ){
				topLoadingBar = <LinearProgress color="secondary" />
			} else {
				topLoadingBar = <Box className="top_loading_bar_placeholder"></Box>
			}
		}

		const determinePageAuthorization = (): TsType_Void => {
			if ( pr_pageKey === "HomePage" ){
				authorizedToViewPage = true
			} else if ( clientType == null || userRole == null ) {
				authorizedToViewPage = true
			} else {
				if ( uc_RootData_UserPermissions == null || objectToArray( uc_RootData_UserPermissions ).length === 0 || uc_RootData_UserPermissions[pageRootNavPermission] === true ){
					authorizedToViewPage = true
				}
			}
		}

		const getMainSectionPadding = ( open: TsType_Boolean ): TsType_String => {
			let cssClassName = ""
			if ( open === true ){
				// cssClassName = "tw-pl-2"
			}
			return cssClassName
		}

		const getHeaderTextMaxWidth = ( open: TsType_Boolean ): TsType_String => {
			// TODO - add 40 for each nav icon visible - i.e. notifications
			let otherHeaderContentWidth = 130
			let maxWidth = `calc(100% - ${otherHeaderContentWidth}px)`
			return maxWidth
		}

		const returnJSX_NavButton = ( open: TsType_Boolean ): TsType_JSX => {
			let navButtonJSX = <></>
			if ( open === true ){
				navButtonJSX =
				<IconButton sx={{ height: "40px", width: "40px", marginTop: "2px", ...( uc_UserInterface_NavBarDisplay && { display: 'none' } ) }} className="tw-inline-block" onClick={ () => { closeNav() } }>
					<Icon icon="chevron-left" />
				</IconButton>
			} else {
				navButtonJSX =
				<IconButton sx={{ height: "40px", width: "40px", marginTop: "2px", ...( !uc_UserInterface_NavBarDisplay && { display: 'none' } ) }} className="tw-inline-block" onClick={ () => { openNav() } }>
					<Icon icon="bars" />
				</IconButton>
			}
			return navButtonJSX
		}

		// Call Functions
		determineClientTypeAndUserRole()
		applyPermissionsToSideNavBarObject()
		determineLoadingBarVisibility()
		determinePageAuthorization()

		// JSX Generation
		const returnJSX_Component = (): TsType_JSX => {
			let authContentJSX = <Box></Box>
			if ( uc_RootData_AuthenticatedUser == null || uc_RootData_AuthenticatedUser.loggedIn == null ){
				authContentJSX =
				<Box className="tw-text-center tw-p-4">
					<CircularProgress />
				</Box>
			} else if ( uc_RootData_AuthenticatedUser != null && uc_RootData_AuthenticatedUser.loggedIn === false ){
				authContentJSX = <Navigate to={ApplicationPages.UnauthenticatedLoginPage.url()} replace />
			} else if ( !authorizedToViewPage ){
				authContentJSX = <Navigate to={ApplicationPages.HomePage.url()} replace />
			} else {
				authContentJSX =
				<Box component='div' className="tw-flex">
					{/* @ts-expect-error */}
					<AppBar position="fixed" open={ uc_UserInterface_NavBarDisplay } sx={ { backgroundColor: appBarBackgroundColor, boxShadow: "none" } } className="tw-pl-0" >
						{ topLoadingBar }
						<Box>
							<Box className="tw-inline-block tw-float-left">
								{ returnJSX_NavButton( uc_UserInterface_NavBarDisplay ) }
							</Box>
							<Typography
								className="tw-inline-block tw-ml-1 tw-mt-2"
								variant="h5"
								noWrap
								component="span"
								sx={{
									"color": logoPrimaryDarkColor,
									"fontWeight": 700,
									"marginTop": "0px",
									"width": "100%",
									"maxWidth": getHeaderTextMaxWidth( uc_UserInterface_NavBarDisplay )
								}}
							>
								{ pr_pageHeader }
							</Typography>


							{/* TODO - implement top right icons */}

							<Box className="tw-inline-block tw-float-right">
								{/* <IconButton sx={{ height: "40px", width: "40px", marginTop: "4px" }} className="tw-inline-block" onClick={ () => { console.log("TEST") } }>
									<NotificationsActiveIcon/>
								</IconButton> */}
								<Link to={ ApplicationMajorPages.UserSettingsPage.url() } >
									<IconButton sx={{ height: "40px", width: "40px", marginTop: "4px" }} className="tw-inline-block" onClick={ () => {  } }>
										<Icon icon="circle-user" />
									</IconButton>
								</Link>
							</Box>
						</Box>
						<Divider />
					</AppBar>
					<Box className="TEMP_nav">
						<Drawer
							sx={{
								width: drawerWidth,
								flexShrink: 0,
								'& .MuiDrawer-paper': {
									width: drawerWidth,
									boxSizing: 'border-box',
								},
							}}
							variant="persistent"
							anchor="left"
							open={ uc_UserInterface_NavBarDisplay }
						>
							<Box sx={{ background: 'linear-gradient(to bottom, ' + navGradientTopColor + ', ' + navGradientBottomColor + ')', height: "calc(100vh - 0px)", overflow: "scroll" }}>
								<DrawerHeader sx={ itemCategory }>
									<Box style={{width:'100%', textAlign: "left", height: "30px"}}>
										<LogoMain height="20px" color={ themeVariables.white } />
									</Box>
								</DrawerHeader>
								<List disablePadding>
									{Object.keys( sideBarNavObject ).map(( sectionKey, sectionIndex ) => (
										<Box component='div' key={ sectionKey }>
											{Object.keys( sideBarNavObject[ sectionKey ]["links"] ).map(( navLinkKey, navLinkName ) => (
												<Link to={ sideBarNavObject[ sectionKey ]["links"][ navLinkKey ].url } key={ sideBarNavObject[ sectionKey ]["links"][ navLinkKey ].key }>
													<ListItem disablePadding >
														<ListItemButton sx={ returnNavLinkStyle( checkIfNavSectionIsActive( sectionKey, navLinkKey ) ) }>
															<ListItemIcon sx={ returnNavIconStyle( checkIfNavSectionIsActive( sectionKey, navLinkKey ) ) }>
																<Icon size="lg" icon={ sideBarNavObject[ sectionKey ]["links"][ navLinkKey ].icon  } />
															</ListItemIcon>
															<ListItemText>{ sideBarNavObject[ sectionKey ]["links"][ navLinkKey ].name }</ListItemText>
														</ListItemButton>
													</ListItem>
												</Link>
											))}
											<Divider className="tw-my-2" sx={{ borderColor: "rgba(255, 255, 255, 0.12)" }} />
										</Box>
									))}
								</List>
							</Box>
						</Drawer>
					</Box>
					{/* @ts-expect-error */}
					<Main className={ getMainSectionPadding(uc_UserInterface_NavBarDisplay) } open={ uc_UserInterface_NavBarDisplay }>
						<Box sx={{ height: "50px" }}/>
						<Box className="tw-px-2 tw-py-2">
							{ pr_pageContent }
						</Box>
						<NavOpenKeyListener openNav={ openNav } closeNav={ closeNav } navOpen={ uc_UserInterface_NavBarDisplay } />
					</Main>
				</Box>
			}
			return authContentJSX
		}

		// Render
		return <>{ returnJSX_Component() }</>
	}