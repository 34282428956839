//////////////////////////////////////////
//		  ooOOOO BOILERPLATE FILE		//
//		 oo		 _____					//
//		_I__n_n__||_|| ________			//
//	  >(_________|_7_|-|______|			//
//	   /o ()() ()() o   oo  oo			//
//////////////////////////////////////////

///////////////////////////////
// Description
///////////////////////////////

	/*
		DESCRIPTION / USAGE:
			Example documentation for dialogs

		TODO:

	*/


///////////////////////////////
// Imports
///////////////////////////////

import React, {
	useContext
} from 'react'
import {
	ComponentDocumentation,
	TsInterface_ComponentDocumentation
} from 'rfbp_core/components/_example_components'
import {
	TsInterface_FormAdditionalData,
	TsInterface_FormData,
	TsInterface_FormHooksObject,
	TsInterface_FormInputs,
	TsInterface_FormSettings,
	TsInterface_FormSubmittedData
} from 'rfbp_core/components/form'
import {
	Icon
} from 'rfbp_core/components/icons'
import {
	Context_UserInterface_AlertDialog,
	Context_UserInterface_ConfirmDialog,
	Context_UserInterface_CustomDialog,
	Context_UserInterface_ErrorDialog,
	Context_UserInterface_FormDialog,
	Context_UserInterface_PromptDialog,
	TsInterface_AlertDialogObject,
	TsInterface_ConfirmDialogObject,
	TsInterface_CustomDialogObject,
	TsInterface_FormDialogObject,
	TsInterface_PromptDialogObject
} from 'rfbp_core/services/context'
import {
	TsInterface_ErrorDialogObject,
	TsType_JSX
} from 'rfbp_core/typescript/global_types'
import {
	Box,
	Button
} from '@mui/material/'

///////////////////////////////
// Typescript
///////////////////////////////

	interface TsInterface_ComponentProps {}


///////////////////////////////
// Variables
///////////////////////////////

	// Displayed Translatable Strings
	// { sort-start } - displayed text - scoped sort plugin

	// { sort-end } - displayed text

	// Html strings
	const AlertDialogHtmlExample =
	`    <AlertDialog
	prop1={ prop1Value }
	/>`
	const ConfirmDialogHtmlExample =
	`    <ConfirmDialog
	prop1={ prop1Value }
	/>`
	const CustomDialogHtmlExample =
	`    <CustomDialog
	prop1={ prop1Value }
	/>`
	const ErrorDialogHtmlExample =
	`    <ErrorDialog
	prop1={ prop1Value }
	/>`
	const FormDialogHtmlExample =
	`    <FormDialog
	prop1={ prop1Value }
	/>`
	const PromptDialogHtmlExample =
	`    <PromptDialog
	prop1={ prop1Value }
	/>`


///////////////////////////////
// Functions
///////////////////////////////


///////////////////////////////
// Component
///////////////////////////////

	export const ExampleDialogs = ( props: TsInterface_ComponentProps ): TsType_JSX => {

		// Props

		// Hooks - useContext, useState, useReducer, other
		// { sort-start } - hooks
		const { uc_setUserInterface_AlertDialogDisplay } = 			useContext( Context_UserInterface_AlertDialog )
		const { uc_setUserInterface_ConfirmDialogDisplay } = 		useContext( Context_UserInterface_ConfirmDialog )
		const { uc_setUserInterface_CustomDialogDisplay } = 		useContext( Context_UserInterface_CustomDialog )
		const { uc_setUserInterface_ErrorDialogDisplay } = 			useContext( Context_UserInterface_ErrorDialog )
		const { uc_setUserInterface_FormDialogDisplay } = 			useContext( Context_UserInterface_FormDialog )
		const { uc_setUserInterface_PromptDialogDisplay } = 		useContext( Context_UserInterface_PromptDialog )
		// { sort-end } - hooks

		// Hooks - useEffect

		// Other Variables

		const AlertDialogObject: TsInterface_AlertDialogObject = {
			color: "warning",
			header: <>Header</>,
			icon: <Icon icon="triangle-exclamation" />,
			text: <>Content</>,
		}

		const AlertDialogObjectPreview = {
			color: "warning",
			header: "Header",
			icon: "<Icon>",
			text: "Content",
		}

		const ConfirmDialogObject: TsInterface_ConfirmDialogObject = {
			color: "success",
			header: <>Header</>,
			icon: <Icon icon="circle-check" />,
			submit_text: "Submit",
			text: "Confirm Text",
			submit_callback: () => { return new Promise( ( resolve, reject ) => { resolve( { success: true } ) }) },
		}

		const ConfirmDialogObjectPreview = {
			color: "success",
			header: "Header",
			icon: "<Icon>",
			submit_text: "Submit",
			text: "Confirm Text",
			submit_callback: () => { },
		}

		const CustomDialogObject: TsInterface_CustomDialogObject = {
			dialog_jsx: <>Custom Content</>,
			settings: {
				max_width: "lg"
			}
		}

		const CustomDialogObjectPreview = {
			dialog_jsx: "Custom Content",
			settings: {
				max_width: "lg"
			}
		}

		const ErrorDialogObject: TsInterface_ErrorDialogObject = {
			code: "ER-D-CD-ED-01",
			details: "No Details",
			message: "No Message"
		}

		const FormDialogObject: TsInterface_FormDialogObject = {

			// TODO - fix up

			form: {
				formAdditionalData: {},
				formData: {},
				formInputs: {},
				formOnChange: ( formAdditionalData: TsInterface_FormAdditionalData, formData: TsInterface_FormData, formInputs: TsInterface_FormInputs, formSettings: TsInterface_FormSettings ) => {},
				formSettings: {},
				formSubmission: ( formSubmittedData: TsInterface_FormSubmittedData, formAdditionalData: TsInterface_FormAdditionalData, formHooks: TsInterface_FormHooksObject ) => { return new Promise( ( resolve, reject ) => { resolve( { success: true } ) })},
			},
			dialog: {
				formDialogHeaderColor: "success",
				formDialogHeaderText: "Edit",
				formDialogIcon: <Icon icon="wrench" />
			}
		}

		const PromptDialogObject: TsInterface_PromptDialogObject = {
			color: "info",
			confirm_text: <>Confirm Text</>,
			default_value: null,
			header: <>Header</>,
			icon: <Icon icon="terminal" />,
			input_label: <>Header</>,
			input_type: "text",
			text: <>Text</>,
			submit_callback: () => { return new Promise( ( resolve, reject ) => { resolve( { success: true } ) }) },
		}

		const PromptDialogObjectPreview = {
			color: "info",
			confirm_text: "Confirm Text",
			default_value: null,
			header: "Header",
			icon: "<Icon>",
			input_label: "Header",
			input_type: "text",
			text: "Text",
			submit_callback: () => { },
		}

		const documentationObject: TsInterface_ComponentDocumentation = {
			group_name: "Dialogs",
			group_description: "Dialogs are modals that can be used to show alerts or errors, get user input from confirms or prompts, and etc",
			components: {
				AlertDialog: {
					component_name:
					<Box component="span">
						<span className="tw-font-bold tw-inline-block tw-align-top tw-normal-case">AlertDialog</span>
					</Box>,
					component_description: "A dialog that operates as a simple alert",
					component_example_jsx_string: AlertDialogHtmlExample,
					component_jsx:
					<Box>
						<Button color="warning" variant="contained" onClick={ () => {
							uc_setUserInterface_AlertDialogDisplay({
								display: true,
								alert: AlertDialogObject
							})
						} }>Show Alert</Button>
					</Box>,
					component_props: {
						alert: {
							propKey: "alert",
							propTypeOrInterface: "TsInterface_AlertDialogObject",
							propDescription: "Object used to generate alert dialog",
							propExampleValue: AlertDialogObjectPreview
						},
					},
					component_todo: {
						1: "Nothing",
					}
				},
				ConfirmDialog: {
					component_name:
					<Box component="span">
						<span className="tw-font-bold tw-inline-block tw-align-top tw-normal-case">ConfirmDialog</span>
					</Box>,
					component_description: "A dialog that forces a user to confirm before proceeding",
					component_example_jsx_string: ConfirmDialogHtmlExample,
					component_jsx:
					<Box>
						<Button color="success" variant="contained" onClick={ () => {
							uc_setUserInterface_ConfirmDialogDisplay({
								display: true,
								confirm: ConfirmDialogObject
							})
						} }>Show Confirm</Button>
					</Box>,
					component_props: {
						confirm: {
							propKey: "confirm",
							propTypeOrInterface: "TsInterface_ConfirmDialogObject",
							propDescription: "Object used to generate confirm dialog",
							propExampleValue: ConfirmDialogObjectPreview
						},
					},
					component_todo: {
						1: "Nothing",
					}
				},
				CustomDialog: {
					component_name:
					<Box component="span">
						<span className="tw-font-bold tw-inline-block tw-align-top tw-normal-case">CustomDialog</span>
					</Box>,
					component_description: "A dialog that operates as a simple alert",
					component_example_jsx_string: CustomDialogHtmlExample,
					component_jsx:
					<Box>
						<Button color="inherit" variant="contained" onClick={ () => {
							uc_setUserInterface_CustomDialogDisplay({
								display: true,
								dialog: CustomDialogObject
							})
						} }>Show Custom Dialog</Button>
					</Box>,
					component_props: {
						alert: {
							propKey: "alert",
							propTypeOrInterface: "TsInterface_CustomDialogObject",
							propDescription: "Object used to generate custom dialog",
							propExampleValue: CustomDialogObjectPreview
						},
					},
					component_todo: {
						1: "Nothing",
					}
				},
				ErrorDialog: {
					component_name:
					<Box component="span">
						<span className="tw-font-bold tw-inline-block tw-align-top tw-normal-case">ErrorDialog</span>
					</Box>,
					component_description: "A dialog that shows a system generated error",
					component_example_jsx_string: ErrorDialogHtmlExample,
					component_jsx:
					<Box>
						<Button color="error" variant="contained" onClick={ () => {
							uc_setUserInterface_ErrorDialogDisplay({
								display: true,
								error: ErrorDialogObject
							})
						} }>Show Error</Button>
					</Box>,
					component_props: {
						error: {
							propKey: "error",
							propTypeOrInterface: "TsInterface_ErrorDialogObject",
							propDescription: "Object used to generate error dialog",
							propExampleValue: ErrorDialogObject
						},
					},
					component_todo: {
						1: "Nothing",
					}
				},
				FormDialog: {
					component_name:
					<Box component="span">
						<span className="tw-font-bold tw-inline-block tw-align-top tw-normal-case">FormDialog</span>
					</Box>,
					component_description: "A dialog that shows a form",
					component_example_jsx_string: FormDialogHtmlExample,
					component_jsx:
					<Box>
						<Button color="inherit" variant="contained" onClick={ () => {
							uc_setUserInterface_FormDialogDisplay({
								display: true,
								form: FormDialogObject
							})
						} }>Show Form</Button>
					</Box>,
					component_props: {
						// form: {
						// 	propKey: "error",
						// 	propTypeOrInterface: "TsInterface_ErrorDialogObject",
						// 	propDescription: "Object used to generate error dialog",
						// 	propExampleValue: ErrorDialogObject
						// },
					},
					component_todo: {
						1: "Nothing",
					}
				},
				PromptDialog: {
					component_name:
					<Box component="span">
						<span className="tw-font-bold tw-inline-block tw-align-top tw-normal-case">PromptDialog</span>
					</Box>,
					component_description: "A dialog that prompts the user to input data",
					component_example_jsx_string: PromptDialogHtmlExample,
					component_jsx:
					<Box>
						<Button color="info" variant="contained" onClick={ () => {
							uc_setUserInterface_PromptDialogDisplay({
								display: true,
								prompt: PromptDialogObject
							})
						} }>Show Prompt</Button>
					</Box>,
					component_props: {
						prompt: {
							propKey: "prompt",
							propTypeOrInterface: "TsInterface_PromptDialogObject",
							propDescription: "Object used to generate prompt dialog",
							propExampleValue: PromptDialogObjectPreview
						},
					},
					component_todo: {
						1: "Make sure that users can't click submit without entering in a value first",
					}
				},
			}
		}

		// Functions



		// JSX Generation
		const returnJSX_Component = (): TsType_JSX => {
			let componentJSX =
			<Box>
				<ComponentDocumentation documentation={ documentationObject } />
			</Box>
			return componentJSX
		}

		// Render
		return <>{ returnJSX_Component() }</>
	}