//======================================//
//		  ooOOOO USED BY BOILERPLATE    //
//		 oo		 _____					//
//		_I__n_n__||_|| ________			//
//	  >(_________|_7_|-|______|			//
//	   /o ()() ()() o   oo  oo			//
//======================================//

///////////////////////////////
// Imports
///////////////////////////////

	// React

// Components
// Context
// Services
import {
	getProp
} from 'rfbp_core/services/helper_functions'
// Typescript
import {
	TsType_JSX,
	TsType_String
} from 'rfbp_core/typescript/global_types'
// Config
// MUI Icons
// MUI Components
import {
	Box
} from '@mui/material'

		// Third Party


///////////////////////////////
// Typescript
///////////////////////////////

	interface TsInterface_ComponentProps {
		height: TsType_String
		color: TsType_String
	}


///////////////////////////////
// Variables
///////////////////////////////

	// Displayed Translatable Strings


///////////////////////////////
// Functions
///////////////////////////////


///////////////////////////////
// Component
///////////////////////////////

	export const LogoMain = ( props: TsInterface_ComponentProps ): TsType_JSX => {

		// Props
		let height: TsInterface_ComponentProps["height"] = 				getProp( props, "height", "30px" )
		let color: TsInterface_ComponentProps["color"] = 				getProp( props, "color", "#000000" )

		// Hooks - useContext, useState, useReducer, other

		// Hooks - useEffect

		// Other Variables

		// Functions

		// Call Functions

		// JSX Generation
		const Logo =
		<Box>
			<svg height={ height } viewBox="0 0 2100 210">
				<g transform="matrix(0.959476,0,0,0.888952,-434.803,-413.054)">
					<path fill={ color } d="M527.867,693.399L453.167,693.399L466.749,470.014L560.035,470.014L587.913,583.672L590.415,583.672L618.294,470.014L711.58,470.014L725.161,693.399L650.461,693.399L646.172,585.102L643.67,585.102L616.507,693.399L561.822,693.399L534.301,585.102L532.156,585.102L527.867,693.399Z"/>
					<path fill={ color } d="M741.245,581.885C741.245,541.14 748.87,511.414 764.12,492.71C779.37,474.005 806.891,464.652 846.683,464.652C886.475,464.652 913.997,474.005 929.246,492.71C944.496,511.414 952.121,541.14 952.121,581.885C952.121,602.139 950.513,619.176 947.296,632.996C944.079,646.816 938.539,658.849 930.676,669.095C922.813,679.341 911.971,686.846 898.151,691.612C884.331,696.378 867.175,698.76 846.683,698.76C826.191,698.76 809.035,696.378 795.215,691.612C781.395,686.846 770.553,679.341 762.69,669.095C754.827,658.849 749.287,646.816 746.07,632.996C742.853,619.176 741.245,602.139 741.245,581.885ZM818.09,544.714L818.09,637.642L847.755,637.642C857.525,637.642 864.613,636.51 869.022,634.247C873.43,631.983 875.634,626.8 875.634,618.699L875.634,525.771L845.611,525.771C836.08,525.771 829.11,526.902 824.702,529.166C820.294,531.43 818.09,536.612 818.09,544.714Z"/>
					<path fill={ color } d="M1171.93,693.399L1093.3,693.399L1063.99,626.92L1048.62,626.92L1048.62,693.399L977.14,693.399L977.14,470.014L1089.73,470.014C1140.96,470.014 1166.57,496.105 1166.57,548.288C1166.57,584.03 1155.49,607.619 1133.33,619.056L1171.93,693.399ZM1048.62,527.2L1048.62,571.877L1065.07,571.877C1073.64,571.877 1079.9,570.984 1083.83,569.197C1087.76,567.41 1089.73,563.299 1089.73,556.866L1089.73,542.212C1089.73,535.778 1087.76,531.668 1083.83,529.881C1079.9,528.094 1073.64,527.2 1065.07,527.2L1048.62,527.2Z"/>
					<path fill={ color } d="M1391.74,693.399L1313.11,693.399L1283.8,626.92L1268.43,626.92L1268.43,693.399L1196.95,693.399L1196.95,470.014L1309.54,470.014C1360.77,470.014 1386.38,496.105 1386.38,548.288C1386.38,584.03 1375.3,607.619 1353.14,619.056L1391.74,693.399ZM1268.43,527.2L1268.43,571.877L1284.88,571.877C1293.45,571.877 1299.71,570.984 1303.64,569.197C1307.57,567.41 1309.54,563.299 1309.54,556.866L1309.54,542.212C1309.54,535.778 1307.57,531.668 1303.64,529.881C1299.71,528.094 1293.45,527.2 1284.88,527.2L1268.43,527.2Z"/>
					<path fill={ color } d="M1559.73,609.049L1488.25,609.049L1488.25,636.212L1575.81,636.212L1575.81,693.399L1416.76,693.399L1416.76,470.014L1574.03,470.014L1565.09,527.2L1488.25,527.2L1488.25,557.223L1559.73,557.223L1559.73,609.049Z"/>
					<path fill={ color } d="M1749.16,693.399L1606.19,693.399L1606.19,470.014L1677.68,470.014L1677.68,636.212L1749.16,636.212L1749.16,693.399Z"/>
					<path fill={ color } d="M1911.79,693.399L1768.82,693.399L1768.82,470.014L1840.3,470.014L1840.3,636.212L1911.79,636.212L1911.79,693.399Z"/>
					<path fill={ color } d="M1932.16,687.323C1926.92,681.843 1924.3,672.073 1924.3,658.015C1924.3,643.956 1926.92,634.068 1932.16,628.349C1937.4,622.631 1947.7,619.771 1963.07,619.771C1978.44,619.771 1988.75,622.631 1993.99,628.349C1999.23,634.068 2001.85,643.956 2001.85,658.015C2001.85,672.073 1999.23,681.843 1993.99,687.323C1988.75,692.803 1978.44,695.544 1963.07,695.544C1947.7,695.544 1937.4,692.803 1932.16,687.323Z"/>
					<path fill={ color } d="M2026.87,693.399L2026.87,470.014L2126.95,470.014C2167.22,470.014 2194.86,478.592 2209.87,495.748C2224.88,512.904 2232.39,541.557 2232.39,581.706C2232.39,621.856 2224.88,650.509 2209.87,667.665C2194.86,684.821 2167.22,693.399 2126.95,693.399L2026.87,693.399ZM2128.02,527.2L2098.36,527.2L2098.36,636.212L2128.02,636.212C2137.79,636.212 2144.88,635.081 2149.29,632.817C2153.7,630.553 2155.9,625.371 2155.9,617.269L2155.9,546.143C2155.9,538.042 2153.7,532.859 2149.29,530.596C2144.88,528.332 2137.79,527.2 2128.02,527.2Z"/>
					<path fill={ color } d="M2400.37,609.049L2328.89,609.049L2328.89,636.212L2416.46,636.212L2416.46,693.399L2257.41,693.399L2257.41,470.014L2414.67,470.014L2405.74,527.2L2328.89,527.2L2328.89,557.223L2400.37,557.223L2400.37,609.049Z"/>
					<path fill={ color } d="M2568.72,470.014L2644.13,470.014L2588.73,693.399L2484.37,693.399L2428.97,470.014L2504.38,470.014L2534.76,611.908L2537.98,611.908L2568.72,470.014Z"/>
				</g>
			</svg>
		</Box>

		return Logo
	}
