//======================================//
//		  ooOOOO USED BY BOILERPLATE    //
//		 oo		 _____					//
//		_I__n_n__||_|| ________			//
//	  >(_________|_7_|-|______|			//
//	   /o ()() ()() o   oo  oo			//
//======================================//

///////////////////////////////
// Description
///////////////////////////////

	/*
		DESCRIPTION / USAGE:
			1) Defines Client Types, Application Pages, and Application Navigation
			2) Provides functions to generate navigation and permissions dynamically from specified variables

			ApplicationNavPages ACCESS LEVELS
				always_yes
				default_yes
				default_no
				always_no
				true
				false

			ADDING A NEW PAGE
				1) Create file in containers folder
				2) Import page into "App.tsx"
				3) Add Route into the Router in the "App.tsx" at the bottom
				4) Add Page into the "ApplicationPages" variable of "applicationStructure.tsx" (this file)
				5) IF the page is a root level page, add it to ApplicationNavPages (and ApplicationNavSections if it is in a new section) in "applicationStructure.tsx" (this file)

		TODO:
			[ ] Typescript - might need to import or export interfaces to other files to avoid duplicates?

	*/


///////////////////////////////
// Imports
///////////////////////////////

import {
	Trans
} from 'react-i18next'
import {
	Icon
} from 'rfbp_core/components/icons'
import {
	TsInterface_RootData_ClientPermissions,
	TsInterface_RootData_ClientUser,
	TsInterface_RootData_GlobalUser
} from 'rfbp_core/services/context'
import {
	getProp
} from 'rfbp_core/services/helper_functions'
import {
	TsInterface_UnspecifiedObject,
	TsType_Any,
	TsType_Boolean,
	TsType_JSX,
	TsType_Null,
	TsType_String
} from 'rfbp_core/typescript/global_types'

///////////////////////////////
// Typescript
///////////////////////////////

	type TsType_PageAccess = "always_yes" | "default_yes" | "default_no" | "always_no" | TsType_Boolean | TsType_Null

	export type TsType_ClientTypes = 'Development' | 'Main'
	export type TsType_UserRoles = 'admin'

	interface TsInterface_PageRoleAccessPermissionsObject {
		access: TsType_PageAccess
		highlighted_nav_section: TsType_String
		highlighted_nav_page: TsType_String
	}

	interface TsInterface_ApplicationPages {
		[ key: TsType_String ]: {
			key: TsType_String
			root_nav_page_permission: TsType_String
			url: TsType_Any
		}
	}

	interface TsInterface_ClientTypes {
		[ key: TsType_String ]: {
			key: TsType_ClientTypes
			name: TsType_String | TsType_JSX
			user_roles: {
				[ key: TsType_String ]: {
					key: TsType_UserRoles
					name: TsType_String | TsType_JSX
					icon: TsType_JSX
					home_redirect_page?: TsType_Any
				}
			}
		}
	}

	//=========================//
	//   USED BY BOILERPLATE   //
	//=========================//
	export interface TsInterface_NavPage {
		name: TsType_JSX
		key: TsType_String
		icon: TsType_String
		url: TsType_String
		page_role_access_permissions: {
			[ key: TsType_String ]: TsInterface_PageRoleAccessPermissionsObject
		}
		nav_badges: {
			[ key: TsType_String ]: TsType_Boolean
		}
	}

	interface TsInterface_ApplicationNavPages {
		[ key: TsType_String ]: TsInterface_NavPage
	}

	interface TsInterface_ApplicationNavSections {
		[ key: TsType_String ]: {
			name: TsType_JSX
			key: TsType_String
			links: TsInterface_ApplicationNavPages
		}
	}

	interface TsInterface_SideBarNavObject {
		[ key: TsType_String ]: {
			name: TsType_JSX
			links: {
				[ key: TsType_String ]: TsInterface_NavPage
			}
		}
	}

	interface TsInterface_FlatUserPermissions {
		[ key: TsType_String ]: {
			key: TsType_String
			access: TsType_PageAccess
		}
	}

	interface TsInterface_AvailableUserTypePermissionsObject {
		[ key: TsType_String ]: {
			sectionKey: TsType_String
			sectionName: TsType_JSX
			permissions: {
				[ key: TsType_String ]: {
					access: TsType_PageAccess
					permissionKey: TsType_String
					pageName: TsType_JSX
				}
			}
		}
	}

	interface TsInterface_AvailableClientTypePermissionsObject {
		[ clientTypeKey: TsType_String ]: {
			[ sectionKey: TsType_String ]: {
				sectionKey: TsType_String
				sectionName: TsType_JSX
				permissions: {
					[ key: TsType_String ]: {
						key: TsType_String
						pageName: TsType_JSX
					}
				}
			}
		}
	}

	interface TsInterface_PermissionObject {
		[ permissionKey: TsType_String ]: TsType_Boolean
	}

	interface TsInterface_GenerateActiveUserApplicationPermissionsResult {
		success: TsType_Boolean
		permissions: TsInterface_PermissionObject
		error: {}
	}

	//=========================//
	//   USED BY BOILERPLATE   //
	//=========================//
	export interface TsInterface_NavigationObject {
		[ sectionKey: TsType_String ]: {
			key: TsType_String
			name: TsType_JSX
			links: {
				[ linkKey: TsType_String ]: {
					icon: TsType_String
					key: TsType_String
					name: TsType_JSX
					page_role_access_permissions: {
						[ roleKey: TsType_String ]: TsInterface_PageRoleAccessPermissionsObject
					}
					// url(): TsType_String
					url: TsType_String
				}
			}
		}
	}


///////////////////////////////
// Variables
///////////////////////////////

	// Displayed Translatable Strings
	// { sort-start } - displayed text - scoped sort plugin
	const s_ADMIN: TsType_JSX = 								<Trans>Admin</Trans>
	const s_BOILERPLATE_DOCS: TsType_JSX = 						<Trans>Boilerplate Docs</Trans>
	const s_CLIENT_PERMISSIONS: TsType_JSX = 					<Trans>Client Permissions</Trans>
	const s_DEVELOPMENT: TsType_JSX = 							<Trans>Development</Trans>
	const s_HOME: TsType_JSX = 									<Trans>Home</Trans>
	const s_MAIN: TsType_JSX = 									<Trans>Main</Trans>
	const s_SETTINGS: TsType_JSX = 								<Trans>Settings</Trans>
	const s_TEST_LAB: TsType_JSX = 								<Trans>Test Lab</Trans>
	// { sort-end } - displayed text

///////////////////////////////
// Functions
///////////////////////////////


///////////////////////////////
// Exports
///////////////////////////////


	// Client Types
	export const ClientUserRoles: TsInterface_UnspecifiedObject = {
		admin: {
			color: "warning",
			icon: <Icon type="solid" icon="crown" />,
			key: "admin",
			name: s_ADMIN,
		},
	}

	//=========================//
	//   USED BY BOILERPLATE   //
	//=========================//

	// All Pages URLs
	export const ApplicationPages: TsInterface_ApplicationPages = {
		// Unauthenticated
		UnauthenticatedLinksPage: 					{ key: "UnauthenticatedLinksPage", 					root_nav_page_permission: "unauthenticated", 						url: () => "/links" },
		UnauthenticatedLoginPage: 					{ key: "UnauthenticatedLoginPage", 					root_nav_page_permission: "unauthenticated", 						url: () => "/login" },
		// Home
		HomePage: 									{ key: "HomePage", 									root_nav_page_permission: "HomePage", 								url: () => "/" },
		UserSettingsPage: 							{ key: "UserSettingsPage", 							root_nav_page_permission: "UserSettingsPage", 						url: () => "/settings" },
		// Geoguesser
		GeoguesserPage: 							{ key: "GeoguesserPage", 							root_nav_page_permission: "HomePage", 								url: () => "/geo" },
		// Pirate Game
		PirateGameDesktopStartPage: 				{ key: "PirateGameDesktopStartPage", 				root_nav_page_permission: "HomePage", 								url: () => "/pirates" },
		PirateGameDesktopLobbyPage: 				{ key: "PirateGameDesktopLobbyPage", 				root_nav_page_permission: "HomePage", 								url: ( id: TsType_String ) => "/pirates/l/" + id },
		PirateGameDesktopGamePage: 					{ key: "PirateGameDesktopGamePage", 				root_nav_page_permission: "HomePage", 								url: ( id: TsType_String ) => "/pirates/g/" + id },
		PirateGameMobileJoinPage: 					{ key: "PirateGameMobileJoinPage", 					root_nav_page_permission: "HomePage", 								url: ( id: TsType_String ) => "/pirate/j/" + id },
		PirateGameMobileGamePage: 					{ key: "PirateGameMobileGamePage", 					root_nav_page_permission: "HomePage", 								url: ( id: TsType_String, uid: TsType_String ) => "/pirate/g/" + id + "/" + uid },
		// Super
		SuperClientPermissionsManagementPage: 		{ key: "SuperClientPermissionsManagementPage", 		root_nav_page_permission: "SuperClientPermissionsManagementPage", 	url: () => "/super/client_permissions/" },
		SuperTestLabPage: 							{ key: "SuperTestLabPage", 							root_nav_page_permission: "SuperTestLabPage", 						url: () => "/super/test_lab/" },
		// Boilerplate Docs
		BoilerplateDocsPage: 						{ key: "BoilerplateDocsPage", 						root_nav_page_permission: "BoilerplateDocsPage", 					url: () => "/boilerplate/docs/components" },
	}

	export const ClientTypes: TsInterface_ClientTypes = {
		Development: {
			key: "Development",
			name: s_DEVELOPMENT,
			user_roles: {
				admin: {
					key: "admin",
					name: ClientUserRoles["admin"]["name"],
					icon: ClientUserRoles["admin"]["icon"],
					home_redirect_page: ApplicationPages.UserSettingsPage, // TODO
				}
			}
		},
		Main: {
			key: "Main",
			name: s_MAIN,
			user_roles: {
				admin: {
					key: "admin",
					name: ClientUserRoles["admin"]["name"],
					icon: ClientUserRoles["admin"]["icon"],
					home_redirect_page: ApplicationPages.UserSettingsPage, // TODO
				},
			}
		},
	}

	export const ApplicationMajorPages: TsInterface_ApplicationPages = {
		HomePage: ApplicationPages["HomePage"],
		UserSettingsPage: ApplicationPages["UserSettingsPage"],
	}

	// Nav Sections
	export const ApplicationNavSections: TsInterface_ApplicationNavSections = {
		HomeSection: {
			name: <></>,
			key: "HomeSection",
			links: {}
		},
		DataSection: {
			name: <></>,
			key: "DataSection",
			links: {}
		},
		CalculationsSection: {
			name: <></>,
			key: "CalculationsSection",
			links: {}
		},
		AnalysisSection: {
			name: <></>,
			key: "AnalysisSection",
			links: {}
		},
		ExportSection: {
			name: <></>,
			key: "ExportSection",
			links: {}
		},
		SuperSection: {
			name: <></>,
			key: "SuperSection",
			links: {}
		},
	}

	//=========================//
	//   USED BY BOILERPLATE   //
	//=========================//

	// Nav Pages
	export const ApplicationNavPages: TsInterface_ApplicationNavPages = {
		HomePage: {
			name: s_HOME,
			key: "HomePage",
			icon: "house-blank",
			url: ApplicationPages.HomePage.url(),
			page_role_access_permissions: {
				Development_admin: {		access: "always_yes",		highlighted_nav_section: "HomeSection", 			highlighted_nav_page: "HomePage" },
				Main_admin: {				access: "always_yes",		highlighted_nav_section: "HomeSection", 			highlighted_nav_page: "HomePage" },
			},
			nav_badges: {},
		},
		UserSettingsPage: {
			name: s_SETTINGS,
			key: "UserSettingsPage",
			icon: "gear",
			url: ApplicationPages.UserSettingsPage.url(),
			page_role_access_permissions: {
				// Development_admin: {		access: "always_yes",		highlighted_nav_section: "HomeSection", 			highlighted_nav_page: "UserSettingsPage" },
				// Main_admin: {				access: "always_yes",		highlighted_nav_section: "HomeSection", 			highlighted_nav_page: "UserSettingsPage" },
			},
			nav_badges: {},
		},
		// Main

		// Development
		SuperClientPermissionsManagementPage: {
			name: s_CLIENT_PERMISSIONS,
			key: "SuperClientPermissionsManagementPage",
			icon: "square-check",
			url: ApplicationPages.SuperClientPermissionsManagementPage.url(),
			page_role_access_permissions: {
				Development_admin: {		access: "always_yes",		highlighted_nav_section: "SuperSection", 			highlighted_nav_page: "SuperClientPermissionsManagementPage" },
			},
			nav_badges: {},
		},
		SuperTestLabPage: {
			name: s_TEST_LAB,
			key: "SuperTestLabPage",
			icon: "flask",
			url: ApplicationPages.SuperTestLabPage.url(),
			page_role_access_permissions: {
				Development_admin: {		access: "always_yes",		highlighted_nav_section: "SuperSection", 			highlighted_nav_page: "SuperTestLabPage" },
			},
			nav_badges: {},
		},
		BoilerplateDocsPage: {
			name: s_BOILERPLATE_DOCS,
			key: "BoilerplateDocsPage",
			icon: "book",
			url: ApplicationPages.BoilerplateDocsPage.url(),
			page_role_access_permissions: {
				Development_admin: {		access: "always_yes",		highlighted_nav_section: "SuperSection", 			highlighted_nav_page: "BoilerplateDocsPage" },
			},
			nav_badges: {},
		}
	}

	//=========================//
	//   USED BY BOILERPLATE   //
	//=========================//
	export const EmptyApplicationNavigationObject: TsInterface_ApplicationNavSections = {}

	//=========================//
	//   USED BY BOILERPLATE   //
	//=========================//
	export const generateApplicationNavigationObject = (
		clientType: TsType_String,
		userRole: TsType_String
	): TsInterface_SideBarNavObject => {
		let sideBarNavObject: TsInterface_SideBarNavObject = {}
		for ( let rootNavPageKey in ApplicationNavPages ){
			let rootNavPage: TsInterface_NavPage = ApplicationNavPages[rootNavPageKey]
			let userRoleAccess = getProp( rootNavPage["page_role_access_permissions"][clientType + "_" + userRole], "access", false )
			if ( userRoleAccess !== false ){
				let userSpecificRootNavPage = rootNavPage["page_role_access_permissions"][clientType + "_" + userRole]
				if ( sideBarNavObject[ userSpecificRootNavPage.highlighted_nav_section ] == null && ApplicationNavSections[ userSpecificRootNavPage.highlighted_nav_section ] != null ){
					sideBarNavObject[ userSpecificRootNavPage.highlighted_nav_section ] = ApplicationNavSections[ userSpecificRootNavPage.highlighted_nav_section ]
				}
				sideBarNavObject[ userSpecificRootNavPage.highlighted_nav_section ]["links"][ userSpecificRootNavPage.highlighted_nav_page ] = rootNavPage
			}
		}
		return sideBarNavObject
	}

	export const generateAvailableClientLevelPermissions = (): TsInterface_AvailableClientTypePermissionsObject => {
		let availableClientTypePermissions: TsInterface_AvailableClientTypePermissionsObject = {}
		// Loop through each client type to
		for ( let clientTypeKey in ClientTypes ){
			availableClientTypePermissions[clientTypeKey] = {}
			let clientTypeUserRoles = ClientTypes[clientTypeKey]["user_roles"]
			for ( let userRoleKey in clientTypeUserRoles ){
				let emulatedNav = generateApplicationNavigationObject( clientTypeKey, userRoleKey )
				for ( let emulatedSectionKey in emulatedNav ){
					let emulatedSection = emulatedNav[emulatedSectionKey]
					if ( availableClientTypePermissions[clientTypeKey][emulatedSectionKey] == null ){
						availableClientTypePermissions[clientTypeKey][emulatedSectionKey] ={
							sectionKey: emulatedSectionKey,
							sectionName: emulatedSection.name,
							permissions: {}
						}
					}
					for ( let emulatedRootNavLinkKey in emulatedSection["links"] ){
						let emulatedRootNavLink = emulatedSection["links"][emulatedRootNavLinkKey]
						let compositePermissionKey = "desktop_page_access_" + clientTypeKey + "_" + emulatedSectionKey + "_" + emulatedRootNavLinkKey
						let userRoleWithAccess = false
						for ( let roleKey in ApplicationNavPages[emulatedRootNavLinkKey]["page_role_access_permissions"] ){
							if ( roleKey.substring( 0, clientTypeKey.length ) === clientTypeKey ){
								if ( ApplicationNavPages[emulatedRootNavLinkKey]["page_role_access_permissions"][roleKey] != null && ApplicationNavPages[emulatedRootNavLinkKey]["page_role_access_permissions"][roleKey]["access"] !== false ){
									userRoleWithAccess = true
								}
							}
						}
						if ( userRoleWithAccess === true ){
							availableClientTypePermissions[clientTypeKey][emulatedSectionKey]["permissions"][compositePermissionKey] = {
								key: compositePermissionKey,
								pageName: emulatedRootNavLink.name
							}
						}
					}
				}
			}
		}
		return availableClientTypePermissions
	}

	export const generateAvailableUserLevelPermissions = (
		userRole: TsType_UserRoles,
		clientType: TsType_ClientTypes,
		rootClientPermissions: TsInterface_RootData_ClientPermissions
	): TsInterface_AvailableUserTypePermissionsObject => {
		let availableUserTypePermissionsObject: TsInterface_AvailableUserTypePermissionsObject = {}
		let flatUserPermissions: TsInterface_FlatUserPermissions = {}
		if ( userRole != null && clientType != null && rootClientPermissions != null ){
			let emulatedNav = generateApplicationNavigationObject( clientType, userRole )
			// Loop through top level nav pages (which permissions are based on)
			for ( let navPageKey in ApplicationNavPages ){
				let navPage = ApplicationNavPages[navPageKey]
				// If the nav page has an access permission for the authenticated user's client type and user role
				if ( navPage["page_role_access_permissions"] != null && navPage["page_role_access_permissions"][clientType + "_" + userRole] ){
					let pageRoleAccessPermissions = navPage["page_role_access_permissions"][clientType + "_" + userRole]
					// If access is granted at the client level
					let compositePermissionOverrideKey = "desktop_page_access_" + clientType + "_" + pageRoleAccessPermissions["highlighted_nav_section"] + "_" + pageRoleAccessPermissions["highlighted_nav_page"]
					if ( pageRoleAccessPermissions["highlighted_nav_section"] != null && pageRoleAccessPermissions["highlighted_nav_page"] != null && rootClientPermissions[ compositePermissionOverrideKey ] ){
						if ( pageRoleAccessPermissions != null && pageRoleAccessPermissions["access"] ){
							// Just show the permissions that the client AND user role have access to
							flatUserPermissions[compositePermissionOverrideKey] = {
								key: compositePermissionOverrideKey,
								access: pageRoleAccessPermissions["access"]
							}
						}
					}
				}
			}
			for ( let emulatedSectionKey in emulatedNav ){
				let emulatedSection = emulatedNav[emulatedSectionKey]
				if ( availableUserTypePermissionsObject[emulatedSectionKey] == null ){
					availableUserTypePermissionsObject[emulatedSectionKey] ={
						sectionKey: emulatedSectionKey,
						sectionName: emulatedSection.name,
						permissions: {}
					}
				}
				for ( let emulatedRootNavLinkKey in emulatedSection["links"] ){
					let emulatedRootNavLink = emulatedSection["links"][emulatedRootNavLinkKey]
					let compositePermissionKey = "desktop_page_access_" + clientType + "_" + emulatedSectionKey + "_" + emulatedRootNavLinkKey
					if ( flatUserPermissions[compositePermissionKey] != null ){
						availableUserTypePermissionsObject[emulatedSectionKey]["permissions"][compositePermissionKey] = {
							access: flatUserPermissions[compositePermissionKey]["access"],
							permissionKey: compositePermissionKey,
							pageName: emulatedRootNavLink.name
						}
					}
				}
			}
		}
		return availableUserTypePermissionsObject
	}

	//=========================//
	//   USED BY BOILERPLATE   //
	//=========================//
	export const generateActiveUserApplicationPermissions = (
		rootClientUser: TsInterface_RootData_ClientUser,
		rootGlobalUser: TsInterface_RootData_GlobalUser,
		rootClientPermissions: TsInterface_RootData_ClientPermissions
	): Promise< TsInterface_GenerateActiveUserApplicationPermissionsResult > => {
		// permissions are of the form "desktop_page_access_$clientType_$sectionKey_$pageKey" where $clientType, $sectionKey and $pageKey values are all in camelCase
		return new Promise( ( resolve, reject ) => {
			let permissions: TsInterface_PermissionObject = {
				HomePage: true
			}
			let userRole = getProp( rootClientUser, "user_role", null )
			if ( userRole == null ){
				userRole = getProp( rootGlobalUser, "user_role", null )
			}
			if ( userRole != null && rootClientPermissions != null && rootClientPermissions["client_type"] != null ){
				let clientType = rootClientPermissions["client_type"]
				// Loop through top level nav pages (which is what permissions are based on)
				for ( let navPageKey in ApplicationNavPages ){
					let navPage = ApplicationNavPages[navPageKey]
					// If the nav page has an access permission for the authenticated user's client type and user role
					if ( navPage["page_role_access_permissions"] != null && navPage["page_role_access_permissions"][clientType + "_" + userRole] ){
						let pageRoleAccessPermissions = navPage["page_role_access_permissions"][clientType + "_" + userRole]
						// If access is granted at the client level
						let compositePermissionOverrideKey = "desktop_page_access_" + clientType + "_" + pageRoleAccessPermissions["highlighted_nav_section"] + "_" + pageRoleAccessPermissions["highlighted_nav_page"]
						if ( pageRoleAccessPermissions["highlighted_nav_section"] != null && pageRoleAccessPermissions["highlighted_nav_page"] != null && rootClientPermissions[ compositePermissionOverrideKey ] ){
							if ( pageRoleAccessPermissions != null && pageRoleAccessPermissions["access"] ){
								// Depending on user role and overrides, determine access boolean
								if ( pageRoleAccessPermissions["access"] === "always_yes" ){
									permissions[ navPage.key ] = true
								} else if ( pageRoleAccessPermissions["access"] === "default_yes" ) {
									if ( rootClientUser != null && rootClientUser["permission_overrides"] != null && rootClientUser["permission_overrides"][ compositePermissionOverrideKey ] != null ){
										permissions[ navPage.key ] = rootClientUser["permission_overrides"][ compositePermissionOverrideKey ]
									} else {
										permissions[ navPage.key ] = true
									}
								} else if ( pageRoleAccessPermissions["access"] === "default_no" ) {
									if ( rootClientUser != null && rootClientUser["permission_overrides"] != null && rootClientUser["permission_overrides"][ compositePermissionOverrideKey ] != null ){
										permissions[ navPage.key ] = rootClientUser["permission_overrides"][ compositePermissionOverrideKey ]
									} else {
										permissions[ navPage.key ] = false
									}
								} else if ( pageRoleAccessPermissions["access"] === "always_no" ) {
									permissions[ navPage.key ] = false
								} else {
									permissions[ navPage.key ] = false
								}
							} else {
								permissions[ navPage.key ] = false
							}
						} else {
							permissions[ navPage.key ] = false
						}
					}
				}
				permissions["HomePage"] = true
				permissions["UserSettingsPage"] = true
				resolve({
					success: true,
					permissions: permissions,
					error: {}
				})
			} else {
				resolve({
					success: false,
					permissions: {},
					error: { message: "", details: "", code: "ER-D-SAS-GAUAP-01"}
				})
			}
		})
	}