//////////////////////////////////////////
//		  ooOOOO BOILERPLATE FILE		//
//		 oo		 _____					//
//		_I__n_n__||_|| ________			//
//	  >(_________|_7_|-|______|			//
//	   /o ()() ()() o   oo  oo			//
//////////////////////////////////////////

///////////////////////////////
// Description
///////////////////////////////

	/*
		DESCRIPTION / USAGE:
			Basic Map Component

		TODO:
			[ ] Couple of typescript ignores and general fuzziness
			[ ] Customizable Clusterer
			[ ] Routes
			Figure out recenter issue
				https://codesandbox.io/embed/boring-easley-9w8fi
				https://blog.bitsrc.io/5-ways-to-avoid-react-component-re-renderings-90241e775b8c
				https://stackblitz.com/edit/react-fwxm1i?file=Map.js

		Documentation:

			Google Maps
				https://react-google-maps-api-docs.netlify.app/#section-introduction

			GoogleMap - import { GoogleMap, LoadScript } from '@react-google-maps/api'
				center 						LatLng | LatLngLiteral | undefined
				clickableIcons 				boolean | undefined
				extraMapTypes 				MapType[] | undefined
				heading 					number | undefined
				id 							string | undefined
				mapContainerClassName 		string | undefined
				mapContainerStyle 			CSSProperties | undefined
				mapTypeId 					string | undefined
				onBoundsChanged 			(() => void) | undefined
				onCenterChanged 			(() => void) | undefined
				onClick 					((e: MapMouseEvent) => void) | undefined
				onDblClick 					((e: MapMouseEvent) => void) | undefined
				onDrag 						(() => void) | undefined
				onDragEnd 					(() => void) | undefined
				onDragStart 				(() => void) | undefined
				onHeadingChanged 			(() => void) | undefined
				onIdle 						(() => void) | undefined
				onLoad 						((map: Map<Element>) => void | Promise<void>) | undefined
				onMapTypeIdChanged 			(() => void) | undefined
				onMouseMove 				((e: MapMouseEvent) => void) | undefined
				onMouseOut 					((e: MapMouseEvent) => void) | undefined
				onMouseOver 				((e: MapMouseEvent) => void) | undefined
				onProjectionChanged 		(() => void) | undefined
				onResize 					(() => void) | undefined
				onRightClick 				((e: MapMouseEvent) => void) | undefined
				onTilesLoaded 				(() => void) | undefined
				onTiltChanged 				(() => void) | undefined
				onUnmount 					((map: Map<Element>) => void | Promise<void>) | undefined
				onZoomChanged 				(() => void) | undefined
				options 					MapOptions | undefined
				streetView 					StreetViewPanorama | undefined
				tilt 						number | undefined
				zoom 						number | undefined

			InfoWindow - import { InfoWindow } from '@react-google-maps/api'
				anchor 						MVCObject | undefined
				onCloseClick 				(() => void) | undefined
				onContentChanged 			(() => void) | undefined
				onDomReady 					(() => void) | undefined
				onLoad 						((infoWindow: InfoWindow) => void) | undefined
				onPositionChanged 			(() => void) | undefined
				onUnmount 					((infoWindow: InfoWindow) => void) | undefined
				onZindexChanged 			(() => void) | undefined
				options 					InfoWindowOptions | undefined
				position 					LatLng | LatLngLiteral | undefined
				zIndex 						number | undefined

			ClustererComponent - import { MarkerClusterer } from '@react-google-maps/api'
				averageCenter 				boolean | undefined
				batchSizeIE 				number | undefined
				calculator 					TCalculator | undefined
				clusterClass 				string | undefined
				enableRetinaIcons 			boolean | undefined
				gridSize 					number | undefined
				ignoreHidden 				boolean | undefined
				imageExtension 				string | undefined
				imagePath 					string | undefined
				imageSizes 					number[] | undefined
				maxZoom 					number | undefined
				minimumClusterSize 			number | undefined
				onClick 					((cluster: Cluster) => void) | undefined
				onClusteringBegin 			((markerClusterer: Clusterer) => void) | undefined
				onClusteringEnd 			((markerClusterer: Clusterer) => void) | undefined
				onLoad 						((markerClusterer: Clusterer) => void) | undefined
				onMouseOut 					((cluster: Cluster) => void) | undefined
				onMouseOver 				((cluster: Cluster) => void) | undefined
				onUnmount 					((markerClusterer: Clusterer) => void) | undefined
				options 					ClustererOptions | undefined
				styles 						ClusterIconStyle[] | undefined
				title 						string | undefined
				zoomOnClick 				boolean | undefined

			DirectionsRenderer - import { DirectionsRenderer } from '@react-google-maps/api'
				directions 					DirectionsResult | undefined
				onDirectionsChanged 		(() => void) | undefined
				onLoad 						((directionsRenderer: DirectionsRenderer) => void) | undefined
				onUnmount 					((directionsRenderer: DirectionsRenderer) => void) | undefined
				options 					DirectionsRendererOptions | undefined
				panel 						Element | undefined
				routeIndex 					number | undefined

			DirectionsService - import { DirectionsService } from '@react-google-maps/api'
				callback 					(result: DirectionsResult, status: DirectionsStatus) => void
				options 					DirectionsRequest
				onLoad 						((directionsService: DirectionsService) => void) | undefined
				onUnmount 					((directionsService: DirectionsService) => void) | undefined

			HeatmapLayer - import { HeatmapLayer } from '@react-google-maps/api'
				data 						MVCArray<LatLng | WeightedLocation> | LatLng[] | WeightedLocation[]
				onLoad 						((heatmapLayer: HeatmapLayer) => void) | undefined
				onUnmount 					((heatmapLayer: HeatmapLayer) => void) | undefined
				options 					HeatmapLayerOptions | undefined

			Marker - import { Marker } from '@react-google-maps/api'
				anchorPoint					Point
				animation 					Animation
				clickable					boolean
				collisionBehavior 			string|CollisionBehavior
				crossOnDrag 				boolean
				cursor 						string
				draggable 					boolean
				icon 						string | Icon | Symbol
					url 						string
					anchor 						Point
					labelOrigin 				Point
					origin 						Point
					scaledSize 					Size
					size 						Size
				label 						string | MarkerLabel
					text 						string
					className 					string
					color 						string
					fontFamily 					string
					fontSize 					string
					fontWeight 					string
				map 						Map | StreetViewPanorama
				opacity 					number
				optimized 					boolean
				position 					LatLng | LatLngLiteral
					lat 						number
					lng 						number
				shape 						MarkerShape
				title 						string
				visible 					boolean
				zIndex 						number

	*/


///////////////////////////////
// Imports
///////////////////////////////

import React, {
	forwardRef,
	useEffect,
	useImperativeHandle,
	useMemo,
	useRef,
	useState
} from 'react'
import {
	themeVariables
} from 'rfbp_aux/config/app_theme' // OUTSIDE BOILERPLATE
import {
	TsInterface_MapMarker,
	TsInterface_MapMarkers,
	TsInterface_MapSettings,
	TsType_GoogleMapsIcon,
	TsType_GoogleMapsSymbol,
	TsType_MapOnClick
} from 'rfbp_core/components/map'
import {
	getProp,
	objectToArray
} from 'rfbp_core/services/helper_functions'
import {
	TsType_Any,
	TsType_Boolean,
	TsType_JSX,
	TsType_Number,
	TsType_String,
	TsType_Unknown
} from 'rfbp_core/typescript/global_types'
import {
	Box
} from '@mui/material/'
import {
	GoogleMap,
	HeatmapLayer,
	Marker,
	MarkerClusterer
} from '@react-google-maps/api'

///////////////////////////////
// Typescript
///////////////////////////////

	interface TsInterface_ComponentProps {
		mapMarkers: TsInterface_MapMarkers
		mapOnClick?: TsType_MapOnClick
		mapSettings: TsInterface_MapSettings
	}


///////////////////////////////
// Variables
///////////////////////////////

	// Displayed Translatable Strings
	// { sort-start } - displayed text - scoped sort plugin

	// { sort-end } - displayed text


///////////////////////////////
// Functions
///////////////////////////////

	const returnHeatMapData = ( input: TsInterface_MapMarkers ): google.maps.LatLng[] => {
		let output = []
		for ( let inputItemKey in input ){
			let item = input[ inputItemKey ]
			if (window != null && window.google != null && window.google.maps != null){
				output.push( new window.google.maps.LatLng( item.position.lat, item.position.lng ) )
			}
		}
		return output
	}

	const recalculateMapBoundsProper = ( ur_mapRef: React.MutableRefObject< TsType_Any >, mapMarkers: TsInterface_MapMarkers, refitBounds: TsType_Boolean ) => {
		let bounds = new window.google.maps.LatLngBounds()
		if ( ur_mapRef != null && ur_mapRef.current != null && window != null && window.google != null && window.google.maps != null ){
			for ( let markersIndex in mapMarkers ) {
				let marker = mapMarkers[ markersIndex ]
				if( marker != null && marker.position != null && marker.position.lat != null && marker.position.lng != null ){
					let latlng = new window.google.maps.LatLng( marker.position.lat, marker.position.lng )
					bounds.extend( latlng )
				}
			}
			if ( ur_mapRef.current.setCenter != null ){ ur_mapRef.current.setCenter( bounds.getCenter() ) }
			if ( refitBounds === true ){
				if ( ur_mapRef.current.fitBounds != null ){ ur_mapRef.current.fitBounds( bounds ) }
			}
		}
	}

	const setNewMapCenterProper = ( ur_mapRef: React.MutableRefObject< TsType_Any >, lat: TsType_Number, lng: TsType_Number, zoom: TsType_Number ) => {
		if ( ur_mapRef != null && ur_mapRef.current != null && window != null && window.google != null && window.google.maps != null ){
			ur_mapRef.current.setCenter( { lat: lat, lng: lng } )
			ur_mapRef.current.setZoom( zoom )
		}
	}

///////////////////////////////
// Component
///////////////////////////////

	export const MapBasic = forwardRef( (props: TsInterface_ComponentProps, ref: React.ForwardedRef<TsType_Unknown>): TsType_JSX => {

		// Props
		let pr_mapSettings: TsInterface_ComponentProps["mapSettings"] = 			getProp( props, "mapSettings", {} )
		let pr_mapMarkers: TsInterface_ComponentProps["mapMarkers"] = 				getProp( props, "mapMarkers", {} )
		let pr_mapOnClick: TsInterface_ComponentProps["mapOnClick"] = 				getProp( props, "mapOnClick", ( lat: TsType_Number, lng: TsType_Number ) => {} )
		let pr_initialMapCenterLat: TsType_Number = 								getProp( pr_mapSettings, "center_lat", 0 )
		let pr_initialMapCenterLng: TsType_Number = 								getProp( pr_mapSettings, "center_lng", 0 )
		let pr_mapWidth: TsType_String = 											getProp( pr_mapSettings, "width", "100%" )
		let pr_mapHeight: TsType_String =											getProp( pr_mapSettings, "height", "500px" )
		let pr_mapZoom: TsType_Number = 											getProp( pr_mapSettings, "zoom", 4 )

		// Hooks - useContext, useState, useReducer, other
		// { sort-start } - hooks
		const [ us_mapCenter, us_setMapCenter ] = useState({ lat: pr_initialMapCenterLat, lng: pr_initialMapCenterLng })
		const ur_mapRef = useRef( null )
		// { sort-end } - hooks

		useImperativeHandle( ref, () => ({
			// Available outside map component
			recalculateMapBounds( refitBounds: TsType_Boolean ) {
				recalculateMapBoundsProper( ur_mapRef, pr_mapMarkers, refitBounds )
			},
			mapCenter: us_mapCenter,
			setNewMapCenter( lat: TsType_Number, lng: TsType_Number, zoom: TsType_Number ) {
				setNewMapCenterProper( ur_mapRef, lat, lng, zoom )
			},
		}))

		// useImperativeHandle( ref, () => ({
		// 	// Available outside map component
		// 	setNewMapCenter( lat: TsType_Number, lng: TsType_Number, zoom: TsType_Number ) {
		// 		setNewMapCenterProper( ur_mapRef, lat, lng, zoom )
		// 	},
		// }))

		// Hooks - useEffect
		useEffect(() => {
			if ( pr_mapSettings["rerender_on_marker_change"] === true && objectToArray( pr_mapMarkers ).length > 0 ){
				// let refitBounds = getProp(pr_mapSettings, "refit_bounds_on_marker_change", true)
				let refitBounds = getProp( pr_mapSettings, "refit_bounds_on_marker_change", false )
				recalculateMapBoundsProper( ur_mapRef, pr_mapMarkers, refitBounds )
			}
			return () => { }
		}, [ pr_mapSettings, pr_mapMarkers ])

		// Other Variables

		// Functions
		const handleMapLoad = ( map: TsType_Any ) => {
			ur_mapRef.current = map
		}

		const handleMapCenterChanged = () => {
			if ( !ur_mapRef.current ) return
			// @ts-expect-error
			if ( ur_mapRef.current.getCenter != null ){
				// @ts-expect-error
				const newCenter = ur_mapRef.current.getCenter().toJSON()
				us_setMapCenter( newCenter )
			}
		}

		const getMarkerTitle = ( marker: TsInterface_MapMarker ): TsInterface_MapMarker["title"] => {
			let title = undefined
			if ( marker != null && marker["title"] != null ){ title = marker["title"] }
			return title
		}

		const getMarkerOnMouseOver = ( marker: TsInterface_MapMarker ): TsInterface_MapMarker["onMouseOver"] => {
			let onMouseOver = () => { }
			if ( marker != null && marker["onMouseOver"] != null ){ onMouseOver = marker["onMouseOver"] }
			return onMouseOver
		}

		const getMarkerOnMouseOut = ( marker: TsInterface_MapMarker ): TsInterface_MapMarker["onMouseOut"] => {
			let onMouseOut = () => { }
			if ( marker != null && marker["onMouseOut"] != null ){ onMouseOut = marker["onMouseOut"] }
			return onMouseOut
		}

		const getMarkerOnClick = ( marker: TsInterface_MapMarker ): TsInterface_MapMarker["onClick"] => {
			let onClick = () => { }
			if ( marker != null && marker["onClick"] != null ){ onClick = marker["onClick"] }
			return onClick
		}

		const getMarkerOnDoubleClick = ( marker: TsInterface_MapMarker ): TsInterface_MapMarker["onDblClick"] => {
			let onDblClick = () => { }
			if ( marker != null && marker["onDblClick"] != null ){ onDblClick = marker["onDblClick"] }
			return onDblClick
		}

		const getMarkerOnRightClick = ( marker: TsInterface_MapMarker ): TsInterface_MapMarker["onRightClick"] => {
			let onRightClick = () => { }
			if ( marker != null && marker["onRightClick"] != null ){ onRightClick = marker["onRightClick"] }
			return onRightClick
		}

		const getMarkerIcon = ( marker: TsInterface_MapMarker ): TsInterface_MapMarker["icon"] => {
			let icon: TsType_String | TsType_GoogleMapsIcon | TsType_GoogleMapsSymbol = ""
			if ( marker != null && marker["icon"] != null ){ icon = marker["icon"] }
			return icon
		}

		const getClusterOptions = () => {
			let clusterOptions = {
				// TODO - customizable url
				imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m'
			}
			return clusterOptions
		}

		const getMapMarkerKey = ( marker: TsInterface_MapMarker, index: TsType_Number): TsType_String => {
			let markerKey = index.toString()
			if ( marker.key != null ){
				markerKey = marker.key
			}
			return markerKey
		}



		// JSX Generation
		const returnJSX_Markers = ( markers: TsInterface_MapMarkers ): TsType_JSX => {
			let markersJSX: TsType_JSX = <></>
			if ( pr_mapSettings.render_markers !== false ){
				markersJSX =
				<>
					{objectToArray( markers ).map(( marker, index ) => (
						<Marker
							// key={ new Date().getTime() + "_" + index }
							key={ getMapMarkerKey( marker, index) }
							position={  marker.position  }
							label={ marker.label }
							onClick={ getMarkerOnClick(marker) }
							onDblClick={ getMarkerOnDoubleClick(marker) }
							onRightClick={ getMarkerOnRightClick(marker) }
							onMouseOver={ getMarkerOnMouseOver(marker) }
							onMouseOut={ getMarkerOnMouseOut(marker) }
							icon={ getMarkerIcon(marker) }
							title={ getMarkerTitle(marker) }
						/>
					))}
				</>
			}
			return markersJSX
		}

		const returnJSX_Heatmap = ( markers: TsInterface_MapMarkers ): TsType_JSX => {
			let heatMapJSX: TsType_JSX = <></>
			if ( pr_mapSettings.render_heatmap !== false ){
				if ( window != null && window.google != null && window.google.maps != null ){
					heatMapJSX =
					<HeatmapLayer
						data={
							returnHeatMapData( markers )
						}
					/>
				}
			}
			return heatMapJSX
		}

		const returnJSX_Clusters = ( markers: TsInterface_MapMarkers ): TsType_JSX => {
			let clustersJSX: TsType_JSX = <></>
			if ( pr_mapSettings.render_clusters === true ){
				clustersJSX =
				<MarkerClusterer options={ getClusterOptions() }>
					{/* @ts-expect-error */}
					{( clusterer ) => objectToArray( mapMarkers ).map(( marker: TsInterface_MapMarker, index: TsType_Number ) => ( <Marker key={ index } position={ marker.position } label={ marker.label } icon={ marker.icon } clusterer={ clusterer } /> )) }
				</MarkerClusterer>
			} else {
				clustersJSX = <></>
			}
			return clustersJSX
		}

		// // TEMP - Drawing Manager and Polygons
		// const onLoad = ( drawingManager: TsType_Any ) => {
		// }

		// const onPolygonComplete = ( polygon: TsType_Any ) => {
		// 	encodePolygon( polygon )
		// }

		// const encodePolygon = ( polygon: TsType_Any ) => {
		// 	//This variable gets all bounds of polygon.
		// 	let path = polygon.getPath()
		// 	let encodeString = google.maps.geometry.encoding.encodePath( path )

		// 	//  google.maps.geometry.encoding.decodePath(encodedString)

		// 	// let polygon = new google.maps.Polygon({
		// 	// 	paths: decodedPolygon,
		// 	// 	editable: false,
		// 	// 	strokeColor: '#FFFF',
		// 	// 	strokeOpacity: 0.8,
		// 	// 	strokeWeight: 2,
		// 	// 	fillColor: '#FFFF',
		// 	// 	fillOpacity: 0.35
		// 	// })

		// 	/* store encodeString in database */
		// }

		// // const tempRanderPolygon = () => {
		// 	// if (
		// 	// 	google != null &&
		// 	// 	google.maps != null &&
		// 	// 	google.maps.geometry != null &&
		// 	// 	google.maps.geometry.encoding != null &&
		// 	// 	google.maps.geometry.encoding.decodePath != null
		// 	// ){
		// 	// 	let encodedPolygonString = "ehttF`lvhT~`Hc~EsdBaaJopGr{CgBt`H"
		// 	// 	let decodedPolygon = google.maps.geometry.encoding.decodePath(encodedPolygonString)
		// 	// 	let polygon = new google.maps.Polygon({
		// 	// 		paths: decodedPolygon,
		// 	// 		editable: false,
		// 	// 		strokeColor: '#FFFF',
		// 	// 		strokeOpacity: 0.8,
		// 	// 		strokeWeight: 2,
		// 	// 		fillColor: '#FFFF',
		// 	// 		fillOpacity: 0.35
		// 	// 	})
		// 	// }
		// // }

		// // tempRanderPolygon()

		// const returnJSX_DrawingManager = (): TsType_JSX => {
		// 	let drawingManagerJSX: TsType_JSX =
		// 	<DrawingManager
		// 		onLoad={ onLoad }
		// 		onPolygonComplete={ onPolygonComplete }
		// 	/>
		// 	return drawingManagerJSX
		// }

		// const returnJSX_Polygon = (): TsType_JSX => {

		// 	// let encodedPolygonString = "ehttF`lvhT~`Hc~EsdBaaJopGr{CgBt`H"
		// 	let encodedPolygonString = "{a_uFr||hTroFlE~mHwhEhjCw}CpsEw_Cpn@grJieGuhEqhE`dCfaDovJbfFzKbqGre@trCd|FunBrsIgk@daKrnHkxTqsBsgKkfHwaB}bHlEg{Lz`Kcp@d|FirC`pJjs@~uGyyDpi@qE}fGgx@yjE_gA~tBgB~qItuAvhE"
		// 	let decodedPolygonPaths = google.maps.geometry.encoding.decodePath( encodedPolygonString )

		// 	const options = {
		// 		fillColor: "#000",
		// 		fillOpacity: .2,
		// 		strokeColor: "#000",
		// 		strokeOpacity: 1,
		// 		strokeWeight: 2,
		// 		clickable: false,
		// 		draggable: false,
		// 		editable: false,
		// 		geodesic: false,
		// 		zIndex: 1
		// 	  }

		// 	let polygonJSX: TsType_JSX =
		// 	<Polygon
		// 		paths={ decodedPolygonPaths }
		// 		options={ options }
		// 	/>
		// 	return polygonJSX
		// }

		// const findMarkersInsidePolygon = () => {

		// 	let encodedPolygonString = "{a_uFr||hTroFlE~mHwhEhjCw}CpsEw_Cpn@grJieGuhEqhE`dCfaDovJbfFzKbqGre@trCd|FunBrsIgk@daKrnHkxTqsBsgKkfHwaB}bHlEg{Lz`Kcp@d|FirC`pJjs@~uGyyDpi@qE}fGgx@yjE_gA~tBgB~qItuAvhE"
		// 	let decodedPolygonPaths = google.maps.geometry.encoding.decodePath( encodedPolygonString )

		// 	let polygon = new google.maps.Polygon({
		// 		paths: decodedPolygonPaths,
		// 		editable: false,
		// 		strokeColor: '#FFFF',
		// 		strokeOpacity: 0.8,
		// 		strokeWeight: 2,
		// 		fillColor: '#FFFF',
		// 		fillOpacity: 0.35
		// 	})

		// 	for ( let markerKey in mapMarkers ){
		// 		let marker = mapMarkers[ markerKey ]

		// 		let containsMarker = google.maps.geometry.poly.containsLocation(
		// 			{ lat: marker.position.lat, lng: marker.position.lng },
		// 			polygon
		// 		)

		// 	}
		// }

		// findMarkersInsidePolygon()

		// TEMP END


		const memoizedMapJSX = useMemo(() => {
			return (
				<GoogleMap
					onLoad={ handleMapLoad }
					mapContainerStyle={{ width: pr_mapWidth, height: pr_mapHeight }}
					zoom={ pr_mapZoom }
					center={ us_mapCenter }
					onCenterChanged={() => {
						handleMapCenterChanged()
					}}
					options={{ styles: themeVariables.map_styles }}
					onClick={( event ) => {
						if ( event != null && event.latLng != null && pr_mapOnClick != null ){
							pr_mapOnClick( event.latLng.lat(), event.latLng.lng() )
						}
					}}
				>
					{returnJSX_Markers( pr_mapMarkers )}
					{returnJSX_Heatmap( pr_mapMarkers )}
					{returnJSX_Clusters( pr_mapMarkers )}
					{/* {returnJSX_DrawingManager()} */}
					{/* {returnJSX_Polygon()} */}
				</GoogleMap>
			)
		// Not including mapCenter in useMemo dependency array since it is being setting manually on pan / zoom change which causes too many rerenders
		// Setting it manually so that the map doesn't recenter to the initial lat and lng on each marker change
		// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [ pr_mapWidth, pr_mapHeight, pr_mapZoom, pr_mapMarkers ] )

		const returnJSX_Component = (): TsType_JSX => {
			let componentJSX =
			<Box>
				{ memoizedMapJSX }
			</Box>
			return componentJSX
		}

		// Render
		return <>{ returnJSX_Component() }</>
	} )