//////////////////////////////////////////
//		  ooOOOO BOILERPLATE FILE		//
//		 oo		 _____					//
//		_I__n_n__||_|| ________			//
//	  >(_________|_7_|-|______|			//
//	   /o ()() ()() o   oo  oo			//
//////////////////////////////////////////

///////////////////////////////
// Description
///////////////////////////////

	/*
		DESCRIPTION / USAGE:
			Example documentation for tabs

		TODO:

	*/


///////////////////////////////
// Imports
///////////////////////////////

import {
	ComponentDocumentation,
	TsInterface_ComponentDocumentation
} from 'rfbp_core/components/_example_components'
import {
	TabsBasic,
	TabsVertical,
	TsInterface_TabContentArray,
	TsInterface_TabsSettingsBasic,
	TsInterface_TabsSettingsVertical
} from 'rfbp_core/components/tabs'
import {
	TsType_JSX
} from 'rfbp_core/typescript/global_types'
import {
	Box
} from '@mui/material/'

///////////////////////////////
// Typescript
///////////////////////////////

	interface TsInterface_ComponentProps {}


///////////////////////////////
// Variables
///////////////////////////////

	// Displayed Translatable Strings
	// { sort-start } - displayed text - scoped sort plugin

	// { sort-end } - displayed text

	// Html strings
	const TabsBasicHtmlExample =
	`    <TabsBasic
	tabs={ tabs }
	tabsSettings={ tabsSettings }
	/>`

	const TabsVerticalHtmlExample =
	`    <TabsVertical
	tabs={ tabs }
	tabsSettings={ tabsSettings }
	/>`


///////////////////////////////
// Functions
///////////////////////////////


///////////////////////////////
// Component
///////////////////////////////

	export const ExampleTabs = ( props: TsInterface_ComponentProps ): TsType_JSX => {

		// Props

		// Hooks - useContext, useState, useReducer, other
		// { sort-start } - hooks

		// { sort-end } - hooks

		// Hooks - useEffect

		// Other Variables
		const exampleTabs: TsInterface_TabContentArray = [
			{	tabHeader: "Tab 1",			tabContent:<Box>Content 1</Box> 		},
			{	tabHeader: "Tab 2",			tabContent:<Box>Content 2</Box> 		},
			{	tabHeader: "Tab 3",			tabContent:<Box>Content 3</Box> 		},
		]

		const exampleTabsPreview = [
			{	tabHeader: "Tab 1",			tabContent: "Content 1" 		},
			{	tabHeader: "Tab 2",			tabContent: "Content 2" 		},
			{	tabHeader: "Tab 3",			tabContent: "Content 3" 		},
		]

		const exampleTabsBasicSettings: TsInterface_TabsSettingsBasic = {

		}
		const exampleTabsVerticalSettings: TsInterface_TabsSettingsVertical = {
			nav_width: "100px"
		}

		const documentationObject: TsInterface_ComponentDocumentation = {
			group_name: "Tabs",
			group_description: "Tabs let you organize containers into subsections that can be toggled into",
			components: {
				TabsBasic: {
					component_name:
					<Box component="span">
						<span className="tw-font-bold tw-inline-block tw-align-top tw-normal-case">TabsBasic</span>
					</Box>,
					component_description: "Basic tabs that run across the top",
					component_example_jsx_string: TabsBasicHtmlExample,
					component_jsx:
					<Box>
						<TabsBasic tabs={exampleTabs} tabsSettings={exampleTabsBasicSettings}/>
					</Box>,
					component_props: {
						tabs: {
							propKey: "tabs",
							propTypeOrInterface: "TsInterface_TabContentArray",
							propDescription: "Array of tabs to render",
							propExampleValue: exampleTabsPreview
						},
					},
					component_todo: {}
				},
				TabsVertical: {
					component_name:
					<Box component="span">
						<span className="tw-font-bold tw-inline-block tw-align-top tw-normal-case">TabsVertical</span>
					</Box>,
					component_description: "Tabs that are vertical along the left side of the screen",
					component_example_jsx_string: TabsVerticalHtmlExample,
					component_jsx:
					<Box>
						<TabsVertical tabs={exampleTabs} tabsSettings={exampleTabsVerticalSettings}/>
					</Box>,
					component_props: {
						tabs: {
							propKey: "tabs",
							propTypeOrInterface: "TsInterface_TabContentArray",
							propDescription: "Array of tabs to render",
							propExampleValue: exampleTabsPreview
						},
					},
					component_todo: {
						1: "CSS for overflow?",
						3: "CSS for selected list item",
					}
				},
			}
		}

		// Functions



		// JSX Generation
		const returnJSX_Component = (): TsType_JSX => {
			let componentJSX =
			<Box>
				<ComponentDocumentation documentation={ documentationObject } />
			</Box>
			return componentJSX
		}

		// Render
		return <>{ returnJSX_Component() }</>
	}