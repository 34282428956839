///////////////////////////////
// Description
///////////////////////////////

	/*
		DESCRIPTION / USAGE:
			containers are pages / views used in the app and are made up of components and can interact with services and models

		TODO:

	*/


///////////////////////////////
// Imports
///////////////////////////////

import {
	updateUserClientKey
} from 'app/models/users'
import React, {
	useContext,
	useState
} from 'react'
import {
	Trans
} from 'react-i18next'
import {
	useNavigate
} from 'react-router-dom'
import {
	returnAppVersionString
} from 'rfbp_aux/config/app_version'
import {
	AuthenticatedContainer
} from 'rfbp_aux/containers/authenticated_container'
import {
	ApplicationPages
} from 'rfbp_aux/data/application_structure'
import {
	stringAvatar,
	stringToColor
} from 'rfbp_core/components/chat'
import {
	Json
} from 'rfbp_core/components/code_display'
import {
	Icon
} from 'rfbp_core/components/icons'
import {
	TabsBasic
} from 'rfbp_core/components/tabs'
import {
	Context_RootData_AuthenticatedUser,
	Context_RootData_ClientKey,
	Context_RootData_ClientPermissions,
	Context_RootData_ClientUser,
	Context_RootData_GlobalUser,
	Context_RootData_UserPermissions
} from 'rfbp_core/services/context'
import {
	dynamicSort,
	getProp,
	returnFormattedPhoneNumberString
} from 'rfbp_core/services/helper_functions'
import {
	logOut
} from 'rfbp_core/services/user_authentication'
import {
	TsType_Boolean,
	TsType_JSX,
	TsType_String,
	TsType_Void
} from 'rfbp_core/typescript/global_types'
import {
	Avatar,
	Box,
	Button,
	Card,
	LinearProgress,
	Stack,
	Typography
} from '@mui/material/'

///////////////////////////////
// Typescript
///////////////////////////////

	interface TsInterface_AuthorizedClient {
		[ key: TsType_String ]: TsType_String
	}

	interface TsInterface_AuthorizedClientsObject {
		[ key: TsType_String ]: TsType_Boolean
	}

	interface TsInterface_AuthorizedClientArray extends Array< TsInterface_AuthorizedClient >{}


///////////////////////////////
// Variables
///////////////////////////////

	// Authenticated Nav Data
	const pageKey: TsType_String = ApplicationPages["UserSettingsPage"]["key"]

	// Displayed Translatable Strings
	// { sort-start } - displayed text - scoped sort plugin
	const s_APP_VERSION: TsType_JSX = 			<Trans>App Version</Trans>
	const s_CLIENT_TOGGLE: TsType_JSX = 		<Trans>Client Toggle</Trans>
	const s_EMAIL: TsType_JSX = 				<Trans>Email</Trans>
	const s_LOG_OUT: TsType_JSX = 				<Trans>Log Out</Trans>
	const s_NAME: TsType_JSX = 					<Trans>Name</Trans>
	const s_PHONE: TsType_JSX = 				<Trans>Phone</Trans>
	const s_PROFILE: TsType_JSX = 				<Trans>Profile</Trans>
	const s_ROOT_DATA: TsType_JSX = 			<Trans>Root Data</Trans>
	const s_USER: TsType_JSX = 					<Trans>User</Trans>
	// { sort-end } - displayed text


///////////////////////////////
// Functions
///////////////////////////////


///////////////////////////////
// Container
///////////////////////////////

	export const Container: React.FC = (): TsType_JSX => {

		// Props

		// Hooks - useContext, useState, useReducer, other
		// { sort-start } - hooks
		const [ us_changingClientKey, us_setChangingClientKey ] = 							useState( false )
		const un_routerNaviation = 																useNavigate()
		const { uc_RootData_AuthenticatedUser } = 											useContext( Context_RootData_AuthenticatedUser )
		const { uc_RootData_ClientKey } = 													useContext( Context_RootData_ClientKey )
		const { uc_RootData_ClientPermissions } = 											useContext( Context_RootData_ClientPermissions )
		const { uc_RootData_ClientUser } = 													useContext( Context_RootData_ClientUser )
		const { uc_RootData_GlobalUser } = 													useContext( Context_RootData_GlobalUser )
		const { uc_RootData_UserPermissions } = 											useContext( Context_RootData_UserPermissions )
		// { sort-end } - hooks

		// Hooks - useEffect

		// Other Variables
		let displayUserName: TsType_String = getProp( uc_RootData_GlobalUser, "name", null )
		let displayUserEmail: TsType_String = getProp( uc_RootData_GlobalUser, "email", null )
		let displayUserPhone: TsType_String = getProp( uc_RootData_GlobalUser, "phone", null )
		let authorizedClients: TsInterface_AuthorizedClientsObject = getProp( uc_RootData_GlobalUser, "client_access", {} )
		let authorizedClientArray: TsInterface_AuthorizedClientArray = []
		let appVersionNumber: TsType_String = returnAppVersionString()

		// Functions
		const signOut = (): TsType_Void => {
			logOut().then(( res_LO ) => {
				un_routerNaviation( ApplicationPages.UnauthenticatedLoginPage.url() )
			}).catch(( rej_LO ) => {
				console.error( rej_LO )
			})
		}

		const generateAuthorizedClientList = (): TsType_Void => {
			for ( let clientKey in authorizedClients ){
				authorizedClientArray.push({ key: clientKey })
			}
			authorizedClientArray.sort( dynamicSort( "key", null ) )
		}

		const selectClientKey = ( clientKey: TsType_String ): TsType_Void => {
			us_setChangingClientKey( true )
			updateUserClientKey( clientKey, uc_RootData_GlobalUser ).finally(() => {
				us_setChangingClientKey( false )
			})
		}

		const returnJSX_ClientToggleButton = ( clientKey: TsType_String ): TsType_JSX => {
			let buttonJSX
			if ( uc_RootData_ClientKey === clientKey ){
				buttonJSX =
				<Button onClick={ () => {} } color="error" variant="contained" disabled={ us_changingClientKey }>
					<Icon icon="square-check"/>
					<Box component='div' sx={{ paddingLeft: "4px" }}>{ clientKey }</Box>
				</Button>
			} else {
				buttonJSX =
				<Button onClick={ () => { selectClientKey( clientKey )} } variant="outlined" disabled={ us_changingClientKey }>
					<Icon icon="square" type="light"/>
					<Box component='div' sx={{ paddingLeft: "4px" }}>{ clientKey }</Box>
				</Button>
			}
			return buttonJSX
		}

		// Call Functions
		generateAuthorizedClientList()

		// JSX Generation

		const returnJSX_ProgressBar = (): TsType_JSX => {
			let progressJSX
			if ( us_changingClientKey ){
				progressJSX = <LinearProgress />
			} else {
				progressJSX = <Box component='div'></Box>
			}
			return progressJSX
		}

		const returnJSX_ProfileTab = (): TsType_JSX => {
			let profileTabJSX =
			<Card variant="outlined">
				<Box className="tw-text-center">
					<Box className="tw-p-4 tw-text-left tw-inline-block">
						<Box className="tw-m-auto">
							<Avatar children={ stringAvatar( displayUserName )["children"] } sx={{ width: 56, height: 56, margin: "auto", bgcolor: stringToColor( displayUserName ) }} />
						</Box>
						<Box className="tw-my-4">
							<Typography variant="subtitle1"><strong>{ s_NAME }:</strong> { displayUserName }</Typography>
							<Typography variant="subtitle1"><strong>{ s_EMAIL }:</strong> { displayUserEmail }</Typography>
							<Typography variant="subtitle1"><strong>{ s_PHONE }:</strong> { returnFormattedPhoneNumberString( displayUserPhone ) }</Typography>
							<Typography variant="subtitle1"><strong>{ s_APP_VERSION }:</strong> { appVersionNumber }</Typography>
						</Box>
						<Button color="error" onClick={ () => { signOut() } } variant="contained">{ s_LOG_OUT }</Button>
					</Box>
				</Box>
			</Card>
			return profileTabJSX
		}

		const returnJSX_ClientToggleTab= (): TsType_JSX => {
			let clientToggleTabJSX =
			<Box>
				{ returnJSX_ProgressBar() }
				<Box component='div' className="tw-p-4">
					<Stack spacing={ 1 } direction="column">
						{authorizedClientArray.map(( client ) => (
							<span key={client.key}>
								{ returnJSX_ClientToggleButton( client.key ) }
							</span>
						))}
					</Stack>
				</Box>
			</Box>
			return clientToggleTabJSX
		}

		const returnJSX_DiagnosticsTab = (): TsType_JSX => {
			let diagnosticsTabJSX =
			<Card variant="outlined" className="tw-p-4">
				<Typography variant="subtitle1">uc_RootData_AuthenticatedUser</Typography>
				<Json alphebetized={ true } data={ uc_RootData_AuthenticatedUser }/>
				<Typography variant="subtitle1">uc_RootData_ClientKey</Typography>
				<Json alphebetized={ true } data={ uc_RootData_ClientKey as TsType_String }/>
				<Typography variant="subtitle1">uc_RootData_GlobalUser</Typography>
				<Json alphebetized={ true } data={ uc_RootData_GlobalUser }/>
				<Typography variant="subtitle1">uc_RootData_ClientUser</Typography>
				<Json alphebetized={ true } data={ uc_RootData_ClientUser }/>
				<Typography variant="subtitle1">uc_RootData_ClientPermissions</Typography>
				<Json alphebetized={ true } data={ uc_RootData_ClientPermissions }/>
				<Typography variant="subtitle1">uc_RootData_UserPermissions</Typography>
				<Json alphebetized={ true } data={ uc_RootData_UserPermissions }/>
			</Card>

			return diagnosticsTabJSX
		}

		const returnJSX_Page = (): TsType_JSX => {
			let tabsJSX =
			<TabsBasic
				tabs={[
					{ tabHeader: s_USER, 				tabContent: <Box>{ returnJSX_ProfileTab() }</Box>			},
					{ tabHeader: s_CLIENT_TOGGLE,		tabContent: <Box>{ returnJSX_ClientToggleTab() }</Box>		},
					{ tabHeader: s_ROOT_DATA,			tabContent: <Box>{ returnJSX_DiagnosticsTab() }</Box>		},
				]}
				tabsSettings={ {} }
			/>
			let pageJSX =
			<AuthenticatedContainer pageHeader={ s_PROFILE } pageKey={ pageKey } content={
				<Box>
					{ tabsJSX }
				</Box>
			}/>
			return pageJSX
		}

		// Render
		return <>{ returnJSX_Page() }</>

	}