//////////////////////////////////////////
//		  ooOOOO BOILERPLATE FILE		//
//		 oo		 _____					//
//		_I__n_n__||_|| ________			//
//	  >(_________|_7_|-|______|			//
//	   /o ()() ()() o   oo  oo			//
//////////////////////////////////////////

///////////////////////////////
// Description
///////////////////////////////

	/*
		DESCRIPTION / USAGE:
			The component documentation component renders all the info needed to quickly build a boilerplate component with working examples, todo lists, etc

		TODO:

	*/


///////////////////////////////
// Imports
///////////////////////////////

import {
	Trans
} from 'react-i18next'
import {
	Html,
	Json
} from 'rfbp_core/components/code_display'
import {
	Icon
} from 'rfbp_core/components/icons'
import {
	TabsBasic,
	TsInterface_TabContent
} from 'rfbp_core/components/tabs'
import {
	getProp,
	objectToArray
} from 'rfbp_core/services/helper_functions'
import {
	TsInterface_UnspecifiedObject,
	TsType_JSX,
	TsType_Number,
	TsType_String
} from 'rfbp_core/typescript/global_types'
import {
	Box,
	Card,
	Divider,
	Typography
} from '@mui/material/'

///////////////////////////////
// Typescript
///////////////////////////////

	interface TsInterface_ComponentPropsList {
		propKey: TsType_String,
		propDescription: TsType_String,
		propTypeOrInterface: TsType_String,
		propExampleValue: TsInterface_UnspecifiedObject | TsType_String
	}

	interface TsInterface_ComponentDocumentationIndividualObject {
		component_name: TsType_JSX | TsType_String,
		component_description: TsType_JSX | TsType_String,
		component_jsx: TsType_JSX,
		component_example_jsx_string: TsType_String,
		component_props: {
			[ $propKey: TsType_String ]: TsInterface_ComponentPropsList
		},
		component_todo: {
			[ $todoKey: TsType_String ]: TsType_JSX | TsType_String
		}
	}

	export interface TsInterface_ComponentDocumentation {
		group_name: TsType_JSX | TsType_String,
		group_description: TsType_JSX | TsType_String,
		components: {
			[ $componentKey: TsType_String ]: TsInterface_ComponentDocumentationIndividualObject
		}
	}

	interface TsInterface_ComponentProps {
		documentation: TsInterface_ComponentDocumentation
	}


///////////////////////////////
// Variables
///////////////////////////////

	// Displayed Translatable Strings
	// { sort-start } - displayed text - scoped sort plugin
	const s_COMPONENT: TsType_JSX = 	<Trans>Component</Trans>
	const s_EXAMPLE: TsType_JSX = 		<Trans>Example</Trans>
	const s_PROPS: TsType_JSX = 		<Trans>Props</Trans>
	const s_TODO: TsType_JSX = 			<Trans>Todo</Trans>
	const s_USAGE: TsType_JSX = 		<Trans>Usage</Trans>
	// { sort-end } - displayed text

///////////////////////////////
// Functions
///////////////////////////////


///////////////////////////////
// Component
///////////////////////////////

	export const ComponentDocumentation = ( props: TsInterface_ComponentProps ): TsType_JSX => {

		// Props
		let pr_documentation: TsInterface_ComponentDocumentation = 					getProp( props, "documentation", { components: {} } )

		// Hooks - useContext, useState, useReducer, other
		// { sort-start } - hooks

		// { sort-end } - hooks

		// Hooks - useEffect

		// Other Variables

		// Functions
		const returnComponentSubTabs = ( component: TsInterface_ComponentDocumentationIndividualObject ): TsInterface_TabContent[] => {
			let componentDataTabsArray: TsInterface_TabContent[] = [
				{
					tabHeader:
					<Box>
						<Icon icon="memo" className="tw-inline-block tw-mr-2"/>
						<Typography className="tw-font-bold tw-inline-block tw-align-top tw-normal-case">{ s_COMPONENT }</Typography>
					</Box>,
					tabContent:
					<Box className="tw-m-4">
						<Typography variant="body1">{ component.component_description }</Typography>
					</Box>
				},
				{
					tabHeader:
					<Box>
						<Icon icon="code" className="tw-inline-block tw-mr-2"/>
						<Typography className="tw-font-bold tw-inline-block tw-align-top tw-normal-case">{ s_PROPS }</Typography>
					</Box>,
					tabContent:
					<Box className="tw-m-4">
						<Box>
							<Typography variant="h6" className="tw-font-bold">{ s_USAGE }</Typography>
							<Box className="tw-ml-2">
								<Html data={ component.component_example_jsx_string }/>
							</Box>
						</Box>
						<Box className="tw-mt-2">
							<Typography variant="h6" className="tw-font-bold">{ s_PROPS }</Typography>
							{objectToArray( component.component_props ).map(( prop: TsInterface_ComponentPropsList, index: TsType_Number ) => (
								<Box key={index}>
									<Box>
										<Typography variant="subtitle1" className="tw-font-bold tw-inline-block tw-text-info_main tw-mr-1">{ prop.propKey }:</Typography>
										<Typography variant="subtitle1" className="tw-inline-block tw-opacity-50">{ prop.propTypeOrInterface }</Typography>
									</Box>
									<Box>
										<Icon icon="circle-arrow-right" className="tw-inline-block tw-ml-2 tw-mr-1 tw-text-info_main tw-mt-1" size="xl"/>
										<Typography variant="subtitle1" className="tw-inline-block tw-align-top">{ prop.propDescription }</Typography>
									</Box>
									<Box className="tw-mb-4 tw-ml-2">
										<Json alphebetized={ false } data={ prop.propExampleValue } include_functions={ true }/>
									</Box>
								</Box>
							))}
						</Box>
					</Box>
				},
				{
					tabHeader:
					<Box>
						<Icon icon="bookmark" className="tw-inline-block tw-mr-2"/>
						<Typography className="tw-font-bold tw-inline-block tw-align-top tw-normal-case">{ s_EXAMPLE }</Typography>
					</Box>,
					tabContent:
					<Box className="tw-m-4">
						{ component.component_jsx }
					</Box>
				},
				{
					tabHeader:
					<Box>
						<Icon icon="clipboard-check" className="tw-inline-block tw-mr-2"/>
						<Typography className="tw-font-bold tw-inline-block tw-align-top tw-normal-case">{ s_TODO }</Typography>
					</Box>,
					tabContent:
					<Box className="tw-m-4">
						{objectToArray( component.component_todo ).map(( todoItem: TsType_String, index: TsType_Number ) => (
							<Box key={ index } className="tw-align-top">
								<Icon icon="square" type="light" size='sm' className="tw-mr-3 tw-opacity-50 tw-mt-1"/>
								<Typography variant="subtitle1" className="tw-inline-block tw-align-top">{ todoItem }</Typography>
							</Box>
						))}
					</Box>
				},
			]
			return componentDataTabsArray
		}

		const returnComponentTabs = (): TsInterface_TabContent[] => {
			let componentTabsArray: TsInterface_TabContent[] = []
			for ( let componentKey in pr_documentation["components"] ){
				let loopComponent = pr_documentation["components"][ componentKey ]
				let loopComponentObject = {
					tabHeader:
					<Box>
						<Typography className="tw-font-bold tw-inline-block tw-align-top tw-normal-case">{ loopComponent.component_name }</Typography>
					</Box>,
					tabContent:
					<Card>
						<TabsBasic tabs={ returnComponentSubTabs( loopComponent ) } tabsSettings={ {} }/>
					</Card>
				}
				componentTabsArray.push( loopComponentObject )
			}
			return componentTabsArray
		}



		// JSX Generation
		const returnJSX_Component = (): TsType_JSX => {
			let componentJSX =
			<Box>
				<Box className="tw-mt-2">
					<Icon icon="folder-grid" className="tw-inline-block tw-mr-2"/>
					<Typography variant="h5" className="tw-font-bold tw-inline-block tw-align-top">{ pr_documentation.group_name }</Typography>
				</Box>
				<Box>
					<Typography variant="body1">{ pr_documentation.group_description }</Typography>
					<Divider className='tw-mt-4 tw-mb-1'/>
					<TabsBasic tabs={ returnComponentTabs() } tabsSettings={ {} }/>
				</Box>
			</Box>
			return componentJSX
		}

		// Render
		return <>{ returnJSX_Component() }</>
	}