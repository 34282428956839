//======================================//
//		  ooOOOO USED BY BOILERPLATE    //
//		 oo		 _____					//
//		_I__n_n__||_|| ________			//
//	  >(_________|_7_|-|______|			//
//	   /o ()() ()() o   oo  oo			//
//======================================//

///////////////////////////////
// Description
///////////////////////////////

	// See config/standard_database_endpoints for documentation

///////////////////////////////
// Imports
///////////////////////////////

import {
	collection,
	CollectionReference,
	doc,
	DocumentData,
	DocumentReference,
	getFirestore,
	Query
} from 'firebase/firestore'
import {
	DatabaseRef_ClientPermissions_Document,
	DatabaseRef_GlobalUser_Document,
	DatabaseRef_GlobalUsers_Collection,
	DatabaseRef_GlobalUserSuper_Collection,
	DatabaseRef_Item_Document,
	DatabaseRef_Items_Collection,
	DatabaseRef_ItemsOrdered_Query,
	DatabaseRef_MessageThread_Document,
	DatabaseRef_RootClient_Collection,
	DatabaseRef_RootClient_Document
} from 'rfbp_aux/services/database_endpoints/standard_database_endpoints'
import {
	generateDatabaseQuery,
	TsInterface_OrderByArray,
	TsInterface_QueryCursorsObject,
	TsInterface_QueryOperatorsArray
} from 'rfbp_core/services/database_management'
import {
	TsType_Boolean,
	TsType_Date,
	TsType_Null,
	TsType_Number,
	TsType_String,
	TsType_Unknown
} from 'rfbp_core/typescript/global_types'

///////////////////////////////
// Typescript
///////////////////////////////


///////////////////////////////
// Variables
///////////////////////////////

	// Displayed Translatable Strings


///////////////////////////////
// Functions
///////////////////////////////


///////////////////////////////
// Exports
///////////////////////////////

	// Standard Endpoints
	export {
		DatabaseRef_ClientPermissions_Document,
		DatabaseRef_GlobalUsers_Collection,
		DatabaseRef_GlobalUser_Document,
		DatabaseRef_GlobalUserSuper_Collection,
		DatabaseRef_RootClient_Collection,
		DatabaseRef_RootClient_Document,
		DatabaseRef_Items_Collection,
		DatabaseRef_Item_Document,
		DatabaseRef_ItemsOrdered_Query,
		DatabaseRef_MessageThread_Document,
	}

	///////////////////////////////
	// Client - Example Items
	///////////////////////////////

	export const DatabaseRef_Games_Collection =
		(): CollectionReference< DocumentData > => {
		return collection( getFirestore(), "games" ) }

	export const DatabaseRef_Game_Document =
		( gameKey: TsType_String ): DocumentReference< DocumentData > => {
		return doc( getFirestore(), "games", gameKey ) }
