//======================================//
//		  ooOOOO BOILERPLATE TEMPLATE   //
//		 oo		 _____					//
//		_I__n_n__||_|| ________			//
//	  >(_________|_7_|-|______|			//
//	   /o ()() ()() o   oo  oo			//
//======================================//

///////////////////////////////
// Description
///////////////////////////////

	/*
		DESCRIPTION / USAGE:
			Main home page for authenticated users

		TODO:
			[ ] Auto navigate to proper page based on user role

		// TEST

	*/


///////////////////////////////
// Imports
///////////////////////////////

import React, {
	useContext,
	useEffect,
	useState
} from 'react'
import {
	Trans
} from 'react-i18next'
import {
	useNavigate
} from 'react-router-dom'
import {
	themeVariables
} from 'rfbp_aux/config/app_theme'
import {
	AuthenticatedContainer
} from 'rfbp_aux/containers/authenticated_container'
import {
	ApplicationPages,
	generateApplicationNavigationObject,
	TsInterface_NavigationObject
} from 'rfbp_aux/data/application_structure'
import {
	Icon
} from 'rfbp_core/components/icons'
import {
	Context_RootData_ClientPermissions,
	Context_RootData_ClientUser,
	Context_RootData_UserPermissions
} from 'rfbp_core/services/context'
import {
	cloneObjectWithoutReference,
	dynamicSort,
	objectToArray
} from 'rfbp_core/services/helper_functions'
import {
	TsInterface_UnspecifiedObject,
	TsType_JSX,
	TsType_String
} from 'rfbp_core/typescript/global_types'
import {
	Box
} from '@mui/material/'

///////////////////////////////
// Typescript
///////////////////////////////


///////////////////////////////
// Variables
///////////////////////////////

	// Authenticated Nav Data
	const pageKey: TsType_String = ApplicationPages["HomePage"]["key"]

	// Displayed Translatable Strings
	// { sort-start } - displayed text - scoped sort plugin
	const s_HOME: TsType_JSX = 				<Trans>Home</Trans>
	// { sort-end } - displayed text


///////////////////////////////
// Functions
///////////////////////////////


///////////////////////////////
// Container
///////////////////////////////

	export const Container: React.FC = (): TsType_JSX => {

		// Props
		// const params = useParams()

		// Hooks - useContext, useState, useReducer, other
		// { sort-start } - hooks
		const [ us_navPages, us_setNavPages ] = 							useState( {} )
		const { uc_RootData_ClientPermissions } = 							useContext( Context_RootData_ClientPermissions )
		const { uc_RootData_ClientUser } = 									useContext( Context_RootData_ClientUser )
		const { uc_RootData_UserPermissions } = 							useContext( Context_RootData_UserPermissions )
		let routerNaviation = 												useNavigate()
		// { sort-end } - hooks

		// Hooks - useEffect
		useEffect(() => {

			if(
				uc_RootData_ClientPermissions != null &&
				uc_RootData_ClientPermissions["client_type"] != null &&
				uc_RootData_ClientUser != null &&
				uc_RootData_ClientUser["user_role"] != null &&
				uc_RootData_UserPermissions != null
			){
				let initialApplicationNavigationObject = generateApplicationNavigationObject( uc_RootData_ClientPermissions["client_type"], uc_RootData_ClientUser["user_role"] )
				let permittedApplicationNavigationObject: TsInterface_NavigationObject = {}
				for ( let loopSectionKey in initialApplicationNavigationObject ){
					let loopSection = initialApplicationNavigationObject[ loopSectionKey ]
					for ( let loopLinkKey in loopSection["links"] ){
						let loopLink = loopSection["links"][ loopLinkKey ]
						if ( uc_RootData_UserPermissions != null && uc_RootData_UserPermissions[ loopLinkKey ] === true && loopLink != null ){
							if ( permittedApplicationNavigationObject[ loopSectionKey ] == null ){
								permittedApplicationNavigationObject[ loopSectionKey ] = cloneObjectWithoutReference( loopSection )
								permittedApplicationNavigationObject[ loopSectionKey ]["links"] = {}
							}
							permittedApplicationNavigationObject[ loopSectionKey ]["links"][ loopLinkKey ] = loopLink
						}
					}
				}
				let sideBarNavObject = permittedApplicationNavigationObject
				let flatNavLinks: TsInterface_UnspecifiedObject[] = []
				for( let loopNavSectionKey in sideBarNavObject ){
					let loopNavSection = sideBarNavObject[ loopNavSectionKey ]
					if(loopNavSection != null && loopNavSection["links"] != null){
						for( let pageKey in loopNavSection["links"] ){
							let loopPage = loopNavSection["links"][ pageKey ]
							flatNavLinks.push( loopPage )
						}
					}
				}
				us_setNavPages(flatNavLinks)
			}

		}, [ uc_RootData_UserPermissions, uc_RootData_ClientUser, uc_RootData_ClientPermissions ])

		// Other Variables

		// Functions
		// const potentiallyRedirect = (): TsType_Void => {
			// if(
			// 	RootData_ClientPermissions != null &&
			// 	RootData_ClientPermissions["client_type"] != null &&
			// 	RootData_ClientUser != null &&
			// 	RootData_ClientUser["user_role"] != null &&
			// 	ClientTypes != null &&
			// 	ClientTypes[ RootData_ClientPermissions["client_type"] ] != null &&
			// 	ClientTypes[ RootData_ClientPermissions["client_type"] ]["user_roles"] != null &&
			// 	ClientTypes[ RootData_ClientPermissions["client_type"] ]["user_roles"][ RootData_ClientUser["user_role"] ] != null &&
			// 	ClientTypes[ RootData_ClientPermissions["client_type"] ]["user_roles"][ RootData_ClientUser["user_role"] ]["home_redirect_page"] != null &&
			// 	ClientTypes[ RootData_ClientPermissions["client_type"] ]["user_roles"][ RootData_ClientUser["user_role"] ]["home_redirect_page"]["key"] != null &&
			// 	ClientTypes[ RootData_ClientPermissions["client_type"] ]["user_roles"][ RootData_ClientUser["user_role"] ]["home_redirect_page"]["url"] != null
			// ){
			// 	let redirectPageKey = ClientTypes[ RootData_ClientPermissions["client_type"] ]["user_roles"][ RootData_ClientUser["user_role"] ]["home_redirect_page"]["key"]
			// 	setTimeout( () => {
			// 		if ( RootData_UserPermissions != null && RootData_UserPermissions[ redirectPageKey ] === true ){
			// 			// @ts-expect-error
			// 			routerNaviation( ClientTypes[ RootData_ClientPermissions["client_type"] ]["user_roles"][ RootData_ClientUser["user_role"] ]["home_redirect_page"]["url"]() )
			// 		}
			// 	}, 1)
			// }
		// }

		// Call Functions
		// potentiallyRedirect()

		// JSX Generation
		const returnJSX_Page = (): TsType_JSX => {
			let pageJSX: TsType_JSX =
			<AuthenticatedContainer pageHeader={s_HOME} pageKey={pageKey} content={
				<Box className="tw-text-center tw-m-auto" sx={{ maxWidth: "700px" }}>
					{ objectToArray( us_navPages ).sort( dynamicSort( "key", null )).map(( page, pageIndex ) => (
						<Box
							onClick={ () => {
								routerNaviation( page.url )
							}}
							key={ pageIndex }
							className="tw-inline-block tw-text-center tw-m-2 tw-p-2 tw-border-2 tw-rounded-xl tw-border-solid tw-cursor-pointer"
							sx={{
								height: "100px",
								width: "280px",
								borderColor: themeVariables.white,
								"&:hover": {
									backgroundColor: themeVariables.background_paper,
									borderColor: themeVariables.info_main,
									color: themeVariables.info_main
								}
							}}
						>
							<Box className="tw-mt-1">
								<Icon size="3x" icon={ page.icon } />
							</Box>
							<Box className="tw-mt-2">{ page.name }</Box>
						</Box>
					)) }
				</Box>
			}/>
			return pageJSX
		}

		// Render
		return <>{returnJSX_Page()}</>
	}