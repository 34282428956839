//////////////////////////////////////////
//		  ooOOOO BOILERPLATE FILE		//
//		 oo		 _____					//
//		_I__n_n__||_|| ________			//
//	  >(_________|_7_|-|______|			//
//	   /o ()() ()() o   oo  oo			//
//////////////////////////////////////////

///////////////////////////////
// Description
///////////////////////////////

	/*
		DESCRIPTION / USAGE:
			component listing message threads

		TODO:
			dark mode?

	*/


///////////////////////////////
// Imports
///////////////////////////////

import {
	Trans
} from 'react-i18next'
import {
	themeVariables
} from 'rfbp_aux/config/app_theme' // OUTSIDE BOILERPLATE
import {
	generateLastMessageText,
	generateMessageThreadName,
	returnJSX_Avatar,
	stringAvatar,
	TsInterface_ChatThread,
	TsInterface_ChatThreadOnClick,
	TsInterface_ChatThreads,
	TsInterface_ChatThreadSettings
} from 'rfbp_core/components/chat'
import {
	dynamicSort,
	getProp,
	objectToArray
} from 'rfbp_core/services/helper_functions'
import {
	TsType_JSX,
	TsType_Number,
	TsType_String
} from 'rfbp_core/typescript/global_types'
import {
	Avatar,
	Badge,
	Box,
	Typography
} from '@mui/material/'

///////////////////////////////
// Typescript
///////////////////////////////

	interface TsInterface_ComponentProps {
		chatThreads: TsInterface_ChatThreads
		threadSettings: TsInterface_ChatThreadSettings
		threadOnClick: TsInterface_ChatThreadOnClick
	}


///////////////////////////////
// Variables
///////////////////////////////

	// Displayed Translatable Strings
	// { sort-start } - displayed text - scoped sort plugin
	const s_NEW_MESSAGE = <Trans>New Message</Trans>
	// { sort-end } - displayed text


///////////////////////////////
// Functions
///////////////////////////////


///////////////////////////////
// Component
///////////////////////////////

	export const ChatThreadsDisplay = ( props: TsInterface_ComponentProps ): TsType_JSX =>  {

		// Props
		let pr_chatThreads: TsInterface_ComponentProps["chatThreads"] = 				getProp(props, "chatThreads", {})
		let pr_threadOnClick: TsInterface_ComponentProps["threadOnClick"] = 			getProp(props, "threadOnClick", ( threadKey: TsType_String ) => {})
		let pr_threadSettings: TsInterface_ComponentProps["threadSettings"] = 			getProp(props, "threadSettings", {})

		// Hooks - useContext, useState, useReducer, other
		// { sort-start } - hooks

		// { sort-end } - hooks

		// Hooks - useEffect

		// Other Variables

		// Functions



		// JSX Generation
		const returnJSX_NewThread = (): TsType_JSX => {
			// Handle Color (selected vs not)
			let backgroundColor = themeVariables.background_paper
			if ( pr_threadSettings.selected_thread_key === "NEW" ){
				backgroundColor = themeVariables.info_light
			}
			// Generate JSX
			let newThreadJSX = <></>
			if ( pr_threadSettings != null && pr_threadSettings.include_create_thread === true ){
				newThreadJSX =
				<Box component='div' sx={{ backgroundColor: backgroundColor, height: "73px", borderBottom: "1px solid " + themeVariables.gray_300 + " !important" }} className="tw-rounded-none tw-cursor-pointer tw-p-2 tw-text-center" onClick={ () => { pr_threadOnClick("NEW") } }>
					<Box className="tw-mt-3">
						<Avatar { ...stringAvatar( "+" ) } sx={{ display: "inline-flex", bgcolor: themeVariables.gray_500, height: "28px", width: "28px" }} />
						<Typography sx={{ display: "inline-flex", paddingLeft: "6px" }} variant="h6"><strong>{ s_NEW_MESSAGE }</strong></Typography>
					</Box>
				</Box>
			}
			return newThreadJSX
		}

		const returnJSX_ThreadAvatar = ( chatThread: TsInterface_ChatThread, unreadCount: TsType_Number ) => {
			let threadAvatarJSX = <></>
			if(
				pr_threadSettings != null &&
				chatThread.avatar_override != null
			) {
				threadAvatarJSX =
				<Badge color="error" overlap="circular" badgeContent={unreadCount} invisible={unreadCount === 0} anchorOrigin={{ vertical: 'top', horizontal: 'left'}}>
					{ chatThread.avatar_override }
				</Badge>
			} else {
				threadAvatarJSX = returnJSX_Avatar( chatThread["associated_member_names"], "tw-text-base tw-w-7 tw-h-7", unreadCount, pr_threadSettings.associated_viewer_key )
			}
			return threadAvatarJSX
		}

		const returnJSX_MessageThread = ( chatThread: TsInterface_ChatThread ): TsType_JSX => {
			// Handle Color (selected vs not)
			let backgroundColor = themeVariables.background_paper
			if ( chatThread.key === pr_threadSettings.selected_thread_key ){
				backgroundColor = themeVariables.info_light
			}
			// Get Unread Count
			let unreadCount: TsType_Number = 0
			if (
				pr_threadSettings != null &&
				pr_threadSettings["associated_viewer_key"] != null &&
				chatThread != null &&
				chatThread["associated_member_unread_count"] != null &&
				chatThread["associated_member_unread_count"][ pr_threadSettings["associated_viewer_key"] ] != null &&
				!isNaN( chatThread["associated_member_unread_count"][ pr_threadSettings["associated_viewer_key"] ]  )
			){
				unreadCount = chatThread["associated_member_unread_count"][ pr_threadSettings["associated_viewer_key"] ]
			}
			// Generate JSX
			let threadJSX =
			<Box component='div' sx={{ backgroundColor: backgroundColor, borderBottom: "1px solid " + themeVariables.gray_300 }} className="tw-rounded-none tw-cursor-pointer tw-p-2" onClick={ () => { pr_threadOnClick( chatThread.key ) } }>
				<Box component='div' className="tw-block">
					<Box component='div'>
						<Box component='div' className="tw-mr-2 tw-inline-block">
							<Box component='div' className="tw-flex">
								{ returnJSX_ThreadAvatar( chatThread, unreadCount ) }
							</Box>
						</Box>
						<Box component='div' sx={{ width: "calc(100% - 56px)" }} className="tw-p-l-2 tw-p-t-0.5 tw-align-top tw-inline-block tw-text-ellipsis tw-overflow-hidden tw-whitespace-nowrap">
							<Typography sx={{ display: "inline-block" }} variant="body2"><strong>{ generateMessageThreadName( chatThread["thread_name_override_desktop"], chatThread["associated_member_names"], pr_threadSettings.associated_viewer_key ) }</strong></Typography>
						</Box>
					</Box>
					<Typography variant="body2" className="tw-inline-block">
						{ generateLastMessageText(chatThread) }
					</Typography>
				</Box>
			</Box>
			return threadJSX
		}

		const returnJSX_Component = (): TsType_JSX => {
			let componentJSX =
			<Box>
				<Box component='div'>
					{ returnJSX_NewThread() }
				</Box>
				{ objectToArray( pr_chatThreads ).sort( dynamicSort("last_message_timestamp", "desc") ).map(( chatThread, index ) => (
					<Box component='div' key={ index }>
						{returnJSX_MessageThread( chatThread )}
					</Box>
				))}
			</Box>
			return componentJSX
		}

		// Render
		return <>{ returnJSX_Component() }</>
	}