///////////////////////////////
// Description
///////////////////////////////

	/*
		DESCRIPTION / USAGE:
			containers are pages / views used in the app and are made up of components and can interact with services and models

		TODO:

			On CLick Map - set pin on map
				Save Guess Coordinates

			Confirm Button
				Fixed Box at bottom left? - disabled if no coordinates
				Load Actual Pin
				Show Distance and Score (banner at the top???)

			Reset StreetView Button (probaby not needed)

	*/


///////////////////////////////
// Imports
///////////////////////////////

import React, {
	useEffect,
	useReducer,
	useRef,
	useState
} from 'react'
import {
	themeVariables
} from 'rfbp_aux/config/app_theme'
import {
	Icon
} from 'rfbp_core/components/icons/icon'
import {
	TsInterface_MapMarkers,
	TsType_MapOnClick
} from 'rfbp_core/components/map/map_types'
import {
	objectToArray
} from 'rfbp_core/services/helper_functions/index'
import {
	TsInterface_UnspecifiedObject,
	TsType_Any,
	TsType_JSX,
	TsType_Null,
	TsType_Number,
	TsType_String,
	TsType_Void
} from 'rfbp_core/typescript/global_types'
import {
	Box,
	Button,
	CircularProgress
} from '@mui/material/'
import {
	GoogleMap,
	Marker
} from '@react-google-maps/api'
import RandomStreetView from './random_coordinates/index.js'
import GoogleStreetview from './streetview/index.jsx'

///////////////////////////////
// Typescript
///////////////////////////////


///////////////////////////////
// Variables
///////////////////////////////

	// Authenticated Nav Data
	// const pageKey: TsType_String = ApplicationPages["GeoguesserPage"]["key"]

	// Displayed Translatable Strings
	// { sort-start } - displayed text - scoped sort plugin
	// const s_GEOGUESSER: TsType_JSX = 		<Trans>Geoguesser</Trans>
	// { sort-end } - displayed text


///////////////////////////////
// Functions
///////////////////////////////


const returnMapIcons = ( iconKey: TsType_String ): TsInterface_UnspecifiedObject => {
	let icon: TsInterface_UnspecifiedObject = {}
	switch( iconKey ){
		case "pin":
			icon = {
				path: "M550,325L550,50L600,50L600,0L200,0L200,50L250,50L250,325C250,325 150,400 150,500C150,525 245,535 350,535L350,645C350,680 360,715 375,750L400,800L425,750C440,720 450,685 450,645L450,535C555,535 650,520 650,500C650,400 550,325 550,325ZM350,330C350,330 325,345 270,400C220,450 195,495 195,495C195,495 200,445 235,400C280,345 300,330 300,330L300,50L350,50L350,330Z",
				scale: 0.05,
				anchor: new google.maps.Point(400, 800),
			}
			break
		default:
			icon = {
				path: "M399.999,0C240.037,0 110.867,129.17 110.867,289.132C110.867,344.905 128.684,398.161 153.472,441.926L341.902,768.044C353.715,789.347 377.535,799.998 399.999,799.998C422.465,799.998 445.121,789.347 458.096,768.044L646.526,442.12C671.508,398.351 689.131,346.063 689.131,289.321C689.131,129.364 559.961,0 399.999,0ZM399.999,374.535C341.902,374.535 294.455,327.089 294.455,268.991C294.455,210.894 341.902,163.448 399.999,163.448C458.096,163.448 505.543,210.894 505.543,268.991C505.543,327.282 458.096,374.535 399.999,374.535Z",
				scale: 0.05,
				anchor: new google.maps.Point(400, 800),
			}
	}
	return icon
}

// @ts-expect-error
function getZoomByBoundsAndDimensions(bounds, mapWidth, mapHeight, zoomLimits) {
	const WORLD_DIM = { height: 256, width: 256 };
	const ZOOM_MAX = zoomLimits.maxZoom;
	const ZOOM_MIN = zoomLimits.minZoom;
	const ne = bounds.getNorthEast();
	const sw = bounds.getSouthWest();
	const latFraction = (Math.abs(ne.lat() - sw.lat())) / 180.0;
	const lngFraction = (Math.abs(ne.lng() - sw.lng())) / 360.0;
	const latZoom = Math.floor(Math.log(mapHeight / WORLD_DIM.height / latFraction) / Math.LN2);
	const lngZoom = Math.floor(Math.log(mapWidth / WORLD_DIM.width / lngFraction) / Math.LN2);
	const zoom = Math.min(latZoom, lngZoom, ZOOM_MAX);
	return Math.max(zoom, ZOOM_MIN);
}

function getWindowDimensions() {
	return {
		width: window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth,
		height: window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight
	}
}

///////////////////////////////
// Container
///////////////////////////////

	export const Container: React.FC = (): TsType_JSX => {

		// Props

		// Hooks - useContext, useState, useReducer, other
		// { sort-start } - hooks
		const [ us_actualCoordinates, us_setActualCoordinates ] = 		useState<TsInterface_UnspecifiedObject>({})
		const [ us_distance, us_setDistance ] = 						useState<TsType_Number | TsType_Null>(null)
		const [ us_gameStatus, us_setGameStatus ] = 					useState<TsType_String>("active") // active / finished
		const [ us_guessCoordinates, us_setGuessCoordinates ] = 		useState<TsInterface_UnspecifiedObject>({ lat: 0, lng: 0 })
		const [ us_mapCenter, us_setMapCenter ] = 						useState({ lat: 0, lng: 0 })
		const [ us_mapMarkers, us_setMapMarkers ] = 					useState<TsInterface_MapMarkers>({ asdf: { position: { lat:0, lng: 0 }, label: "guess" } })
		const [ us_mapZoom, us_setMapZoom ] = 							useState(2)
		const [ us_score, us_setScore ] = 								useState<TsType_Number | TsType_Null>(null)
		const ur_forceRerender = 										useReducer(() => ({}), {})[1] as () => TsType_Void
		const ur_mapRef = 												useRef( null )
		// { sort-end } - hooks

		// Hooks - useEffect
		useEffect(() => {
			// Generate Random Street View Coordinate
			us_setActualCoordinates({ lat: 0, lng: 0 })
			setTimeout( () => {
				RandomStreetView.getRandomLocation().then( res => {
					// @ts-expect-error
					if( res != null && res[0] != null && res[1] != null )
						// @ts-expect-error
						us_setActualCoordinates( { lat: res[0], lng: res[1]  })
						ur_forceRerender()
				}).catch( err => {
					console.error( err)
				})
			}, 1000)
		}, [ur_forceRerender])

		useEffect(() => {
			let mapMarkers: TsInterface_MapMarkers = {
				guess: {
					position: { lat:0, lng: 0 },
					label: "",
					icon: {
						path: returnMapIcons( "pin2" )["path"],
						fillColor: themeVariables.error_main,
						// fillOpacity: 1,
						fillOpacity: 0,
						// strokeWeight: 0.5,
						strokeWeight: 0,
						strokeColor: "white",
						rotation: 0,
						scale: returnMapIcons( "pin2" )["scale"],
						anchor: returnMapIcons( "pin2" )["anchor"],
					}
				},
				actual: {
					position: { lat:0, lng: 0 },
					label: "",
					icon: {
						path: returnMapIcons( "pin2" )["path"],
						fillColor: themeVariables.info_main,
						// fillOpacity: 1,
						fillOpacity: 0,
						// strokeWeight: 0.5,
						strokeWeight: 0,
						strokeColor: "white",
						rotation: 0,
						scale: returnMapIcons( "pin2" )["scale"],
						anchor: returnMapIcons( "pin2" )["anchor"],
					}
				}
			}
			if(
				us_guessCoordinates != null &&
				us_guessCoordinates.lat != null &&
				us_guessCoordinates.lng != null &&
				us_guessCoordinates.lat !== 0 &&
				us_guessCoordinates.lng !== 0
			){
				// @ts-expect-error
				mapMarkers["guess"]["icon"]["fillOpacity"] = 1
				// @ts-expect-error
				mapMarkers["guess"]["icon"]["strokeWeight"] = 0.5
				mapMarkers["guess"]["position"] = {
					lat: us_guessCoordinates.lat,
					lng: us_guessCoordinates.lng,
				}
			}
			if( us_gameStatus === "active" ){
				setTimeout( () => {
					us_setMapMarkers( mapMarkers )
					ur_forceRerender()
				}, 100)
			} else if( us_gameStatus === "finished" ){
				if(
					us_actualCoordinates != null &&
					us_actualCoordinates.lat != null &&
					us_actualCoordinates.lng != null &&
					us_actualCoordinates.lat !== 0 &&
					us_actualCoordinates.lng !== 0
				){
					// @ts-expect-error
					mapMarkers["actual"]["icon"]["fillOpacity"] = 1
					// @ts-expect-error
					mapMarkers["actual"]["icon"]["strokeWeight"] = 0.5
					mapMarkers["actual"]["position"] = {
						lat: us_actualCoordinates.lat,
						lng: us_actualCoordinates.lng,
					}
				}
				setTimeout( () => {
					us_setMapMarkers( mapMarkers )
					ur_forceRerender()
				}, 100)
				ur_forceRerender()
				// Resize
				setTimeout( () => {
					let bounds = new window.google.maps.LatLngBounds()
					for ( let markersIndex in mapMarkers ) {
						let marker = mapMarkers[ markersIndex ]
						if( marker != null && marker.position != null && marker.position.lat != null && marker.position.lng != null ){
							let latlng = new window.google.maps.LatLng( marker.position.lat, marker.position.lng )
							bounds.extend( latlng )
						}
					}
					if(
						bounds != null &&
						bounds.getCenter() != null &&
						bounds.getCenter().lat() != null &&
						bounds.getCenter().lng() != null
					){
						us_setMapCenter({ lat: bounds.getCenter().lat(), lng: bounds.getCenter().lng() })
						const zoomLimits = {
							maxZoom: 21, // Adjust the maximum zoom level as needed
							minZoom: 0,  // Adjust the minimum zoom level as needed
						}
						let mapWidth = getWindowDimensions().width
						let mapHeight = getWindowDimensions().height * 0.4
						let zoom = getZoomByBoundsAndDimensions(bounds, mapWidth, mapHeight, zoomLimits);
						us_setMapZoom( zoom )
						// @ts-expect-error
						if ( ur_mapRef != null && ur_mapRef.current != null && ur_mapRef.current.fitBounds != null){
							// @ts-expect-error
							ur_mapRef.current.fitBounds( bounds )
						}

						// @ts-expect-error
						if ( ur_mapRef != null && ur_mapRef != null && ur_mapRef.fitBounds != null){
							// @ts-expect-error
							ur_mapRef.fitBounds( bounds )
						}
					}
					ur_forceRerender()
				}, 0)
			}
		}, [ur_forceRerender, us_actualCoordinates, us_gameStatus, us_guessCoordinates])

		// Other Variables

		// Functions

		// Call Functions
		const confirmGuess = () => {
			us_setGameStatus("finished")
		}

		const measureDistanceBetweenTwoPoints = (
			lat1: TsType_Number,
			lon1: TsType_Number,
			lat2: TsType_Number,
			lon2: TsType_Number
		) => { // https://en.wikipedia.org/wiki/Haversine_formula
			let R = 6378.137 // Radius of earth in KM
			let dLat = lat2 * Math.PI / 180 - lat1 * Math.PI / 180
			let dLon = lon2 * Math.PI / 180 - lon1 * Math.PI / 180
			let a = Math.sin(dLat/2) * Math.sin(dLat/2) +
			Math.cos(lat1 * Math.PI / 180) * Math.cos(lat2 * Math.PI / 180) *
			Math.sin(dLon/2) * Math.sin(dLon/2)
			let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a))
			let d = R * c
			let meters = d * 1000 // meters
			let miles = meters * 0.000621371192
			// return meters
			return miles
		}

		const calculateGeoguesserScore = ( distance: TsType_Number ): TsType_Number => {
			// Scores
			// 64 = 4669
			// 194 = 4057
			// 235 = 3881
			// 278 = 3706
			// 1740 = 765
			// let mapSize = 1500 // World Map
			let mapSize = 935 // World Map
			let score = 5000 * Math.E ** ( ( -1 * distance ) / mapSize )
			return Math.round(score)
		}

		const mapOnClick: TsType_MapOnClick = ( lat: TsType_Number, lng: TsType_Number ) => {
			if( us_gameStatus === "active" ){
				us_setGuessCoordinates({ lat: lat, lng: lng })
				let distance = measureDistanceBetweenTwoPoints(
					us_actualCoordinates.lat,
					us_actualCoordinates.lng,
					lat,
					lng
				)

				let score = calculateGeoguesserScore( distance )
				us_setDistance( distance )
				us_setScore( score )
			}
		}

		// JSX Generation
		const returnJSX_Score = (): TsType_JSX => {
			let scoreJSX = <></>
			if( us_gameStatus === "finished" ){
				let distance: TsType_Null | TsType_Number | TsType_String = us_distance
				if(
					distance != null &&
					distance < 1
				){
					distance = Math.round(( distance * 5280 / 3 )) + " yards"
				} else if(
					distance != null &&
					distance < 25
				){
					distance = distance.toFixed(1) + " miles"
				} else if(
					distance != null
				) {
					distance = distance.toFixed(0) + " miles"
				}
				scoreJSX =
				<Box className="tw-text-left tw-w-full tw-p-2" sx={{ background: "rgba(0,0,0,.7)", color: "#fff" }} >
					<Box>
						Distance: { distance }
					</Box>
					<Box>
						Score: { us_score }
					</Box>
				</Box>
			}
			return scoreJSX
		}

		const retunJSX_Button = (): TsType_JSX => {
			let buttonJSX = <></>
			if( us_gameStatus === "finished" ){
				buttonJSX =
				<Button
					color="primary"
					variant="contained"
					onClick={ () => {
						window.location.reload()
					} }
				>
					<Icon icon="rotate-right" className="tw-mr-2" />
					Play Again
				</Button>
			} else if( us_gameStatus === "active" ){
				buttonJSX =
				<Button
					color="error"
					variant="contained"
					onClick={ confirmGuess }
					disabled={
						us_guessCoordinates == null ||
						(
							us_guessCoordinates.lat === 0 &&
							us_guessCoordinates.lng === 0
						)
					}
				>
					<Icon icon="lock" className="tw-mr-2" />
					Confirm Guess
				</Button>
			}
			return buttonJSX
		}

		const returnJSX_StreetView = (): TsType_JSX => {
			let streetViewJSX = <></>
			let opacity = 0
			let loaderJSX = <></>
			if(
				us_actualCoordinates != null &&
				us_actualCoordinates.lat != null &&
				us_actualCoordinates.lng != null &&
				us_actualCoordinates.lat !== 0 &&
				us_actualCoordinates.lng !== 0
			){
				opacity = 1
				loaderJSX = <></>
			} else {
				loaderJSX =
				<Box className="tw-text-center tw-mt-8">
					<CircularProgress />
				</Box>
			}
			streetViewJSX =
				<Box>
					{ loaderJSX }
					<Box sx={{ opacity: opacity, height: "60vh" }}>
						<GoogleStreetview
							apiKey={"AIzaSyB4ooZgS4RYPKQ1qrxI8MtBc-3B4a6K3zs"}
							streetViewPanoramaOptions={{
								addressControl: false,
								showRoadLabels: false,
								fullscreenControl: false,
								compassControl: false,
								zoom: 0,
								position: us_actualCoordinates
							}}
						/>
						<Box sx={{ height: "40vh", background: "#f00" }}>
							<GoogleMap
								id="map"
								ref={ ur_mapRef }
								// onLoad={ handleMapLoad }
								mapContainerStyle={{ width: "100%", height: "40vh" }}
								zoom={ us_mapZoom }
								center={ us_mapCenter }
								onCenterChanged={() => {
									// Nothing
								}}
								options={{
									styles: themeVariables.map_styles,
									fullscreenControl: false,
									mapTypeControl: false,
									streetViewControl: false
								}}
								onClick={( event ) => {
									if ( event != null && event.latLng != null ){
										mapOnClick( event.latLng.lat(), event.latLng.lng() )
									}
								}}
							>
								{objectToArray( us_mapMarkers ).map(( marker: TsType_Any, index: TsType_Number ) => (
									<Marker
										key={ index }
										position={ marker.position }
										label={ marker.label }
										icon={ marker.icon }
									/>
								))}
							</GoogleMap>
						</Box>
						<Box sx={{ position: "fixed", top: "60vh", left: "0px"}} >
							{ returnJSX_Score() }
						</Box>
						<Box sx={{ position: "fixed", bottom: "0px", left: "0px", padding: "6px" }}>
							{ retunJSX_Button() }
						</Box>
					</Box>
				</Box>
			return streetViewJSX
		}

		const returnJSX_Page = (): TsType_JSX => {
			let pageJSX =
			// <AuthenticatedContainer pageHeader={s_GEOGUESSER} pageKey={pageKey} content={
				<Box>
					{ returnJSX_StreetView() }
				</Box>
			// }/>
			return pageJSX
		}

		// Render
		return <>{returnJSX_Page()}</>

	}


