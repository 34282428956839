///////////////////////////////
// Description
///////////////////////////////

	/*
		DESCRIPTION / USAGE:
			containers are pages / views used in the app and are made up of components and can interact with services and models

		TODO:

			Required:
				List everyone in the game with their color and name
				ability to kick players from the game

				First time in the game, probably need to write more to the game start (ie play phase, initial scores, etc)
					- starting locations and missions for each player - based on map and other settings

			Optional:
				ability to get qr code for a player who closed out of the game and needs to rejoin
				game settings

	*/


///////////////////////////////
// Imports
///////////////////////////////

// @ts-expect-error
import logoSrc from 'app/pages/pirate_game/assets/logo.png'
import QRCode from 'qrcode.react'
import {
	useContext,
	useEffect,
	useReducer,
	useRef,
	useState
} from 'react'
import {
	Trans
} from 'react-i18next'
import {
	useNavigate,
	useParams
} from 'react-router-dom'
import {
	ApplicationPages
} from 'rfbp_aux/data/application_structure'
import {
	DatabaseRef_Game_Document
} from 'rfbp_aux/services/database_endpoints/games'
import {
	Json
} from 'rfbp_core/components/code_display'
import {
	Icon
} from 'rfbp_core/components/icons'
import {
	Context_UserInterface_ErrorDialog
} from 'rfbp_core/services/context'
import {
	DatabaseGetLiveDocument,
	DatabaseSetMergeDocument
} from 'rfbp_core/services/database_management'
import {
	dynamicSort,
	getProp,
	objectToArray
} from 'rfbp_core/services/helper_functions'
import {
	TsInterface_UnspecifiedObject,
	TsType_Any,
	TsType_Boolean,
	TsType_JSX,
	TsType_Number,
	TsType_String,
	TsType_Void,
	TsType_VoidFunction
} from 'rfbp_core/typescript/global_types'
import {
	Box,
	Button,
	Divider,
	Typography
} from '@mui/material/'
import Grid2 from '@mui/material/Unstable_Grid2'

///////////////////////////////
// Typescript
///////////////////////////////


///////////////////////////////
// Variables
///////////////////////////////

	// Authenticated Nav Data

	// Displayed Translatable Strings
	// { sort-start } - displayed text - scoped sort plugin
	// const s_END_OF_GAME_BONUSES: TsType_JSX = 		<Trans>End of game bonuses</Trans>
	// const s_HEARTS: TsType_JSX = 					<Trans>Hearts</Trans>
	// const s_HIDDEN_TREASURE_CLUES: TsType_JSX = 	<Trans>Hidden Treasure Clues</Trans>
	// const s_POWER_UPS: TsType_JSX = 				<Trans>Power Ups</Trans>
	const s_ALL_ABOARD: TsType_JSX = 				<Trans>All aboard</Trans>
	const s_CLICK_TO_GET_STARTED: TsType_JSX = 		<Trans>Click to get started</Trans>
	const s_CREW_LEDGER: TsType_JSX = 				<Trans>Crew Ledger</Trans>
	const s_GAME_TYPE: TsType_JSX = 				<Trans>Game Type</Trans>
	const s_MAP: TsType_JSX = 						<Trans>Map</Trans>
	const s_NEW_GAME: TsType_JSX = 					<Trans>New Game</Trans>
	const s_SETTINGS: TsType_JSX = 					<Trans>Settings</Trans>
	const s_SET_SAIL: TsType_JSX = 					<Trans>Set Sail</Trans>
	// { sort-end } - displayed text


///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Container
///////////////////////////////

	export const Container: React.FC = (): TsType_JSX => {

		// Props
		const params = useParams()
		const gameKey: TsType_String = params.id as TsType_String

		// Hooks - useContext, useState, useReducer, other
		// { sort-start } - hooks
		const [ us_logoSplashGone, us_setLogoSplashGone ] = 	useState<TsType_Boolean>(false)
		const [ us_rootGame, us_setRootGame ] = 				useState<TsInterface_UnspecifiedObject>({})
		const [ us_showLogoSplash, us_setShowLogoSplash ] = 	useState<TsType_Boolean>(true)
		const audioRef = 										useRef(null)
		const un_routerNaviation = 								useNavigate()
		const ur_forceRerender = 								useReducer(() => ({}), {})[1] as () => TsType_Void
		const { uc_setUserInterface_ErrorDialogDisplay } = 		useContext( Context_UserInterface_ErrorDialog )
		// { sort-end } - hooks

		// Hooks - useEffect
		useEffect(() => {
			let unsubscribeLiveData: TsType_VoidFunction
			const updateLiveData = ( newData: TsInterface_UnspecifiedObject ) => {
				us_setRootGame( newData )
				ur_forceRerender()
			}
			unsubscribeLiveData = DatabaseGetLiveDocument( DatabaseRef_Game_Document( gameKey ), updateLiveData )
			return () => {
				if (typeof unsubscribeLiveData === 'function'){
					unsubscribeLiveData()
				}
			}
		}, [gameKey, ur_forceRerender])

		// Other Variables

		// https://www.youtube.com/watch?v=iEcJPZ0HEkk&ab_channel=UnwindStation-NoCopyrightMusic
		// https://www.youtube.com/watch?v=iTVxFPhbAtk&ab_channel=UntoldJourney
		let audioURL = "https://firebasestorage.googleapis.com/v0/b/john-morrell.appspot.com/o/pirate_game_assets%2FUntold%20Journey%20-%20The%20Pirate%20King%20(Royalty%20Free%20Music).mp3?alt=media&token=ad839e16-1af5-46e6-b9f3-a11d66670713&_gl=1*esbgxh*_ga*MTI2MTIyMjQ5Ni4xNjczNDgyMDI3*_ga_CW55HF8NVT*MTY5ODAwNzIzOS40MjMuMS4xNjk4MDA3MzA0LjYwLjAuMA.."

		// Functions
		const createNewGame = (): TsType_Void => {

			// TODO - have a url that creates a new game and redirects to here with the game ID in the URL already

			// Generate New Game ID and save to DB - if page refreshed game will die so need to think about that...
				// Possibly need to have a useEffect that writes either success for fail to DB on page redirect

			// Hide Logo Splash after opacity change
			setTimeout( () => {
				us_setLogoSplashGone(true)
			}, 2000 )
			// Change Animation
			us_setShowLogoSplash(false)
			if (audioRef.current) {
				// @ts-expect-error
				audioRef.current.play();
			}
		}

		const startGame = (): TsType_Void => {
			let updateObject: TsInterface_UnspecifiedObject = {
				status: "active"
			}
			DatabaseSetMergeDocument( DatabaseRef_Game_Document( gameKey ), updateObject, {} ).then( ( res_DSMD ) => {
				un_routerNaviation( ApplicationPages.PirateGameDesktopGamePage.url( gameKey ) )
			}).catch( ( rej_DSMD ) => {
				uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
			})
		}

		// Call Functions

		// JSX Generation
		const returnJSX_AudioPlayer = (): TsType_JSX => {
			let playerJSX =
			<Box className="tw-hidden">
				<audio ref={audioRef} controls autoPlay loop>
					<source src={ audioURL } type="audio/mpeg" />
				</audio>
			</Box>
			return playerJSX
		}

		const returnJSX_LogoSplash = (): TsType_JSX => {
			let splashJSX = <></>
			if( us_logoSplashGone === false ){
				splashJSX =
				<Box
					sx={{
						width: "100%",
						height: "100%",
						position: "fixed",
						zIndex: 1000,
						background: "linear-gradient(to bottom, #D4418E, #0652C5)",
						opacity: us_showLogoSplash ? 1 : 0, // Use opacity to control the fade
						transition: 'opacity 2s', // Add a transition for a smooth fade-out effect
					}}
					onClick={ () => {
						createNewGame()
					}}
				>
					<Box
						sx={{
							maxWidth: "600px",
							textAlign: "center",
							margin: "auto",
							marginTop: "30vh"
						}}
					>
						<img
							src={ logoSrc }
							alt=""
							loading="lazy"
							width={"100%"}
						/>
					</Box>
					<Typography
						className="font-pirata-one"
						sx={{
							width: "100%",
							height: "100%",
							textAlign: "center",
							color: "#fff",
							fontSize: "32px"
						}}
					>
						{ s_CLICK_TO_GET_STARTED }
					</Typography>
				</Box>
			}
			return splashJSX
		}

		const returnJSX_GameLobby = (): TsType_JSX => {

			// TODO - Logo, QR code, settings, players in the game, ability to kick players, etc

			// Confirm button changes game status from new to active and redirects to game page / locks new users from joining


			let lobbyJSX =
			<Box
				sx={{
					width: "100%",
					height: "100vh",
					background: "#71482E",
					opacity: us_showLogoSplash ? 0 : 1, // Use opacity to control the fade
					transition: 'opacity 2s', // Add a transition for a smooth fade-out effect
				}}
			>
				<Grid2 container>
					<Grid2 xs={6} sm={6} md={6} lg={6} className="tw-inline-block">
						<Box className="tw-p-6">
							<Box className="tw-p-2" sx={{ height: "calc(100vh - 48px)", width: "100%", background: "#F5D3A0", position: "relative" }}>
								<Box className="tw-text-center">
									<Typography className="font-pirata-one" sx={{ color: "#000", fontSize: "48px" }}>
										{ s_CREW_LEDGER }
									</Typography>
								</Box>
								<Divider />
								<Grid2 container>
									<Grid2 xs={12} sm={12} md={12} lg={12} className="tw-inline-block" sx={{ overflow: "scroll", height: "calc(100vh - 340px)" }}>
										<Box>
											{objectToArray( getProp( us_rootGame, "players", {} ) ).sort( dynamicSort( "timestamp_joined", null )).map(( player: TsInterface_UnspecifiedObject, playerIndex: TsType_Any ) => (
												<Box key={ playerIndex } >

													<Typography className="font-pirata-one" sx={{ color: "#000", fontSize: "32px" }}>
														<Icon icon="sailboat" className="tw-mr-2" />
														{ player.name }
													</Typography>

												</Box>
											))}
										</Box>
									</Grid2>
									<Grid2 xs={12} sm={12} md={12} lg={12} className="tw-inline-block tw-text-center">
										<QRCode fgColor="#71482E" bgColor="#F5D3A0" size={ 200 } value={ window.location.origin + ApplicationPages.PirateGameMobileJoinPage.url( gameKey ) } />
									</Grid2>
								</Grid2>
							</Box>
						</Box>
					</Grid2>
					<Grid2 xs={6} sm={6} md={6} lg={6} className="tw-inline-block">
						<Box className="tw-p-6">
							<Box className="tw-p-2" sx={{ height: "calc(100vh - 48px)", width: "100%", background: "#F5D3A0", position: "relative" }}>
								<Box className="tw-text-center">
									<Typography className="font-pirata-one" sx={{ color: "#000", fontSize: "48px" }}>
										{ s_SET_SAIL }
									</Typography>
								</Box>
								<Divider />
								<Grid2 container>
									<Grid2 xs={12} sm={12} md={12} lg={12} className="tw-inline-block" sx={{ overflow: "scroll", height: "calc(100vh - 210px)" }}>
										<Box>
											<Box>
												<Typography className="font-pirata-one tw-inline-block tw-mr-2" sx={{ color: "#000", fontSize: "24px" }}>
													{ s_GAME_TYPE }:
												</Typography>
												<Typography className="font-pirata-one tw-inline-block" sx={{ color: "#f00", fontSize: "24px" }}>
													{ "standard" }
												</Typography>
											</Box>
											<Box>
											<Typography className="font-pirata-one tw-inline-block tw-mr-2" sx={{ color: "#000", fontSize: "24px" }}>
													{ s_MAP }:
												</Typography>
												<Typography className="font-pirata-one tw-inline-block" sx={{ color: "#f00", fontSize: "24px" }}>
													{ "standard" }
												</Typography>
											</Box>
										</Box>
									</Grid2>
									<Grid2 xs={12} sm={12} md={12} lg={12} className="tw-inline-block">
										<Box className="tw-text-center tw-py-2">
											<Button
												variant="contained"
												sx={{ background: "#f00", color: "#fff" }}
												onClick={ () => {
													startGame()
												}}
											>
												<Typography className="font-pirata-one" sx={{ color: "#fff", fontSize: "32px" }}>
													{ s_SET_SAIL }
												</Typography>
											</Button>

										</Box>
									</Grid2>
								</Grid2>
							</Box>
						</Box>
					</Grid2>
				</Grid2>
			</Box>
			return lobbyJSX
		}

		const returnJSX_Page = (): TsType_JSX => {
			let pageJSX =
			<Box>
				{ returnJSX_AudioPlayer() }
				{ returnJSX_LogoSplash() }
				{ returnJSX_GameLobby() }
			</Box>
			return pageJSX
		}

		// Render
		return <>{returnJSX_Page()}</>

	}