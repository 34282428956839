///////////////////////////////
// Imports
///////////////////////////////

import {
	TsType_Number,
	TsType_String
} from 'rfbp_core/typescript/global_types'

///////////////////////////////
// Variables
///////////////////////////////

	const appVersionString: TsType_String = "0.0.1"

///////////////////////////////
// Exports
///////////////////////////////

	export const returnAppVersionNumber = (): TsType_Number => {
		return parseInt( appVersionString.replaceAll(".", "") )
	}

	export const returnAppVersionString = (): TsType_String => {
		return appVersionString
	}