//////////////////////////////////////////
//		  ooOOOO BOILERPLATE FILE		//
//		 oo		 _____					//
//		_I__n_n__||_|| ________			//
//	  >(_________|_7_|-|______|			//
//	   /o ()() ()() o   oo  oo			//
//////////////////////////////////////////

///////////////////////////////
// Description
///////////////////////////////

	/*
		DESCRIPTION / USAGE:
			Example documentation for timeline components

		TODO:
			Styles and customization

	*/


///////////////////////////////
// Imports
///////////////////////////////

import {
	ComponentDocumentation,
	TsInterface_ComponentDocumentation
} from 'rfbp_core/components/_example_components'
import {
	TimelineBasic,
	TsInterface_TimelineEvents,
	TsInterface_TimelineSettings
} from 'rfbp_core/components/timeline'
import {
	TsType_JSX
} from 'rfbp_core/typescript/global_types'
import {
	Box
} from '@mui/material/'

///////////////////////////////
// Typescript
///////////////////////////////

	interface TsInterface_ComponentProps {}


///////////////////////////////
// Variables
///////////////////////////////

	// Displayed Translatable Strings
	// { sort-start } - displayed text - scoped sort plugin

	// { sort-end } - displayed text

	// Html strings
	const TimelineBasicHtmlExample =
	`    <TimelineBasic
	timelineEvents={ timelineEvents }
	timelineSettings={ timelineSettings }
	/>`


///////////////////////////////
// Functions
///////////////////////////////


///////////////////////////////
// Component
///////////////////////////////

	export const ExampleTimeline = ( props: TsInterface_ComponentProps ): TsType_JSX => {

		// Props

		// Hooks - useContext, useState, useReducer, other
		// { sort-start } - hooks

		// { sort-end } - hooks

		// Hooks - useEffect

		// Other Variables
		const timelineBasicSettings: TsInterface_TimelineSettings = {
			direction: "asc"
		}

		const timelineEvents: TsInterface_TimelineEvents = {
			e0: { text: "Event 0", 		timestamp: new Date(2022, 5, 4, 23, 36).getTime() 	},
			e1: { text: "Event 1", 		timestamp: new Date(2022, 5, 5, 1, 1).getTime() 	},
			e2: { text: "Event 2", 		timestamp: new Date(2022, 5, 5, 1, 2).getTime() 	},
		}

		const documentationObject: TsInterface_ComponentDocumentation = {
			group_name: "Timelines",
			group_description: "Components related to timelines",
			components: {
				TimelineBasic: {
					component_name:
					<Box component="span">
						<span className="tw-font-bold tw-inline-block tw-align-top tw-normal-case">TimelineBasic</span>
					</Box>,
					component_description: "Basic timeline component",
					component_example_jsx_string: TimelineBasicHtmlExample,
					component_jsx:
					<Box>
						<TimelineBasic
							timelineSettings={timelineBasicSettings}
							timelineEvents={timelineEvents}
						/>
					</Box>,
					component_props: {
						timelineSettings: {
							propKey: "timelineSettings",
							propTypeOrInterface: "TsInterface_TimelineSettings",
							propDescription: "Settings used for setting up timeline",
							propExampleValue: timelineBasicSettings
						},
						timelineEvents: {
							propKey: "timelineEvents",
							propTypeOrInterface: "TsInterface_TimelineEvents",
							propDescription: "Events to render in timeline",
							propExampleValue: timelineEvents
						},
					},
					component_todo: {
						1: "Style / Customization options",
					}
				},
			}
		}

		// Functions



		// JSX Generation
		const returnJSX_Component = (): TsType_JSX => {
			let componentJSX =
			<Box>
				<ComponentDocumentation documentation={ documentationObject } />
			</Box>
			return componentJSX
		}

		// Render
		return <>{ returnJSX_Component() }</>
	}