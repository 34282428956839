///////////////////////////////
// Description
///////////////////////////////

	/*
		DESCRIPTION / USAGE:
			Model files contains data and business logic specific to an individual database collection type

		TODO:

	*/


///////////////////////////////
// Imports
///////////////////////////////

import {
	activateAccount,
	deactivateAccount
} from 'app/models/users'
import {
	Trans
} from 'react-i18next'
import {
	ApplicationPages
} from 'rfbp_aux/data/application_structure'
import {
	Icon
} from 'rfbp_core/components/icons'
import {
	TableCellBasic,
	TableCellManage,
	TableCellPhone,
	TsInterface_TableAdditionalData,
	TsInterface_TableColumns,
	TsInterface_TableDatabaseSettings,
	TsInterface_TableDataRow,
	TsInterface_TableHooks
} from 'rfbp_core/components/table'
import {
	getProp
} from 'rfbp_core/services/helper_functions'
import {
	getClientKey
} from 'rfbp_core/services/user_authentication'
import {
	TsType_JSX,
	TsType_String,
	TsType_Void
} from 'rfbp_core/typescript/global_types'

///////////////////////////////
// Typescript
///////////////////////////////


///////////////////////////////
// Variables
///////////////////////////////

	// Displayed Translatable Strings
	const s_ARE_YOU_SURE_THAT_YOU_WANT_TO_LOCK_THIS_USER_ACCOUNT: TsType_JSX = 		<Trans>Are you sure that you want to lock this user account?</Trans>
	const s_ARE_YOU_SURE_THAT_YOU_WANT_TO_UNLOCK_THIS_USER_ACCOUNT: TsType_JSX = 	<Trans>Are you sure that you want to unlock this user account?</Trans>
	const s_DISABLE_ACCOUNT: TsType_JSX = 											<Trans>Disable Account</Trans>
	const s_EDIT: TsType_JSX = 														<Trans>Edit</Trans>
	const s_EMAIL: TsType_JSX = 													<Trans>Email</Trans>
	const s_ENABLE_ACCOUNT: TsType_JSX = 											<Trans>Enable Account</Trans>
	const s_KEY: TsType_JSX = 														<Trans>Key</Trans>
	const s_LOCK_ACCOUNT: TsType_JSX = 												<Trans>Lock Account</Trans>
	const s_PHONE: TsType_JSX = 													<Trans>Phone</Trans>
	const s_UNLOCK_ACCOUNT: TsType_JSX = 											<Trans>Unlock account</Trans>
	const s_USER_NAME: TsType_JSX = 												<Trans>User Name</Trans>
	const s_VIEW: TsType_JSX = 														<Trans>View</Trans>
	const s_NO_USERS_FOUND_BY_SEARCH: TsType_JSX = 									<Trans>No Users found by search</Trans>

///////////////////////////////
// Functions
///////////////////////////////

	const viewUser = ( rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks ): TsType_Void => {
		if ( rowData.key != null ){
			tableHooks.un_routerNaviation( ApplicationPages.AdminDatabaseUserViewPage.url( rowData.key as  TsType_String ))
		}
	}

	const editUser = ( rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks ): TsType_Void => {
		if ( rowData.key != null ){
			tableHooks.un_routerNaviation( ApplicationPages.AdminDatabaseUserEditPage.url( rowData.key as  TsType_String ))
		}
	}

	const disableUserAccount = ( rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks ): TsType_Void => {
		tableHooks.uc_setUserInterface_ConfirmDialogDisplay({
			display: true,
			confirm: {
				color: "error",
				icon: <Icon icon="lock" />,
				header: s_DISABLE_ACCOUNT,
				text: s_ARE_YOU_SURE_THAT_YOU_WANT_TO_LOCK_THIS_USER_ACCOUNT,
				submit_text: s_LOCK_ACCOUNT,
				submit_callback: () => {
					return new Promise( ( resolve, reject ) => {
						getClientKey( tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey ).then(( res_GCK ) => {
							if ( res_GCK.clientKey != null && rowData.key != null ){
								let userName = getProp( tableHooks.uc_RootData_ClientUser, "name", null )
								let userKey = getProp( tableHooks.uc_RootData_ClientUser, "key", null )
								deactivateAccount( res_GCK.clientKey, rowData.key as TsType_String, userName, userKey ).then(( res_DA ) => {
									// TODO - Toast?
									resolve( res_DA )
								}).catch((rej_DA) => {
									tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DA.error })
									reject( rej_DA )
								})
							}
						}).catch(( rej_GCK ) => {
							tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
							reject( rej_GCK )
						})
					})
				}
			}
		})
	}

	const enableUserAccount = ( rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks ): TsType_Void => {
		tableHooks.uc_setUserInterface_ConfirmDialogDisplay({
			display: true,
			confirm: {
				color: "warning",
				icon: <Icon icon="lock-open" />,
				header: s_ENABLE_ACCOUNT,
				text: s_ARE_YOU_SURE_THAT_YOU_WANT_TO_UNLOCK_THIS_USER_ACCOUNT,
				submit_text: s_UNLOCK_ACCOUNT,
				submit_callback: () => {
					return new Promise( ( resolve, reject ) => {
						getClientKey( tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey ).then(( red_GCK ) => {
							if ( red_GCK.clientKey != null && rowData.key != null ){
								let userName = getProp( tableHooks.uc_RootData_ClientUser, "name", null )
								let userKey = getProp( tableHooks.uc_RootData_ClientUser, "key", null )
								activateAccount( red_GCK.clientKey, rowData.key as TsType_String, userName, userKey ).then(( rej_AA ) => {
									// TODO - Toast?
									resolve( rej_AA )
								}).catch(( rej_AA ) => {
									tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_AA.error })
									reject( rej_AA )
								})
							}
						}).catch(( res_GCK ) => {
							tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: res_GCK.error })
							reject( res_GCK )
						})
					})
				}
			}
		})
	}

	// JSX generation

///////////////////////////////
// Exports
///////////////////////////////

	export const tableSettings_AllUsers: TsInterface_TableDatabaseSettings = {
		rows_per_page: 20,
		show_header: true,
		size: "small",
		sort_direction: "asc",
		sort_property: "name",
		use_live_data: false,
		conditional_row_styles: [
			{
				className: "tw-opacity-30 tw-line-through",
				conditional_display: {
					active: true,
					logic_type: "comparison",
					source: "rowData",
					prop: "status",
					comparator: "==",
					value: "deleted",
					conditions: []
				},
			},
		],
		searchable: true,
		search_settings_database: {
			search_type: "firebase",
			search_no_data_message: s_NO_USERS_FOUND_BY_SEARCH,
			search_property_options: [
				{ key: "name", value: s_USER_NAME },
				{ key: "email", value: s_EMAIL },
				{ key: "phone", value: s_PHONE },
			]
		},
	}

	export const tableSettings_User: TsInterface_TableDatabaseSettings = {
		rows_per_page: 20,
		show_header: true,
		size: "small",
		sort_direction: "asc",
		sort_property: "name",
		use_live_data: false,
		conditional_row_styles: [
			{
				className: "tw-opacity-30 tw-line-through",
				conditional_display: {
					active: true,
					logic_type: "comparison",
					source: "rowData",
					prop: "status",
					comparator: "==",
					value: "deleted",
					conditions: []
				},
			},
		],
	}

	const nameColumn = TableCellBasic( "name", s_USER_NAME, "name" )
	const emailColumn = TableCellBasic( "email", s_EMAIL, "email" )
	const phoneColumn = TableCellPhone( "phone", s_PHONE, "phone" )
	const manageColumn = TableCellManage({
		view: {
			icon: <Icon icon="magnifying-glass"/>,
			label: <>{ s_VIEW }</>,
			onClick: ( rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks ) => {
				viewUser( rowData, tableAdditionalData, tableHooks )
			}
		},
		edit: {
			icon: <Icon icon="pen-to-square"/>,
			label: <>{ s_EDIT }</>,
			conditional_display: {
				active: true,
				logic_type: "comparison",
				source: "rowData",
				prop: "status",
				comparator: "!=",
				value: "deleted",
				conditions: []
			},
			onClick: ( rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks ) => {
				editUser( rowData, tableAdditionalData, tableHooks )
			}
		},
		delete: {
			icon: <Icon icon="lock"/>,
			label: <>{ s_DISABLE_ACCOUNT }</>,
			conditional_display: {
				active: true,
				logic_type: "comparison",
				source: "rowData",
				prop: "status",
				comparator: "!=",
				value: "deleted",
				conditions: []
			},
			onClick: ( rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks ) => {
				disableUserAccount( rowData, tableAdditionalData, tableHooks )
			}
		},
		undelete: {
			icon: <Icon icon="lock-open"/>,
			label: <>{ s_ENABLE_ACCOUNT }</>,
			conditional_display: {
				active: true,
				logic_type: "comparison",
				source: "rowData",
				prop: "status",
				comparator: "==",
				value: "deleted",
				conditions: []
			},
			onClick: ( rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks ) => {
				enableUserAccount( rowData, tableAdditionalData, tableHooks )
			}
		},
	})
	const keyColumn = TableCellBasic( "key", s_KEY, "key" )

	export const tableColumns_User: TsInterface_TableColumns = {
		name: nameColumn,
		email: emailColumn,
		phone: phoneColumn,
		manage: manageColumn
	}

	export const tableColumns_UserReadOnly: TsInterface_TableColumns = {
		name: nameColumn,
		key: keyColumn,
		email: emailColumn,
		phone: phoneColumn,
	}