///////////////////////////////
// Description
///////////////////////////////

	/*
		DESCRIPTION / USAGE:
			containers are pages / views used in the app and are made up of components and can interact with services and models

		TODO:

			MAP main View

			Battle Initiated (animated?)
			Battle Results (animated?)


	*/


///////////////////////////////
// Imports
///////////////////////////////

import {
	useContext,
	useEffect,
	useReducer,
	useState
} from 'react'
import {
	Trans
} from 'react-i18next'
import {
	useParams
} from 'react-router-dom'
import {
	DatabaseRef_Game_Document
} from 'rfbp_aux/services/database_endpoints/games'
import {
	Context_UserInterface_ErrorDialog
} from 'rfbp_core/services/context'
import {
	DatabaseGetLiveDocument,
	DatabaseSetMergeDocument
} from 'rfbp_core/services/database_management'
import {
	getProp
} from 'rfbp_core/services/helper_functions'
import {
	TsInterface_UnspecifiedObject,
	TsType_JSX,
	TsType_String,
	TsType_Void,
	TsType_VoidFunction
} from 'rfbp_core/typescript/global_types'
import {
	Box,
	Typography
} from '@mui/material/'

///////////////////////////////
// Typescript
///////////////////////////////


///////////////////////////////
// Variables
///////////////////////////////

	// Authenticated Nav Data

	// Displayed Translatable Strings
	// { sort-start } - displayed text - scoped sort plugin
	const s_WAITING_TO_START: TsType_JSX = 		<Trans>Waiting to Start</Trans>
	// { sort-end } - displayed text


///////////////////////////////
// Functions
///////////////////////////////


///////////////////////////////
// Container
///////////////////////////////

	export const Container: React.FC = (): TsType_JSX => {

		// Props
		const params = useParams()
		const gameKey: TsType_String = params.id as TsType_String
		const userKey: TsType_String = params.uid as TsType_String

		// Hooks - useContext, useState, useReducer, other
		// { sort-start } - hooks
		// const un_routerNaviation = 								useNavigate()
		// const { uc_setUserInterface_ErrorDialogDisplay } = 		useContext( Context_UserInterface_ErrorDialog )
		const [ us_rootGame, us_setRootGame ] = 				useState<TsInterface_UnspecifiedObject>({})
		const ur_forceRerender = 								useReducer(() => ({}), {})[1] as () => TsType_Void
		// { sort-end } - hooks

		// Hooks - useEffect
		useEffect(() => {
			let unsubscribeLiveData: TsType_VoidFunction
			const updateLiveData = ( newData: TsInterface_UnspecifiedObject ) => {
				us_setRootGame( newData )
				ur_forceRerender()
			}
			unsubscribeLiveData = DatabaseGetLiveDocument( DatabaseRef_Game_Document( gameKey ), updateLiveData )
			return () => {
				if (typeof unsubscribeLiveData === 'function'){
					unsubscribeLiveData()
				}
			}
		}, [gameKey, ur_forceRerender])

		// Other Variables

		// Functions

		// Call Functions

		// JSX Generation
		const returnJSX_WaitingToStartPage = (): TsType_JSX => {
			let pageJSX =
			<Box
				sx={{
					height: "100vh",
					width: "100%",
					backgroundColor: "#000"
				}}
			>
				<Box
					className="tw-p-4"
					sx={{
						width: "100%",
						height: "100vh",
						background: "#71482E",
					}}
				>
					<Box
						sx={{
							maxWidth: "500px",
							margin: "auto",
						}}
					>

						<Box className="tw-text-center tw-py-2">
							<Typography className="font-pirata-one" sx={{ color: "#000", fontSize: "32px" }}>
								{ s_WAITING_TO_START }
							</Typography>
							<Typography className="font-pirata-one" sx={{ color: "#000", fontSize: "32px" }}>
								{ userKey }
							</Typography>
						</Box>

					</Box>
				</Box>
			</Box>
			return pageJSX
		}

		const returnJSX_ActiveGamePage = (): TsType_JSX => {
			let pageJSX = <></>

			pageJSX = <>Active Game Desktop</>

			return pageJSX
		}

		const returnJSX_GameOverPage = (): TsType_JSX => {
			let pageJSX = <></>

			return pageJSX
		}

		const returnJSX_Page = (): TsType_JSX => {
			let pageJSX = <></>
			if( us_rootGame != null ){
				switch( us_rootGame.status ){
					case "new":
						pageJSX = returnJSX_WaitingToStartPage()
						break
					case "active":
						pageJSX = returnJSX_ActiveGamePage()
						break
					case "complete":
						pageJSX = returnJSX_GameOverPage()
						break
				}
			}
			return pageJSX
		}

		// Render
		return <>{returnJSX_Page()}</>

	}