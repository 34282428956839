//////////////////////////////////////////
//		  ooOOOO BOILERPLATE FILE		//
//		 oo		 _____					//
//		_I__n_n__||_|| ________			//
//	  >(_________|_7_|-|______|			//
//	   /o ()() ()() o   oo  oo			//
//////////////////////////////////////////

///////////////////////////////
// Description
///////////////////////////////

	/*
		DESCRIPTION / USAGE:
			The data filter allows users to filter input data used for a table or other component

		TODO:

	*/


///////////////////////////////
// Imports
///////////////////////////////

import React, {
	useReducer
} from 'react'
import {
	Icon
} from 'rfbp_core/components/icons'
import {
	getProp,
	objectToArray
} from 'rfbp_core/services/helper_functions'
import {
	TsInterface_UnspecifiedObject,
	TsType_Boolean,
	TsType_JSX,
	TsType_Number,
	TsType_SetStateUnspecifiedObject,
	TsType_String,
	TsType_Void
} from 'rfbp_core/typescript/global_types'
import {
	Box,
	Checkbox,
	FormControl,
	IconButton,
	InputLabel,
	ListItemText,
	MenuItem,
	OutlinedInput,
	Select
} from '@mui/material/'

///////////////////////////////
// Typescript
///////////////////////////////

	interface TsInterface_DataFilterFilterOption {
		key: TsType_String
		value: TsType_JSX | TsType_String
		disabled?: TsType_Boolean
	}

	interface TsInterface_DataFilterFilter {
		key: TsType_String
		label: TsType_JSX | TsType_String
		selection_type: "single" | "multiple"
		options: TsInterface_DataFilterFilterOption[]
	}

	export interface TsInterface_DataFilterFiltersObject {
		[ $filterKey: TsType_String ]: TsInterface_DataFilterFilter
	}

	type TsType_DefaultSetFilteredData = ( filteredData: TsInterface_UnspecifiedObject ) => void

	interface TsInterface_ComponentProps {
		filtersFormData: TsInterface_UnspecifiedObject
		filterInputData: TsInterface_UnspecifiedObject
		setFilterOutputData: TsType_SetStateUnspecifiedObject | TsType_DefaultSetFilteredData
		filtersObject: TsInterface_DataFilterFiltersObject
	}

	type TsType_SelectedOption = TsType_String | TsType_Number | TsType_Boolean | TsType_JSX
	type TsType_SelectedOptionArray =TsType_SelectedOption[]


///////////////////////////////
// Variables
///////////////////////////////

	// Displayed Translatable Strings
	// { sort-start } - displayed text - scoped sort plugin

	// { sort-end } - displayed text


///////////////////////////////
// Functions
///////////////////////////////

	const renderSelectedOptions = ( filterInput: TsInterface_DataFilterFilter, selectedOptionsArray: TsType_SelectedOptionArray ): TsType_SelectedOptionArray => {
		let renderedOptionsArray: TsType_SelectedOptionArray = []
		for ( let selectedOptionIndex = 0; selectedOptionIndex < selectedOptionsArray.length; selectedOptionIndex++ ){
			let selectedOption = selectedOptionsArray[ selectedOptionIndex ]
			for ( let allOptionIndex in filterInput["options"] ){
				let allOption: TsInterface_DataFilterFilterOption = filterInput["options"][ parseInt( allOptionIndex ) ]
				if ( allOption != null && selectedOption === allOption.key ){
					renderedOptionsArray[ selectedOptionIndex ] = allOption.value
				}
			}
		}
		return renderedOptionsArray
	}

///////////////////////////////
// Component
///////////////////////////////

	export const DataFilter = ( props: TsInterface_ComponentProps ): TsType_JSX => {

		// Props
		const pr_filterInputData: TsInterface_ComponentProps["filterInputData"] = 				getProp( props, "filterInputData", {} )
		const pr_filtersFormData: TsInterface_ComponentProps["filtersFormData"] = 				getProp( props, "filtersFormData", {} )
		const pr_filtersObject: TsInterface_ComponentProps["filtersObject"] = 					getProp( props, "filtersObject", {} )
		const pr_setFilterOutputData: TsInterface_ComponentProps["setFilterOutputData"] = 		getProp( props, "setFilterOutputData", () => { /* Do Nothing */ } )

		// Hooks - useContext, useState, useReducer, other
		// { sort-start } - hooks
		const ur_forceRerender = 																useReducer( () => ( {} ), {} )[1] as () => TsType_Void
		// { sort-end } - hooks

		// Hooks - useEffect

		// Other Variables

		// Functions
		const generateFilteredData = (): TsType_Void => {
			// Instantiate Variables
			let outputData: TsInterface_UnspecifiedObject[] = []
			// Loop through items in input data
			for (let loopItemIndex in pr_filterInputData){
				let loopItem = pr_filterInputData[ loopItemIndex ]
				let failsFilter = false
				// Loop through selected filters
				for ( let filterInputKey in pr_filtersObject ){
					let loopFilterInput = pr_filtersObject[ filterInputKey ]
					if (loopFilterInput["selection_type"] === "single"){
						if (
							pr_filtersFormData != null &&
							pr_filtersFormData[ filterInputKey ] != null &&
							pr_filtersFormData[ filterInputKey ] !== "" &&
							(
								loopItem[ filterInputKey ] == null ||
								loopItem[ filterInputKey ] !== pr_filtersFormData[ filterInputKey ]
							)
						){
							failsFilter = true
						}
					} else if (loopFilterInput["selection_type"] === "multiple"){
						if (
							pr_filtersFormData != null &&
							pr_filtersFormData[ filterInputKey ] != null
						){
							let foundMatch = false
							for (let selectedOptionIndex in pr_filtersFormData[ filterInputKey ]){
								let selectedOptionValue = pr_filtersFormData[ filterInputKey ][ selectedOptionIndex ]
								if (loopItem[ filterInputKey ] === selectedOptionValue){
									foundMatch = true
								}
							}
							if (foundMatch === false){
								failsFilter = true
							}
						}
					}
				}
				// If the item meets all filter criteria, add to output array
				if ( failsFilter === false ){
					outputData.push( loopItem )
				}
			}
			// Set output data and trigger rerender
			pr_setFilterOutputData( outputData )
			ur_forceRerender()
		}



		// JSX Generation
		const returnJSX_FilterInput = ( filterInput: TsInterface_DataFilterFilter ): TsType_JSX => {
			let filterInputJSX = <></>
			if ( filterInput.selection_type === "single" ){
				filterInputJSX =
				<Box component="span">
					<FormControl className="bp_data_filter_input">
						<InputLabel id={ filterInput["key"] }>{ filterInput["label"] }</InputLabel>
						<Select
							autoWidth={ true }
							id={ filterInput["key"] }
							label={ filterInput["label"] }
							labelId={ filterInput["key"] }
							onChange={ ( event, value ) => {
								pr_filtersFormData[ filterInput["key"] ] = event.target.value
								generateFilteredData()
							} }
							value={ pr_filtersFormData[filterInput.key] || "" }
						>
							{filterInput["options"].map(( option: TsInterface_DataFilterFilterOption ) => (
								<MenuItem key={ option["key"] } value={ option["key"] } disabled={ option["disabled"] }>
									{ option["value"] }
								</MenuItem>
							))}
						</Select>
					</FormControl>
					<IconButton size="small" className="tw-p-0 tw-mt-4 tw-mr-2" onClick={( event ) => {
						pr_filtersFormData[ filterInput["key"] ] = ""
						generateFilteredData()
					}}>
						<Icon icon="x" />
					</IconButton>
				</Box>
			} else if ( filterInput.selection_type === "multiple" ){
				if ( pr_filtersFormData[ filterInput.key ] == null ){ pr_filtersFormData[ filterInput.key ] = [] }
				filterInputJSX =
				<Box component="span">
					<FormControl className="bp_data_filter_input">
						<InputLabel id={ filterInput["key"] }>{ filterInput["label"] }</InputLabel>
						<Select
							autoWidth={ true }
							labelId={ filterInput["key"] }
							id={ filterInput["key"] }
							input={<OutlinedInput label={ filterInput["label"] } />}
							label={ filterInput["label"] }
							multiple
							onChange={ ( event, value ) => {
								pr_filtersFormData[ filterInput["key"] ] = event.target.value
								generateFilteredData()
							} }
							renderValue={ ( selected ) => <>{ renderSelectedOptions( filterInput, selected ).join(', ')}</> }
							value={ pr_filtersFormData[ filterInput.key ] || []}
						>
							{filterInput["options"].map(( option: TsInterface_DataFilterFilterOption ) => (
								<MenuItem key={ option["key"] } value={ option["key"] } disabled={ option["disabled"] }>
									<Checkbox checked={ pr_filtersFormData[ filterInput.key ].indexOf( option["key"] ) > -1 } />
									<ListItemText primary={ option["value"] } />
								</MenuItem>
							))}
						</Select>
					</FormControl>
					<IconButton size="small" className="tw-p-0 tw-mt-4 tw-mr-2" onClick={( event ) => {
						pr_filtersFormData[ filterInput["key"] ] = []
						generateFilteredData()
					}}>
						<Icon icon="x" />
					</IconButton>
				</Box>
			}
			return filterInputJSX
		}

		const returnJSX_Component = (): TsType_JSX => {
			let componentJSX =
			<Box>
				{objectToArray( pr_filtersObject ).map(( filter: TsInterface_DataFilterFilter, filterIndex: TsType_Number ) => (
					<Box component='span' key={ filterIndex } className="tw-pr-3">
						{returnJSX_FilterInput( filter )}
					</Box>
				))}
			</Box>
			return componentJSX
		}

		// Render
		return <>{ returnJSX_Component() }</>
	}