//////////////////////////////////////////
//		  ooOOOO BOILERPLATE FILE		//
//		 oo		 _____					//
//		_I__n_n__||_|| ________			//
//	  >(_________|_7_|-|______|			//
//	   /o ()() ()() o   oo  oo			//
//////////////////////////////////////////

///////////////////////////////
// Description
///////////////////////////////

	/*
		DESCRIPTION / USAGE:
			Renders a full month calendar with events on days rendered using a

		TODO:
			[ ] Feature - Custom Renderer Prop that supports clicks on events
			[ ] Feature - An on click event for a specific date
			// Calendar Settings
			// Calendar Data
			// Calendar Additional Data
			// Calendar Day Renderer
				// Events, On Clicks, etc
	*/


///////////////////////////////
// Imports
///////////////////////////////

import React, {
	useEffect,
	useState
} from 'react'
import {
	Trans
} from 'react-i18next'
import {
	themeVariables
} from 'rfbp_aux/config/app_theme' // OUTSIDE BOILERPLATE
import {
	TsInterface_CalendarAdditionalData,
	TsInterface_CalendarData,
	TsInterface_CalendarEvent,
	TsInterface_CalendarSettings,
	TsType_CalendarDateChangeCallback
} from 'rfbp_core/components/calendar'
import {
	Icon
} from 'rfbp_core/components/icons'
import {
	dynamicSort,
	getProp,
	millisecondsPerDay,
	returnFormattedDate
} from 'rfbp_core/services/helper_functions'
import {
	TsInterface_UnspecifiedObject,
	TsType_Boolean,
	TsType_Date,
	TsType_JSX,
	TsType_Number,
	TsType_String,
	TsType_Undefined,
	TsType_Void
} from 'rfbp_core/typescript/global_types'
import {
	Box,
	Button,
	Card,
	Grid,
	Typography
} from '@mui/material/'

///////////////////////////////
// Types
///////////////////////////////

	interface TsInterface_ComponentProps {
		calendarAdditionalData: TsInterface_CalendarAdditionalData
		calendarData: TsInterface_CalendarData
		calendarDateChangeCallback: TsType_CalendarDateChangeCallback
		calendarSettings: TsInterface_CalendarSettings
	}

	interface TsInterface_CalendarDayStyles {
		background?: TsType_String
		maxHeight: TsType_String
		minHeight: TsType_String
		overflow?: TsType_String
	}

	interface DayObjectInterface {
		date: TsType_Date
		day: TsType_Number
		events: TsInterface_CalendarEvent[]
		is_current_day: TsType_Boolean
		is_current_month: TsType_Boolean
		month: TsType_JSX | TsType_Undefined
		week_number: TsType_Number[]
		weekday: TsType_JSX | TsType_Undefined
		year: TsType_Number
	}

	interface CalendarRowArrayInterface extends Array< DayObjectInterface >{}


///////////////////////////////
// Variables
///////////////////////////////

	// Displayed Translatable Strings
	// { sort-start } - displayed text - scoped sort plugin
	const s_APR: TsType_JSX = 				<Trans>Apr</Trans>
	const s_APRIL: TsType_JSX = 			<Trans>April</Trans>
	const s_AUG: TsType_JSX = 				<Trans>Aug</Trans>
	const s_AUGUST: TsType_JSX = 			<Trans>August</Trans>
	const s_DEC: TsType_JSX = 				<Trans>Dec</Trans>
	const s_DECEMBER: TsType_JSX = 			<Trans>December</Trans>
	const s_FEB: TsType_JSX = 				<Trans>Feb</Trans>
	const s_FEBRUARY: TsType_JSX = 			<Trans>February</Trans>
	const s_FRI: TsType_JSX = 				<Trans>Fri</Trans>
	const s_FRIDAY: TsType_JSX = 			<Trans>Friday</Trans>
	const s_JAN: TsType_JSX = 				<Trans>Jan</Trans>
	const s_JANUARY: TsType_JSX = 			<Trans>January</Trans>
	const s_JUL: TsType_JSX = 				<Trans>Jul</Trans>
	const s_JULY: TsType_JSX = 				<Trans>July</Trans>
	const s_JUN: TsType_JSX = 				<Trans>Jun</Trans>
	const s_JUNE: TsType_JSX = 				<Trans>June</Trans>
	const s_MAR: TsType_JSX = 				<Trans>Mar</Trans>
	const s_MARCH: TsType_JSX = 			<Trans>March</Trans>
	const s_MAY: TsType_JSX = 				<Trans>May</Trans>
	const s_MON: TsType_JSX = 				<Trans>Mon</Trans>
	const s_MONDAY: TsType_JSX = 			<Trans>Monday</Trans>
	const s_NOV: TsType_JSX = 				<Trans>Nov</Trans>
	const s_NOVEMBER: TsType_JSX = 			<Trans>November</Trans>
	const s_OCT: TsType_JSX = 				<Trans>Oct</Trans>
	const s_OCTOBER: TsType_JSX = 			<Trans>October</Trans>
	const s_SAT: TsType_JSX = 				<Trans>Sat</Trans>
	const s_SATURDAY: TsType_JSX = 			<Trans>Saturday</Trans>
	const s_SEP: TsType_JSX = 				<Trans>Sep</Trans>
	const s_SEPTEMBER: TsType_JSX = 		<Trans>September</Trans>
	const s_SUN: TsType_JSX = 				<Trans>Sun</Trans>
	const s_SUNDAY: TsType_JSX = 			<Trans>Sunday</Trans>
	const s_THU: TsType_JSX = 				<Trans>Thu</Trans>
	const s_THURSDAY: TsType_JSX = 			<Trans>Thursday</Trans>
	const s_TODAY: TsType_JSX = 			<Trans>Today</Trans>
	const s_TUE: TsType_JSX = 				<Trans>Tue</Trans>
	const s_TUESDAY: TsType_JSX = 			<Trans>Tuesday</Trans>
	const s_WED: TsType_JSX = 				<Trans>Wed</Trans>
	const s_WEDNESDAY: TsType_JSX = 		<Trans>Wednesday</Trans>
	const s_WEEK: TsType_JSX = 				<Trans>Week</Trans>
	// { sort-end } - displayed text

	// Other
	const defaultCalendarDateChangeCallback = ( startDate: TsType_Date, endDate: TsType_Date ) => {
		return new Promise(( resolve, reject ) => {
			resolve({ success: true })
		})
	}


///////////////////////////////
// Functions
///////////////////////////////


///////////////////////////////
// Component
///////////////////////////////

	export const CalendarMonth = ( props: TsInterface_ComponentProps ): TsType_JSX => {

		// Props
		let pr_calendarData: TsInterface_CalendarData = 								getProp( props, "calendarData", {} )
		let pr_calendarDateChangeCallback: TsType_CalendarDateChangeCallback = 			getProp( props, "calendarDateChangeCallback", defaultCalendarDateChangeCallback )
		let pr_calendarSettings: TsInterface_CalendarSettings = 						getProp( props, "calendarSettings", {} )
		let pr_highlightedDate: TsType_Date = 											getProp( pr_calendarSettings, "highlighted_date", new Date() )
		let pr_initialSelectedDate: TsType_Date = 										getProp( pr_calendarSettings, "initial_selected_date", new Date() )

		let eventRenderer = ( event: TsInterface_CalendarEvent ) => {
			let eventJSX: TsType_JSX =
			<Box>
				<Icon icon="circle" className="tw-inline-block tw-text-xs tw-text-info_main tw-mr-1"/>
				<Typography variant="body2" className="tw-inline-block">
					{ returnFormattedDate( event.event_timestamp, 'HH:mm' ) } { event.name }
				</Typography>
			</Box>
			return eventJSX
		}

		// Hooks - useContext, useState, useReducer, other
		// { sort-start } - hooks
		const [ us_selectedDate, us_setSelectedDate ] = 			useState< TsType_Date >( pr_initialSelectedDate )
		const [ us_themeColors, us_setThemeColors ] = 				useState< TsInterface_UnspecifiedObject >( {} )
		// { sort-end } - hooks

		// Hooks - useEffect
		useEffect(() => {
			if ( themeVariables.mode === "dark" ){
				us_setThemeColors({
					calendarColorLight: themeVariables.gray_800,
					calendarColorMain: themeVariables.gray_900,
					calendarColorDark: themeVariables.background_json,
				})
			} else {
				us_setThemeColors({
					calendarColorLight: themeVariables.gray_200,
					calendarColorMain: themeVariables.gray_300,
					calendarColorDark: themeVariables.gray_400,
				})
			}
		}, [])

		// Other Variables
		let tempCalendarVisibleRows: CalendarRowArrayInterface[] = []
		let calendarVisibleRows: CalendarRowArrayInterface[] = []
		let selectedMonth: TsType_Number = us_selectedDate.getMonth()
		let selectedYear: TsType_Number = us_selectedDate.getFullYear()
		let highlightedDay: TsType_Number = pr_highlightedDate.getDate()
		let highlightedMonth: TsType_Number = pr_highlightedDate.getMonth()
		let highlightedYear: TsType_Number = pr_highlightedDate.getFullYear()

		// Functions
		const getWeekNumber = ( d: Date ): TsType_Number[] => {
			// Copy date so don't modify original
			d = new Date( Date.UTC( d.getFullYear(), d.getMonth(), d.getDate() ))
			// Set to nearest Thursday: current date + 4 - current day number
			// Make Sunday's day number 7
			d.setUTCDate( d.getUTCDate() + 4 - ( d.getUTCDay() || 7 ) )
			// Get first day of year
			let yearStart: Date = new Date( Date.UTC( d.getUTCFullYear(), 0, 1 ))
			// Calculate full weeks to nearest Thursday
			let weekNo: TsType_Number = Math.ceil(( ( ( d.getTime() - yearStart.getTime() ) / millisecondsPerDay ) + 1 ) /7 );
			// Return array of year and week number
			return [ d.getUTCFullYear(), weekNo ];
		}

		const returnJSX_Weekday = ( dayNumber: TsType_Number, dateLength: TsType_String ): TsType_JSX => {
			let weekdayName: TsType_JSX = <></>
			if ( dateLength === "short" ){
				switch( dayNumber ){
					case 0: weekdayName = s_SUN; break
					case 1: weekdayName = s_MON; break
					case 2: weekdayName = s_TUE; break
					case 3: weekdayName = s_WED; break
					case 4: weekdayName = s_THU; break
					case 5: weekdayName = s_FRI; break
					case 6: weekdayName = s_SAT; break
					default: break
				}
			} else {
				switch( dayNumber ){
					case 0: weekdayName = s_SUNDAY; break
					case 1: weekdayName = s_MONDAY; break
					case 2: weekdayName = s_TUESDAY; break
					case 3: weekdayName = s_WEDNESDAY; break
					case 4: weekdayName = s_THURSDAY; break
					case 5: weekdayName = s_FRIDAY; break
					case 6: weekdayName = s_SATURDAY; break
					default: break
				}
			}
			return weekdayName
		}

		const returnJSX_Month = ( monthNumber: TsType_Number, dateLength: TsType_String ): TsType_JSX => {
			let monthName: TsType_JSX = <></>
			if ( dateLength === "short" ){
				switch( monthNumber ){
					case 0:  monthName = s_JAN; break
					case 1:  monthName = s_FEB; break
					case 2:  monthName = s_MAR; break
					case 3:  monthName = s_APR; break
					case 4:  monthName = s_MAY; break
					case 5:  monthName = s_JUN; break
					case 6:  monthName = s_JUL; break
					case 7:  monthName = s_AUG; break
					case 8:  monthName = s_SEP; break
					case 9:  monthName = s_OCT; break
					case 10: monthName = s_NOV; break
					case 11: monthName = s_DEC; break
					default: break
				}
			} else {
				switch( monthNumber ){
					case 0:  monthName = s_JANUARY; break
					case 1:  monthName = s_FEBRUARY; break
					case 2:  monthName = s_MARCH; break
					case 3:  monthName = s_APRIL; break
					case 4:  monthName = s_MAY; break
					case 5:  monthName = s_JUNE; break
					case 6:  monthName = s_JULY; break
					case 7:  monthName = s_AUGUST; break
					case 8:  monthName = s_SEPTEMBER; break
					case 9:  monthName = s_OCTOBER; break
					case 10: monthName = s_NOVEMBER; break
					case 11: monthName = s_DECEMBER; break
					default: break
				}
			}
			return monthName
		}

		const generateCalendarRows = (): TsType_Void => {
			let firstCalendarDate: TsType_Date = new Date( selectedYear, selectedMonth, 1 )
			let lastCalendarDate: TsType_Date = new Date( selectedYear, selectedMonth + 1, 0 )
			let firstDayOfWeek: TsType_Number = firstCalendarDate.getDay()
			let lastDayOfWeek: TsType_Number = lastCalendarDate.getDay()
			let prevMonthVisibleDays: TsType_Number = firstDayOfWeek
			let nextMonthVisibleDays: TsType_Number = 6 - lastDayOfWeek
			let firstVisbleDay: TsType_Date = new Date( selectedYear, selectedMonth, 1 - prevMonthVisibleDays )
			let lastVisibleDay: TsType_Date = new Date( selectedYear, selectedMonth + 1, 0 + nextMonthVisibleDays )
			let totalDays: TsType_Number = ( lastVisibleDay.getTime() - firstVisbleDay.getTime() ) / millisecondsPerDay + 1
			for ( let i = 0; i < totalDays; i++ ){
				let rowIndex = Math.floor(i / 7)
				if ( tempCalendarVisibleRows[ rowIndex ] == null ){
					tempCalendarVisibleRows[ rowIndex ] = []
				}
				let loopDate = new Date( firstVisbleDay.getTime() + ( i * millisecondsPerDay ))
				let isCurrentMonth = false
				if ( selectedMonth === loopDate.getMonth() ){
					isCurrentMonth = true
				}
				let isCurrentDay = false
				if ( highlightedDay === loopDate.getDate() && highlightedMonth === loopDate.getMonth() && highlightedYear === loopDate.getFullYear() ){
					isCurrentDay = true
				}
				let dayEvents = []
				let eventStartBoundary = new Date( loopDate.getFullYear(), loopDate.getMonth(), loopDate.getDate(), 0, 0, 0 ).getTime()
				let eventEndBoundary = new Date( loopDate.getFullYear(), loopDate.getMonth(), loopDate.getDate() + 1, 0, 0, 0 ).getTime()
				for ( let eventsKey in pr_calendarData ){
					let event = pr_calendarData[ eventsKey ]
					if ( eventStartBoundary <= event["event_timestamp"] && event["event_timestamp"] < eventEndBoundary ){
						dayEvents.push( event )
					}
				}
				tempCalendarVisibleRows[ rowIndex ].push({
					date: loopDate,
					day: loopDate.getDate(),
					weekday: returnJSX_Weekday( loopDate.getDay(), "short" ),
					month: returnJSX_Month( loopDate.getMonth(), "short" ),
					year: loopDate.getFullYear(),
					week_number: getWeekNumber( loopDate ),
					is_current_month: isCurrentMonth,
					is_current_day: isCurrentDay,
					events: dayEvents
				})
			}
			// Finalize
			for ( let rowIndex in tempCalendarVisibleRows ){
				let showRow = false
				for ( let dayKey in tempCalendarVisibleRows[ rowIndex ] ){
					let loopDay = tempCalendarVisibleRows[ rowIndex ][ dayKey ]
					if ( loopDay != null && loopDay.is_current_month === true ){
						showRow = true
					}
				}
				if ( showRow === true ){
					calendarVisibleRows.push( tempCalendarVisibleRows[ rowIndex ] )
				}
			}
		}

		const updateCalendarDateBounds = ( newSelectedDate: TsType_Date ) => {
			us_setSelectedDate( newSelectedDate )
			let firstCalendarDate = new Date( newSelectedDate.getFullYear(), newSelectedDate.getMonth() + 0, 1 )
			let lastCalendarDate = new Date( newSelectedDate.getFullYear(), newSelectedDate.getMonth()+ 1, 0 )
			let firstDayOfWeek: TsType_Number = firstCalendarDate.getDay()
			let lastDayOfWeek: TsType_Number = lastCalendarDate.getDay()
			let prevMonthVisibleDays: TsType_Number = firstDayOfWeek
			let nextMonthVisibleDays: TsType_Number = 6 - lastDayOfWeek
			let firstVisbleDay: TsType_Date = new Date( newSelectedDate.getFullYear(), newSelectedDate.getMonth() + 0, 1 - prevMonthVisibleDays )
			let lastVisibleDay: TsType_Date = new Date( newSelectedDate.getFullYear(), newSelectedDate.getMonth() + 1, 0 + nextMonthVisibleDays + 1 )
			pr_calendarDateChangeCallback( firstVisbleDay, lastVisibleDay )
		}

		const moveCalendarBack = (): TsType_Void => {
			let newSelectedDate = new Date( us_selectedDate.getFullYear(), us_selectedDate.getMonth() - 1, 1 )
			updateCalendarDateBounds( newSelectedDate )
		}

		const moveToToday = (): TsType_Void => {
			let newSelectedDate = new Date( pr_highlightedDate.getFullYear(), pr_highlightedDate.getMonth() + 0, 1 )
			updateCalendarDateBounds( newSelectedDate )
		}

		const moveCalendarForward = (): TsType_Void => {
			let newSelectedDate = new Date( us_selectedDate.getFullYear(), us_selectedDate.getMonth() + 1, 1 )
			updateCalendarDateBounds( newSelectedDate )
		}


		generateCalendarRows()

		// JSX Generation
		const returnJSX_DayContainer = ( day: DayObjectInterface ): TsType_JSX => {
			let dayStyles: TsInterface_CalendarDayStyles = {
				minHeight: "12.5vh",
				maxHeight: "12.5vh",
			}
			let eventContainerStyles: TsInterface_CalendarDayStyles = {
				minHeight: "8.5vh",
				maxHeight: "8.5vh",
				overflow: "scroll"
			}
			if ( day != null && day.is_current_month === false ){
				dayStyles["background"] = us_themeColors.calendarColorLight
			}
			let dayContainer: TsType_JSX = <></>
			if ( day != null ){
				if ( day.events != null && day.events.length > 0 ){
					day.events.sort( dynamicSort( "event_timestamp", null ))
					dayContainer =
					<Box component='div' className="tw-p-2 tw-text-left" style={ dayStyles }>
						<Typography variant="body2">{ day["day"] }</Typography>
						<Box style={ eventContainerStyles }>
							{day.events.map(( event: TsInterface_CalendarEvent, index: TsType_Number ) => (
								<Box key={ index }>
									{eventRenderer( event )}
								</Box>
							))}
						</Box>
					</Box>
				} else {
					let className: TsType_String = "tw-inline-block tw-p-0.5 tw-rounded-full tw-w-6 tw-text-center"
					if ( day.is_current_day ){
						className = "tw-inline-block tw-p-0.5 tw-rounded-full tw-w-6 tw-text-center tw-bg-info_main tw-text-white"
					}
					dayContainer =
					<Box component='div' className="tw-p-2 tw-text-left" style={ dayStyles }>
						<Typography variant="body2" className={ className }>{ day["day"] }</Typography>
					</Box>
				}
			} else {
				dayContainer =
				<Box component='div' className="tw-p-2 tw-text-left" style={ dayStyles }>
					<Typography variant="body2"></Typography>
				</Box>
			}
			return dayContainer
		}

		const returnJSX_WeekNumber = ( calendarRow: DayObjectInterface[] ): TsType_JSX => {
			let weekNumberJSX: TsType_JSX = <></>
			if ( pr_calendarSettings.show_week_number === true ){
				weekNumberJSX =
				<Box component='div' className="tw-p-2" style={{ borderColor: us_themeColors.calendarColorDark, backgroundColor: us_themeColors.calendarColorMain }} sx={{ borderBottom: 1 }}>
					<Typography variant="body2">
						<strong>{ s_WEEK } { calendarRow[0]["week_number"][1] }: </strong>
						<span>{ calendarRow[0]["month"] } { calendarRow[0]["day"] } - { calendarRow[6]["month"] } { calendarRow[6]["day"] }, { calendarRow[6]["year"] }</span>
					</Typography>
				</Box>
			} else {
				weekNumberJSX = <></>
			}
			return weekNumberJSX
		}

		const returnJSX_Component = (): TsType_JSX => {
			let calendarJSX: TsType_JSX =
			<Card>
				<Grid container spacing={ 0 } columns={ 7 }>
					<Grid item sm={ 7 } xs={ 7 } className="tw-text-left" style={{ borderColor: us_themeColors.calendarColorDark }} sx={{ borderBottom: 1 }}>
						<Box component='div' className="tw-p-2" style={{ background: us_themeColors.calendarColorMain }}>
							<Button variant="outlined" className="tw-ml-2 tw-px-1 tw-inline-block tw-text-center" onClick={( event ) => { moveToToday() }}>
								{ s_TODAY }
							</Button>
							<Button className="tw-px-1 tw-inline-block tw-text-center tw-mx-2 tw-min-w-0" onClick={( event ) => { moveCalendarBack() }}>
								<Icon icon="chevron-left" size="sm" className="tw-align-middle tw-mb-1" />
							</Button>
							<Button className="tw-px-1 tw-inline-block tw-text-center tw-mx-2 tw-min-w-0" onClick={( event ) => { moveCalendarForward() }}>
								<Icon icon="chevron-right" size="sm" className="tw-align-middle tw-mb-1" />
							</Button>
							<Typography variant="h5" className="tw-inline-block">
								<strong>{ returnJSX_Month( selectedMonth, "full" ) } {selectedYear}</strong>
							</Typography>
						</Box>
					</Grid>
				</Grid>
				<Grid container spacing={ 0 } columns={ 7 }>
					<Grid item sm={ 1 } xs={ 1 } className="tw-text-center" style={{ borderColor: us_themeColors.calendarColorDark }} sx={{ borderBottom: 1 }}>
						<Box component='div' className="tw-p-2" style={{ background: us_themeColors.calendarColorMain }}>
							<Typography variant="body2">
								<strong>{ s_SUN }</strong>
							</Typography>
						</Box>
					</Grid>
					<Grid item sm={ 1 } xs={ 1 } className="tw-text-center" style={{ borderColor: us_themeColors.calendarColorDark }} sx={{ borderBottom: 1, borderLeft: 1 }}>
						<Box component='div' className="tw-p-2" style={{ background: us_themeColors.calendarColorMain }}>
							<Typography variant="body2">
								<strong>{ s_MON }</strong>
							</Typography>
						</Box>
					</Grid>
					<Grid item sm={ 1 } xs={ 1 } className="tw-text-center" style={{ borderColor: us_themeColors.calendarColorDark }} sx={{ borderBottom: 1, borderLeft: 1 }}>
						<Box component='div' className="tw-p-2" style={{ background: us_themeColors.calendarColorMain }}>
							<Typography variant="body2">
								<strong>{ s_TUE }</strong>
							</Typography>
						</Box>
					</Grid>
					<Grid item sm={ 1 } xs={ 1 } className="tw-text-center" style={ {borderColor: us_themeColors.calendarColorDark }} sx={{ borderBottom: 1, borderLeft: 1 }}>
						<Box component='div' className="tw-p-2" style={{ background: us_themeColors.calendarColorMain }}>
							<Typography variant="body2">
								<strong>{ s_WED }</strong>
							</Typography>
						</Box>
					</Grid>
					<Grid item sm={ 1 } xs={ 1 } className="tw-text-center" style={{ borderColor: us_themeColors.calendarColorDark }} sx={{ borderBottom: 1, borderLeft: 1 }}>
						<Box component='div' className="tw-p-2" style={{ background: us_themeColors.calendarColorMain }}>
							<Typography variant="body2">
								<strong>{ s_THU }</strong>
							</Typography>
						</Box>
					</Grid>
					<Grid item sm={ 1 } xs={ 1 } className="tw-text-center" style={{ borderColor: us_themeColors.calendarColorDark }} sx={{ borderBottom: 1, borderLeft: 1 }}>
						<Box component='div' className="tw-p-2" style={{ background: us_themeColors.calendarColorMain }}>
							<Typography variant="body2">
								<strong>{ s_FRI }</strong>
							</Typography>
						</Box>
					</Grid>
					<Grid item sm={ 1 } xs={ 1 } className="tw-text-center" style={{ borderColor: us_themeColors.calendarColorDark }} sx={{ borderBottom: 1, borderLeft: 1 }}>
						<Box component='div' className="tw-p-2" style={{ background: us_themeColors.calendarColorMain }}>
							<Typography variant="body2">
								<strong>{ s_SAT }</strong>
							</Typography>
						</Box>
					</Grid>
				</Grid>
				{calendarVisibleRows.map(( calendarRow, index ) => (
					<Box key={ index }>
						{returnJSX_WeekNumber( calendarRow )}
						<Grid container spacing={ 0 } columns={ 7 }>
							<Grid item sm={ 1 } xs={ 1 } className="tw-text-center" style={{ borderColor: us_themeColors.calendarColorDark }} sx={{ borderBottom: 1 }}>
								{returnJSX_DayContainer( calendarRow[0] )}
							</Grid>
							<Grid item sm={ 1 } xs={ 1 } className="tw-text-center" style={{ borderColor: us_themeColors.calendarColorDark }} sx={{ borderBottom: 1, borderLeft: 1 }}>
								{returnJSX_DayContainer( calendarRow[1] )}
							</Grid>
							<Grid item sm={ 1 } xs={ 1 } className="tw-text-center" style={{ borderColor: us_themeColors.calendarColorDark }} sx={{ borderBottom: 1, borderLeft: 1 }}>
								{returnJSX_DayContainer( calendarRow[2] )}
							</Grid>
							<Grid item sm={ 1 } xs={ 1 } className="tw-text-center" style={{ borderColor: us_themeColors.calendarColorDark }} sx={{ borderBottom: 1, borderLeft: 1 }}>
								{returnJSX_DayContainer( calendarRow[3] )}
							</Grid>
							<Grid item sm={ 1 } xs={ 1 } className="tw-text-center" style={{ borderColor: us_themeColors.calendarColorDark }} sx={{ borderBottom: 1, borderLeft: 1 }}>
								{returnJSX_DayContainer( calendarRow[4] )}
							</Grid>
							<Grid item sm={ 1 } xs={ 1 } className="tw-text-center" style={{ borderColor: us_themeColors.calendarColorDark }} sx={{ borderBottom: 1, borderLeft: 1 }}>
								{returnJSX_DayContainer( calendarRow[5] )}
							</Grid>
							<Grid item sm={ 1 } xs={ 1 } className="tw-text-center" style={{ borderColor: us_themeColors.calendarColorDark }} sx={{ borderBottom: 1, borderLeft: 1 }}>
								{returnJSX_DayContainer( calendarRow[6] )}
							</Grid>
						</Grid>
					</Box>
				))}
			</Card>
			return calendarJSX
		}

		// Render
		return <>{ returnJSX_Component() }</>
	}
