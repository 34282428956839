///////////////////////////////
// Description
///////////////////////////////

	/*
		DESCRIPTION / USAGE:
			containers are pages / views used in the app and are made up of components and can interact with services and models

		TODO:

	*/


///////////////////////////////
// Imports
///////////////////////////////

import React, {
	useEffect
} from 'react'
import {
	Trans
} from 'react-i18next'
import {
	AuthenticatedContainer
} from 'rfbp_aux/containers/authenticated_container'
import {
	ApplicationPages
} from 'rfbp_aux/data/application_structure'
import {
	TsType_JSX,
	TsType_String
} from 'rfbp_core/typescript/global_types'
import {
	Box
} from '@mui/material/'

///////////////////////////////
// Typescript
///////////////////////////////



///////////////////////////////
// Variables
///////////////////////////////

	// Authenticated Nav Data
	const pageKey: TsType_String = ApplicationPages["SuperTestLabPage"]["key"]

	// Displayed Translatable Strings
	// { sort-start } - displayed text - scoped sort plugin
	const s_TEST_LAB: TsType_JSX = 			<Trans>Test Lab</Trans>
	// { sort-end } - displayed text


///////////////////////////////
// Functions
///////////////////////////////


///////////////////////////////
// Container
///////////////////////////////

	export const Container: React.FC = (): TsType_JSX => {

		// Props
		// let items2 = 						getProp(props, "items2", {})

		// Hooks - useContext, useState, useReducer, other
		// { sort-start } - hooks
		// const [ us_items, us_setItems ] = 			useState< TsInterface_UnspecifiedObject >( {} )
		// const ur_forceRerender = 				useReducer( () => ( {} ), {} )[1] as () => TsType_Void
		// { sort-end } - hooks

		// Hooks - useEffect
		useEffect(() => {

		}, [])


		// Functions

		// Call Functions

		// JSX Generation

		const returnJSX_Page = (): TsType_JSX => {
			let pageJSX =
			<AuthenticatedContainer pageHeader={ s_TEST_LAB } pageKey={ pageKey } content={
				<Box>

				</Box>
			}/>
			return pageJSX
		}

		// Render
		return <>{ returnJSX_Page() }</>

	}