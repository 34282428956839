//======================================//
//		  ooOOOO BOILERPLATE TEMPLATE   //
//		 oo		 _____					//
//		_I__n_n__||_|| ________			//
//	  >(_________|_7_|-|______|			//
//	   /o ()() ()() o   oo  oo			//
//======================================//

///////////////////////////////
// Description
///////////////////////////////

	/*
		DESCRIPTION / USAGE:
			Page used to manage access to application pages on a client by client basis

		TODO:
			[ ] Typescript - 1 isntance of TsType_Any
			[ ] Typescript - 5 isntances of TsInterface_UnspecifiedObject
			[ ] Bug - Warning: A component is changing an uncontrolled input to be controlled (on checklist)

	*/


///////////////////////////////
// Imports
///////////////////////////////

import {
	useContext,
	useEffect,
	useReducer,
	useState
} from 'react'
import {
	Trans
} from 'react-i18next'
import {
	themeVariables
} from 'rfbp_aux/config/app_theme'
import {
	AuthenticatedContainer
} from 'rfbp_aux/containers/authenticated_container'
import {
	ApplicationPages,
	ClientTypes,
	generateAvailableClientLevelPermissions
} from 'rfbp_aux/data/application_structure'
import {
	DatabaseRef_ClientPermissions_Document,
	DatabaseRef_RootClient_Collection,
	DatabaseRef_RootClient_Document
} from 'rfbp_aux/services/database_endpoints/database_endpoints'
import {
	Json
} from 'rfbp_core/components/code_display'
import {
	Form,
	TsInterface_FormAdditionalData,
	TsInterface_FormData,
	TsInterface_FormHooksObject,
	TsInterface_FormInputs,
	TsInterface_FormSettings,
	TsInterface_FormSubmittedData,
	TsType_FormOnChange
} from 'rfbp_core/components/form'
import {
	Icon
} from 'rfbp_core/components/icons'
import {
	TabsBasic
} from 'rfbp_core/components/tabs'
import {
	Context_UserInterface_ErrorDialog,
	Context_UserInterface_FormDialog
} from 'rfbp_core/services/context'
import {
	DatabaseGetDocument,
	DatabaseGetLiveCollection,
	DatabaseGetLiveDocument,
	DatabaseSetMergeDocument
} from 'rfbp_core/services/database_management'
import {
	objectToArray
} from 'rfbp_core/services/helper_functions'
import {
	TsInterface_GenericPromiseReject,
	TsInterface_GenericPromiseResolve,
	TsInterface_UnspecifiedObject,
	TsType_Any,
	TsType_Boolean,
	TsType_JSX,
	TsType_String,
	TsType_Void,
	TsType_VoidFunction
} from 'rfbp_core/typescript/global_types'
import {
	Box,
	Button,
	Card,
	Checkbox,
	FormControl,
	FormControlLabel,
	FormGroup,
	InputLabel,
	MenuItem,
	Select,
	SelectChangeEvent,
	Stack,
	Typography
} from '@mui/material/'

///////////////////////////////
// Typescript
///////////////////////////////


///////////////////////////////
// Variables
///////////////////////////////

	// Authenticated Nav Data
	const pageKey = ApplicationPages["SuperClientPermissionsManagementPage"]["key"]

	// Displayed Translatable Strings
	// { sort-start } - displayed text - scoped sort plugin
	const s_CLIENT_KEY = 											<Trans>Client Key</Trans>
	const s_CLIENT_KEY_ALREADY_USED = 								<Trans>Client key already used</Trans>
	const s_CLIENT_NAME = 											<Trans>Client Name</Trans>
	const s_CLIENT_PERMISSIONS = 									<Trans>Client Permissions</Trans>
	const s_CLIENT_TYPE = 											<Trans>Client Type</Trans>
	const s_CREATE_NEW_CLIENT = 									<Trans>Create new Client</Trans>
	const s_FAILED_TO_CREATE_CLIENT = 								<Trans>Failed to create client</Trans>
	const s_JSON = 													<Trans>JSON</Trans>
	const s_PERMISSIONS = 											<Trans>Permissions</Trans>
	const s_SELECT_A_CLIENT_TO_EDIT_PERMISSIONS = 					<Trans>Select a client to edit permissions</Trans>
	const s_SELECT_CLIENT = 										<Trans>Select Client</Trans>
	const s_THE_SELECTED_CLIENT_IS_MISSING_A_VALID_CLIENT_TYPE = 	<Trans>The selected client is missing a valid client type</Trans>
	const s_UPDATE_CLIENT_TYPE = 									<Trans>Update Client Type</Trans>
	// { sort-end } - displayed text

	// Tables



///////////////////////////////
// Functions
///////////////////////////////


///////////////////////////////
// Container
///////////////////////////////

	export const Container: React.FC = (): TsType_JSX => {

		// Props

		// Hooks - useContext, useState, useReducer, other
		// { sort-start } - hooks
		const [ us_clientLevelPermissions, us_setClientLevelPermissions ] = 				useState< TsInterface_UnspecifiedObject >( {} )
		const [ us_clientsList, us_setClientsList ] = 										useState< TsInterface_UnspecifiedObject >( {} )
		const [ us_formData_ClientType, us_setFormData_ClientType ] = 						useState< TsInterface_UnspecifiedObject >( {} )
		const [ us_selectedClientKey, us_setSelectedClientKey ] = 							useState< TsType_String >( "" )
		const ur_forceRerender = 															useReducer( () => ( {} ), {} )[1] as () => TsType_Void
		const { uc_setUserInterface_ErrorDialogDisplay } = 									useContext( Context_UserInterface_ErrorDialog )
		const { uc_setUserInterface_FormDialogDisplay } = 									useContext( Context_UserInterface_FormDialog )
		// { sort-end } - hooks

		// Hooks - useEffect
 		useEffect(() => {
			// Get Client Level Permissions
			us_setClientLevelPermissions( generateAvailableClientLevelPermissions() )
			return () => {}
		}, [])

		useEffect(() => {
			let unsubscribeLiveData: TsType_VoidFunction
			const updateLiveData = ( newData: TsInterface_UnspecifiedObject ) => {
				us_setClientsList( newData )
				ur_forceRerender()
			}
			unsubscribeLiveData = DatabaseGetLiveCollection( DatabaseRef_RootClient_Collection( ), updateLiveData )
			return () => {
				if (typeof unsubscribeLiveData === 'function'){
					unsubscribeLiveData()
				}
			}
		}, [ ur_forceRerender ])

		useEffect(() => {
			let unsubscribeLiveData: TsType_VoidFunction
			const updateLiveData = ( newData: TsInterface_UnspecifiedObject ) => {
				us_setFormData_ClientType( newData )
				ur_forceRerender()
			}
			if ( us_selectedClientKey != null && us_selectedClientKey !== "" ) {
				unsubscribeLiveData = DatabaseGetLiveDocument( DatabaseRef_ClientPermissions_Document( us_selectedClientKey ), updateLiveData )
			}
			return () => {
				if (typeof unsubscribeLiveData === 'function'){
					unsubscribeLiveData()
				}
			}
		}, [ ur_forceRerender, us_selectedClientKey ])


		// Other Variables
		let formAdditionalData_ClientType: TsInterface_FormAdditionalData = {}

		let formInputs_ClientType: TsInterface_FormInputs = {
			client_type: {
				data_type: "string",
				input_type: "multiple_choice_radio",
				key: "client_type",
				label: s_CLIENT_TYPE,
				options: [],
				required: true,
			},
		}

		let formSettings_ClientType: TsInterface_FormSettings = {
			highlight_missing: true,
			submit_button_alignment: "left",
			submit_button_saving_icon: true,
			submit_button_text: s_UPDATE_CLIENT_TYPE,
			submit_button_theme: "primary"
		}

		// Functions
		const returnClientTypeFormOptions = (): TsInterface_UnspecifiedObject[] => {
			let options = []
			for ( let clientTypeKey in ClientTypes ){
				options.push({ key: clientTypeKey, value: ClientTypes[ clientTypeKey ]["name"]} )
			}
			// formInputs_ClientType["client_type"]["options"] = options
			return options
		}

		const selectClientKey = ( event: SelectChangeEvent< TsType_Any > ): TsType_Void => {
			us_setSelectedClientKey( event.target.value )
			ur_forceRerender()
		}

		const togglePermission = ( event: React.ChangeEvent<HTMLInputElement>, permissionKey: TsType_String, permissionValue: TsType_String | TsType_Boolean ): TsType_Void => {
			let permissionUpdateObject: TsInterface_UnspecifiedObject = {}
			permissionUpdateObject[ permissionKey ] = permissionValue
			DatabaseSetMergeDocument( DatabaseRef_ClientPermissions_Document( us_selectedClientKey ), permissionUpdateObject, {}).then(( res_DSMD ) => {
				DatabaseGetDocument( DatabaseRef_ClientPermissions_Document( us_selectedClientKey ) ).then(( res_DGD ) => {
					us_setFormData_ClientType( res_DGD.data )
				})
			}).catch(( rej_DSMD ) => {
				console.error( rej_DSMD )
			})
		}

		const formOnChange_ClientType: TsType_FormOnChange = ( formAdditionalData, formData, formInputs, formSettings ) => {
			// Nothing
		}

		const formSubmission_ClientType = ( submittedFormData: TsInterface_UnspecifiedObject, formAdditionalData: TsInterface_UnspecifiedObject ): Promise< TsInterface_GenericPromiseReject | TsInterface_GenericPromiseResolve > => {
			return new Promise( ( resolve, reject ) => {
				DatabaseSetMergeDocument( DatabaseRef_ClientPermissions_Document( us_selectedClientKey ), submittedFormData, {} ).then(( res_DSMD ) => {
					ur_forceRerender()
					resolve( res_DSMD )
				}).catch(( rej_DSMD ) => {
					console.error( rej_DSMD )
					reject( rej_DSMD )
				})
			})
		}

		const createNewClient = ( ): TsType_Void => {
			uc_setUserInterface_FormDialogDisplay({
				display: true,
				form: {
					form: {
						formAdditionalData: {},
						formData: {},
						formInputs: {
							key: {
								key: "key",
								label: s_CLIENT_KEY,
								input_type: "text_basic",
								required: true,
								data_type: "string",
							},
							name: {
								key: "name",
								label: s_CLIENT_NAME,
								input_type: "text_basic",
								required: true,
								data_type: "string",
							},
						},
						formOnChange: ( formAdditionalData: TsInterface_FormAdditionalData, formData: TsInterface_FormData, formInputs: TsInterface_FormInputs, formSettings: TsInterface_FormSettings ) => {},
						formSettings: {},
						formSubmission: ( formSubmittedData: TsInterface_FormSubmittedData, formAdditionalData: TsInterface_FormAdditionalData, formHooks: TsInterface_FormHooksObject ) => {
							return new Promise( ( resolve, reject ) => {
								DatabaseGetDocument( DatabaseRef_RootClient_Document( formSubmittedData.key ) ).then( (res_DGD) => {
									if ( res_DGD != null && res_DGD.data != null && res_DGD.data.key != null ) {
										uc_setUserInterface_ErrorDialogDisplay({ display: true, error: {
											message: s_FAILED_TO_CREATE_CLIENT,
											details: s_CLIENT_KEY_ALREADY_USED,
											code: "ER-D-CPM-CNC-01"
										} })
									} else {
										DatabaseSetMergeDocument( DatabaseRef_RootClient_Document( formSubmittedData.key ), formSubmittedData, {} ).then((res_DSMD) => {
											// TODO - Snackbar
											us_setSelectedClientKey( formSubmittedData.key )
											resolve(res_DSMD)
										}).catch((rej_DSDM) => {
											reject(rej_DSDM)
											uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSDM.error })
										})
									}
								}).catch((rej_DGD) => {
									DatabaseSetMergeDocument( DatabaseRef_RootClient_Document( formSubmittedData.key ), formSubmittedData, {} ).then((res_DSMD) => {
										// TODO - Snackbar
										us_setSelectedClientKey( formSubmittedData.key )
										resolve(res_DSMD)
									}).catch((rej_DSDM) => {
										reject(rej_DSDM)
										uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSDM.error })
									})
								})
							})
						},
					},
					dialog: {
						formDialogHeaderColor: "success",
						formDialogHeaderText: s_CREATE_NEW_CLIENT,
						formDialogIcon: <Icon icon="pen-to-square" />
					}
				}
			})
		}

		// Call Functions
		formInputs_ClientType["client_type"]["options"] = returnClientTypeFormOptions()

		// JSX Generation
		const returnJSX_ClientToggleDropdown = (): TsType_JSX => {
			let clientToggleDropdownJSX =
			<Box>
				<Stack spacing={ 1 } direction="row">
					<FormControl sx={{ minWidth: "200px", background: themeVariables.background_paper }}>
						<InputLabel id="select_client_dropdown">{ s_SELECT_CLIENT }</InputLabel>
						<Select
							labelId="select_client_dropdown"
							value={ us_selectedClientKey }
							label={ s_SELECT_CLIENT }
							onChange={ selectClientKey }
						>
							{objectToArray( us_clientsList ).map(( client ) => (
								<MenuItem value={ client.key } key={ client.key }>{ client.name }</MenuItem>
							))}
						</Select>
					</FormControl>
					<Button
						color="success"
						onClick={ () => { createNewClient() } }
						variant="contained"
					>
						<Icon icon="circle-plus" className="tw-pr-1"/>
						{ s_CREATE_NEW_CLIENT }
					</Button>
				</Stack>
			</Box>
			return clientToggleDropdownJSX
		}

		const returnJSX_PermissionChecklist = (): TsType_JSX => {
			let clientPermissionsJSX: TsType_JSX = <Box></Box>
			if (us_selectedClientKey != null && us_selectedClientKey !== ""){
				if ( us_formData_ClientType != null && us_formData_ClientType.client_type != null && us_clientLevelPermissions != null && us_clientLevelPermissions[ us_formData_ClientType.client_type ] != null ){
					clientPermissionsJSX =
					<Box className="tw-text-left tw-p-4">
						{objectToArray( us_clientLevelPermissions[ us_formData_ClientType.client_type ] ).map(( section ) => (
							<Box key={ section.sectionKey }>
								<Typography variant="h6" noWrap component="div">{ section.sectionName }</Typography>
								<FormGroup>
									{objectToArray( section.permissions ).map(( permission ) => (
										<Box key={ permission.key }>
											<FormControlLabel
												control={
													<Checkbox checked={ us_formData_ClientType[ permission.key ] || false } onChange={ ( event, value ) => { togglePermission(event, permission.key, value ) } } />
												}
												key={ permission.key }
												label={ permission.pageName }
											/>
										</Box>
									))}
								</FormGroup>
							</Box>
						))}
					</Box>
				} else {
					clientPermissionsJSX =
					<Box className="tw-text-center tw-p-4">
						<Typography variant="h5" noWrap component="div">{ s_THE_SELECTED_CLIENT_IS_MISSING_A_VALID_CLIENT_TYPE }</Typography>
					</Box>
				}
			} else {
				clientPermissionsJSX =
				<Box className="tw-text-center tw-p-4">
					<Typography variant="h5" noWrap component="div">{ s_SELECT_A_CLIENT_TO_EDIT_PERMISSIONS }</Typography>
				</Box>
			}
			return clientPermissionsJSX
		}

		const returnJSX_ClientTypeForm = (): TsType_JSX => {
			let clientTypeJSX: TsType_JSX = <Box></Box>
			// Client Type Selection
			if ( us_selectedClientKey != null && us_selectedClientKey !== "" ){
				clientTypeJSX =
				<Card className="tw-text-left tw-p-4" variant="outlined">
					<Form
						formAdditionalData={ formAdditionalData_ClientType }
						formData={ us_formData_ClientType }
						formInputs={ formInputs_ClientType }
						formOnChange={ formOnChange_ClientType }
						formSettings={ formSettings_ClientType }
						formSubmission={ formSubmission_ClientType }
					/>
				</Card>
			}
			return clientTypeJSX
		}

		const returnJSX_Page = (): TsType_JSX => {
			// Page JSX
			let pageJSX =
			<AuthenticatedContainer pageHeader={ s_CLIENT_PERMISSIONS } pageKey={ pageKey } content={
				<Box>
					<Box className="tw-my-4">
						{ returnJSX_ClientToggleDropdown() }
					</Box>
					<TabsBasic tabsSettings={ {} } tabs={[
						{
							tabHeader: s_PERMISSIONS,
							tabContent:
							<Box>
								<Card variant="outlined">
									{ returnJSX_ClientTypeForm() }
									{ returnJSX_PermissionChecklist() }
								</Card>
							</Box>
						},
						{
							tabHeader: s_JSON,
							tabContent:
							<Box>
								<Json data={ us_formData_ClientType } alphebetized={ true }/>
							</Box>
						},
					]} />
				</Box>
			}/>
			return pageJSX
		}

		// Render
		return <>{ returnJSX_Page() }</>
	}