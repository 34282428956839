//////////////////////////////////////////
//		  ooOOOO BOILERPLATE FILE		//
//		 oo		 _____					//
//		_I__n_n__||_|| ________			//
//	  >(_________|_7_|-|______|			//
//	   /o ()() ()() o   oo  oo			//
//////////////////////////////////////////

///////////////////////////////
// Description
///////////////////////////////

	/*
		DESCRIPTION / USAGE:
			Renders a card that shows formatted Html

		TODO:

	*/

	// ESLINT OVERRIDES:
		/*eslint no-useless-concat: "off"*/
		/*eslint no-useless-escape: "off"*/


///////////////////////////////
// Imports
///////////////////////////////

import {
	themeVariables
} from 'rfbp_aux/config/app_theme' // OUTSIDE BOILERPLATE
import {
	TsType_JSX,
	TsType_String,
	TsType_Undefined,
	TsType_Void
} from 'rfbp_core/typescript/global_types'
import {
	Card
} from '@mui/material/'

///////////////////////////////
// Typescript
///////////////////////////////

	interface TsInterface_ComponentProps {
		data: TsType_String
	}


///////////////////////////////
// Variables
///////////////////////////////

	// Displayed Translatable Strings
	// { sort-start } - displayed text - scoped sort plugin

	// { sort-end } - displayed text

	// Other
	let PR: TsType_Undefined = undefined

	// TODO - change the color scheme here

	let htmlCss: TsType_String = `
		pre.html_view {
			background: ` + themeVariables.background_json + ` !important;
			color: ` + themeVariables.white + `;
			text-align: left;
			padding: 6px;
			font-size: 14px;
			margin: 0px;
		}

		pre .str, code .str { color: ` + themeVariables.success_light + `; } /* string - green */
		pre .kwd, code .kwd { color: ` + themeVariables.error_light + `; } /* keyword - dark pink */
		pre .com, code .com { color: ` + themeVariables.gray_700 + `; font-style: italic; } /* comment - gray */
		pre .typ, code .typ { color: ` + themeVariables.primary_light + `; } /* type - light blue */
		pre .lit, code .lit { color: ` + themeVariables.primary_light + `; } /* literal - blue */
		pre .pun, code .pun { color: ` + themeVariables.white + `; } /* punctuation - white */
		pre .pln, code .pln { color: ` + themeVariables.white + `; } /* plaintext - white */
		pre .tag, code .tag { color: ` + themeVariables.primary_light + `; } /* html/xml tag - light blue */
		pre .atn, code .atn { color: ` + themeVariables.warning_light + `; } /* html/xml attribute name - khaki */
		pre .atv, code .atv { color: ` + themeVariables.success_light + `; } /* html/xml attribute value - green */
		pre .dec, code .dec { color: ` + themeVariables.primary_light + `; } /* decimal - blue */

		pre.prettyprint, code.prettyprint {
			background: ` + themeVariables.background_json + ` !important;
		}

		pre.prettyprint {
			margin: auto;
			padding: 1em;
			white-space: pre-wrap;
		}


		/* Specify class=linenums on a pre to get line numbering */
		ol.linenums { margin-top: 0; margin-bottom: 0; color: #AEAEAE; } /* IE indents via margin-left */
		li.L0,li.L1,li.L2,li.L3,li.L5,li.L6,li.L7,li.L8 { list-style-type: none }
		/* Alternate shading for lines */
		li.L1,li.L3,li.L5,li.L7,li.L9 { }

		@media print {
			pre .str, code .str { color: #060; }
			pre .kwd, code .kwd { color: #006; font-weight: bold; }
			pre .com, code .com { color: #600; font-style: italic; }
			pre .typ, code .typ { color: #404; font-weight: bold; }
			pre .lit, code .lit { color: #044; }
			pre .pun, code .pun { color: #440; }
			pre .pln, code .pln { color: #000; }
			pre .tag, code .tag { color: #006; font-weight: bold; }
			pre .atn, code .atn { color: #404; }
			pre .atv, code .atv { color: #060; }
		}
	`


///////////////////////////////
// Functions
///////////////////////////////

	const replaceMarkupTags = ( string: TsType_String ): TsType_String => {
		let cleanString: TsType_String = string.replace(/&/g, "&amp").replace(/</g, "&lt").replace(/>/g, "&gt")
		return cleanString
	}

	const getHeadOrBody = (): HTMLHeadElement => {
		return document.getElementsByTagName( 'head' )[0] || document.getElementsByTagName( 'body' )[0]
	}

	const codePrettify = (): TsType_Void => {
		// Check if PR object already exists
		if ( typeof PR !== 'undefined' ) {
			// PR.prettyPrint()
		} else {
			// Inject if not
			getHeadOrBody().appendChild(
				Object.assign(
					document.createElement('script'),
					{
						type: 'text/javascript',
						async: true,
						src: 'https://cdn.rawgit.com/google/code-prettify/master/loader/run_prettify.js'
					}
				)
			)
		}
	}


///////////////////////////////
// Component
///////////////////////////////

	export const Html = ( props: TsInterface_ComponentProps ): TsType_JSX => {

		// Props
		let pr_data: TsInterface_ComponentProps["data"] = 		props.data

		// Hooks - useContext, useState, useReducer, other
		// { sort-start } - hooks

		// { sort-end } - hooks

		// Hooks - useEffect

		// Other Variables
		let html: TsType_String = replaceMarkupTags( pr_data )

		// Functions


		codePrettify()

		// JSX Generation
		const returnJSX_Component = (): TsType_JSX => {
			let componentJSX: TsType_JSX =
			<div>
				<Card variant="outlined" style={{"fontFamily": "monospace !important"}}>
					<pre className="html_view prettyprint">
						<div dangerouslySetInnerHTML={{ __html: html}}></div>
					</pre>
				</Card>
				<style>{ htmlCss }</style>
			</div>
			return componentJSX
		}

		// Render
		return <>{ returnJSX_Component() }</>
	}
