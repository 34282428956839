//======================================//
//		  ooOOOO USED BY BOILERPLATE    //
//		 oo		 _____					//
//		_I__n_n__||_|| ________			//
//	  >(_________|_7_|-|______|			//
//	   /o ()() ()() o   oo  oo			//
//======================================//

///////////////////////////////
// Description
///////////////////////////////

	/*
		DESCRIPTION / USAGE:
			1) Defines all valid URLs and which containers they are linked to
			2) Specifies the global theme/style of the application
			3) Creates live database listeners for user and client information
			4) Generates application permissions based on user and client information
			5) Triggers a rerender when global user, client user, or permissions are updated
			6) Makes user/client info and other global state variables available via context
			7) Provides the physical location where error messages are rendered

			ADDING A NEW PAGE
				1) Create file in containers folder
				2) Import page into "App.tsx" (this file)
				3) Add Route into the Router in "App.tsx" at the bottom (this file)
				4) Add Page into the "ApplicationPages" variable of "applicationStructure.tsx"
				5) IF the page is a root level page, add it to ApplicationNavPages (and ApplicationNavSections if it is in a new section) in "applicationStructure.tsx"

		TODO:
			[ ] Typescript - 1 instance of @ts-expect-error - combined contexts
			[ ] Typescript - 4 instances of TsType_Any

	*/


///////////////////////////////
// Imports
///////////////////////////////

import 'app/styles/app.css'
import 'rfbp_core/styles/main.css'
import {
	Container as BoilerplateDocsPage
} from 'app/pages/boilerplate_docs/boilerplate_docs'
import {
	Container as GeoguesserPage
} from 'app/pages/geoguesser/geoguesser'
import {
	Container as HomePage
} from 'app/pages/home/home'
import {
	Container as UserSettingsPage
} from 'app/pages/home/user_settings'
import {
	Container as PirateGameDesktopGamePage
} from 'app/pages/pirate_game/pirate_desktop_game'
import {
	Container as PirateGameDesktopLobbyPage
} from 'app/pages/pirate_game/pirate_desktop_lobby'
import {
	Container as PirateGameDesktopStartPage
} from 'app/pages/pirate_game/pirate_desktop_start'
import {
	Container as PirateGameMobileGamePage
} from 'app/pages/pirate_game/pirate_mobile_game'
import {
	Container as PirateGameMobileJoinPage
} from 'app/pages/pirate_game/pirate_mobile_join'
import {
	Container as UnauthenticatedLinksPage
} from 'app/pages/unauthenticated/links'
import {
	Container as UnauthenticatedLoginPage
} from 'app/pages/unauthenticated/login'
import {
	getAuth,
	onAuthStateChanged
} from 'firebase/auth'
import React, {
	useEffect,
	useReducer,
	useState
} from 'react'
import {
	BrowserRouter,
	Navigate,
	Route,
	Routes
} from 'react-router-dom'
import {
	DefaultAppTheme
} from 'rfbp_aux/config/app_theme'
import {
	ApplicationPages
} from 'rfbp_aux/data/application_structure'
import {
	Container as SuperClientPermissionsManagementPage
} from 'rfbp_aux/pages/super/client_permissions_management'
import {
	Container as SuperTestLabPage
} from 'rfbp_aux/pages/super/test_lab'
import {
	DatabaseRef_ClientPermissions_Document,
	DatabaseRef_GlobalUser_Document
} from 'rfbp_aux/services/database_endpoints/database_endpoints'
import {
	AlertDialog,
	ConfirmDialog,
	CustomDialog,
	ErrorDialog,
	FormDialog,
	PromptDialog,
	SnackbarDisplay
} from 'rfbp_core/components/dialog'
import {
	ScrollToTop
} from 'rfbp_core/components/utilities/scroll_to_top'
import {
	CombinedAppContext,
	RootData_Default_AuthenticatedUserState,
	RootData_Default_ClientKeyState,
	RootData_Default_ClientPermissionsState,
	RootData_Default_ClientUserState,
	RootData_Default_GlobalUserState,
	RootData_Default_UserPermissionsState,
	TsInterface_RootData_AuthenticatedUser,
	TsInterface_RootData_ClientPermissions,
	TsInterface_RootData_ClientUser,
	TsInterface_RootData_GlobalUser,
	TsInterface_RootData_UserPermissions,
	TsInterface_UserInterface_AlertDialogDisplay,
	TsInterface_UserInterface_ConfirmDialogDisplay,
	TsInterface_UserInterface_ConfirmDialogInternalState,
	TsInterface_UserInterface_CustomDialogDisplay,
	TsInterface_UserInterface_ErrorDialogDisplay,
	TsInterface_UserInterface_FormDialogDisplay,
	TsInterface_UserInterface_FormDialogInternalState,
	TsInterface_UserInterface_NavBadges,
	TsInterface_UserInterface_PromptDialogDisplay,
	TsInterface_UserInterface_PromptDialogInternalState,
	TsInterface_UserInterface_SnackbarDisplay,
	TsType_RootData_ClientKey,
	TsType_UserInterface_LoadingBarDisplay,
	TsType_UserInterface_NavBarDisplay,
	UserInterface_Default_AlertDialogDisplayState,
	UserInterface_Default_ConfirmDialogDisplayState,
	UserInterface_Default_ConfirmDialogInternalState,
	UserInterface_Default_CustomDialogDisplayState,
	UserInterface_Default_ErrorDialogDisplayState,
	UserInterface_Default_FormDialogDisplayState,
	UserInterface_Default_FormDialogInternalState,
	UserInterface_Default_LoadingBarDisplayState,
	UserInterface_Default_NavBarDisplayState,
	UserInterface_Default_PromptDialogDisplayState,
	UserInterface_Default_PromptDialogInternalState,
	UserInterface_Default_SnackbarDisplayState
} from 'rfbp_core/services/context'
import {
	DatabaseGetLiveDocument
} from 'rfbp_core/services/database_management'
import {
	TsType_Any,
	TsType_JSX,
	TsType_Null,
	TsType_String,
	TsType_Void
} from 'rfbp_core/typescript/global_types'
import CssBaseline from '@mui/material/CssBaseline'
import {
	StyledEngineProvider,
	ThemeProvider
} from '@mui/material/styles'

///////////////////////////////
// Typescript
///////////////////////////////

	interface TsInterface_ClientPermissionsLiveData {
		[ propKey: TsType_String ]: TsType_Any
		// TODO
	}

	interface TsInterface_ClientUserLiveData {
		[ propKey: TsType_String ]: TsType_Any
		// TODO
	}

	interface TsInterface_GlobalUserLiveData {
		[ propKey: TsType_String ]: TsType_Any
		// TODO
	}

	type TsType_DismissFunction = (obj?: TsType_Any) => TsType_Void


///////////////////////////////
// Variables
///////////////////////////////

	// Nav Badges
	let defaultNavBadgeCounts = {
		counts: {}
	}

///////////////////////////////
// Functions
///////////////////////////////


///////////////////////////////
// Exports
///////////////////////////////

	function App() {

		// Hooks - useContext, useState, useReducer, other
		// { sort-start } - hooks
		const [ uc_AppData_UrlHistory, 						uc_setAppData_UrlHistory ] = 						useState< TsType_String[] >( [] )
		const [ uc_RootData_AuthenticatedUser, 				uc_setRootData_AuthenticatedUser ] = 				useState< TsInterface_RootData_AuthenticatedUser >( RootData_Default_AuthenticatedUserState )
		const [ uc_RootData_ClientKey, 						uc_setRootData_ClientKey ] = 						useState< TsType_RootData_ClientKey >( RootData_Default_ClientKeyState )
		const [ uc_RootData_ClientPermissions, 				uc_setRootData_ClientPermissions ] = 				useState< TsInterface_RootData_ClientPermissions >( RootData_Default_ClientPermissionsState )
		const [ uc_RootData_ClientUser, 					uc_setRootData_ClientUser ] = 						useState< TsInterface_RootData_ClientUser >( RootData_Default_ClientUserState )
		const [ uc_RootData_GlobalUser, 					uc_setRootData_GlobalUser ] = 						useState< TsInterface_RootData_GlobalUser >( RootData_Default_GlobalUserState )
		const [ uc_RootData_UserPermissions, 				uc_setRootData_UserPermissions ] = 					useState< TsInterface_RootData_UserPermissions >( RootData_Default_UserPermissionsState )
		const [ uc_UserInterface_AlertDialogDisplay, 		uc_setUserInterface_AlertDialogDisplay ] = 			useState< TsInterface_UserInterface_AlertDialogDisplay >( UserInterface_Default_AlertDialogDisplayState )
		const [ uc_UserInterface_ConfirmDialogAction, 		uc_setUserInterface_ConfirmDialogAction ] = 		useState< TsInterface_UserInterface_ConfirmDialogInternalState >( UserInterface_Default_ConfirmDialogInternalState )
		const [ uc_UserInterface_ConfirmDialogDisplay, 		uc_setUserInterface_ConfirmDialogDisplay ] = 		useState< TsInterface_UserInterface_ConfirmDialogDisplay >( UserInterface_Default_ConfirmDialogDisplayState )
		const [ uc_UserInterface_CustomDialogDisplay, 		uc_setUserInterface_CustomDialogDisplay ] = 		useState< TsInterface_UserInterface_CustomDialogDisplay >( UserInterface_Default_CustomDialogDisplayState )
		const [ uc_UserInterface_ErrorDialogDisplay, 		uc_setUserInterface_ErrorDialogDisplay ] = 			useState< TsInterface_UserInterface_ErrorDialogDisplay >( UserInterface_Default_ErrorDialogDisplayState )
		const [ uc_UserInterface_FormDialogAction, 			uc_setUserInterface_FormDialogAction ] = 			useState< TsInterface_UserInterface_FormDialogInternalState >( UserInterface_Default_FormDialogInternalState )
		const [ uc_UserInterface_FormDialogDisplay, 		uc_setUserInterface_FormDialogDisplay ] = 			useState< TsInterface_UserInterface_FormDialogDisplay >( UserInterface_Default_FormDialogDisplayState )
		const [ uc_UserInterface_LoadingBarDisplay, 		uc_setUserInterface_LoadingBarDisplay ] = 			useState< TsType_UserInterface_LoadingBarDisplay >( UserInterface_Default_LoadingBarDisplayState )
		const [ uc_UserInterface_NavBadges, 				uc_setUserInterface_NavBadges ] = 					useState< TsInterface_UserInterface_NavBadges >( defaultNavBadgeCounts )
		const [ uc_UserInterface_NavBarDisplay, 			uc_setUserInterface_NavBarDisplay ] = 				useState< TsType_UserInterface_NavBarDisplay >( UserInterface_Default_NavBarDisplayState )
		const [ uc_UserInterface_PromptDialogAction, 		uc_setUserInterface_PromptDialogAction ] = 			useState< TsInterface_UserInterface_PromptDialogInternalState >( UserInterface_Default_PromptDialogInternalState )
		const [ uc_UserInterface_PromptDialogDisplay, 		uc_setUserInterface_PromptDialogDisplay ] = 		useState< TsInterface_UserInterface_PromptDialogDisplay >( UserInterface_Default_PromptDialogDisplayState )
		const [ uc_UserInterface_SnackbarDisplay, 			uc_setUserInterface_SnackbarDisplay ] = 			useState< TsInterface_UserInterface_SnackbarDisplay >( UserInterface_Default_SnackbarDisplayState )
		const [ uc_rootData_AuthenticatedUserKey, 			uc_setRootData_AuthenticatedUserKey ] = 			useState< TsType_String | TsType_Null >( null )
		const ur_forceRerender = 																				useReducer(() => ({}), {})[1] as () => TsType_Void
		// { sort-end } - hooks


		// Hooks - useEffect
		useEffect(() => {
			// Create listeners to user and client database locations and generate application permissions
			// Create variables for database unsubscribers
			let databaseClientPermissionsUnsubscribe: TsType_DismissFunction
			let databaseClientUserUnsubscribe: TsType_DismissFunction
			let databaseGlobalUserUnsubscribe: TsType_DismissFunction
			let databaseUnassignedProjectsUnsubscribe: TsType_DismissFunction
			let databaseUnreadNotificationsUnsubscribe: TsType_DismissFunction
			let databaseUnreadMessagesUnsubscribe: TsType_DismissFunction
			let databaseOpenTasksUnsubscribe: TsType_DismissFunction
			let databaseUnreadRemindersUnsubscribe: TsType_DismissFunction
			if ( uc_rootData_AuthenticatedUserKey != null ) {
				// Set user authentication to global state
				uc_setRootData_AuthenticatedUser( { loggedIn: true, uid: uc_rootData_AuthenticatedUserKey } )

				// Callback function to set client permissions to global state
				const clientPermissionsLiveDataUpdate = ( clientPermissionsLiveData: TsInterface_ClientPermissionsLiveData ) => {
					uc_setRootData_ClientPermissions( clientPermissionsLiveData as TsInterface_RootData_ClientPermissions )
				}

				// Callback function to set client user to global state
				const clientUserLiveDataUpdate = ( clientUserLiveData: TsInterface_ClientUserLiveData ) => {
					uc_setRootData_ClientUser( clientUserLiveData as TsInterface_RootData_ClientUser )
				}

				// Callback funtion to set global client to global state and susequently load client level info
				const globalUserLiveDataUpdate = ( globalUserLiveData: TsInterface_GlobalUserLiveData ) => {
					// Set Global User and Client Key
					uc_setRootData_GlobalUser( globalUserLiveData as TsInterface_RootData_GlobalUser )
					if ( globalUserLiveData != null && globalUserLiveData.client_key != null ){
						uc_setRootData_ClientKey( globalUserLiveData.client_key )
					}
					// Create Database Listener for Client User
					if ( globalUserLiveData != null && globalUserLiveData.client_key != null && uc_rootData_AuthenticatedUserKey != null ){
						databaseClientUserUnsubscribe = DatabaseGetLiveDocument( DatabaseRef_GlobalUser_Document( uc_rootData_AuthenticatedUserKey ), clientUserLiveDataUpdate )
					}
					// Create Database Listener for Client Permissions
					if ( globalUserLiveData != null && globalUserLiveData.client_key != null ){
						databaseClientPermissionsUnsubscribe = DatabaseGetLiveDocument( DatabaseRef_ClientPermissions_Document( globalUserLiveData.client_key ), clientPermissionsLiveDataUpdate )
					}
				}
				// Create Database Listener for Global User
				databaseGlobalUserUnsubscribe = DatabaseGetLiveDocument( DatabaseRef_GlobalUser_Document( uc_rootData_AuthenticatedUserKey ), globalUserLiveDataUpdate )

			} else {
				// If the user is not authenticated then wipe all user and client related data in global state
				uc_setRootData_AuthenticatedUser( RootData_Default_AuthenticatedUserState )
				uc_setRootData_ClientPermissions( RootData_Default_ClientPermissionsState )
				uc_setRootData_ClientUser( RootData_Default_ClientUserState )
				uc_setRootData_GlobalUser( RootData_Default_GlobalUserState )
			}
			return () => {
				if ( typeof databaseClientPermissionsUnsubscribe === 'function' ){ 			databaseClientPermissionsUnsubscribe() }
				if ( typeof databaseClientUserUnsubscribe === 'function' ){ 				databaseClientUserUnsubscribe() }
				if ( typeof databaseGlobalUserUnsubscribe === 'function' ){ 				databaseGlobalUserUnsubscribe() }
				if ( typeof databaseUnassignedProjectsUnsubscribe === 'function' ){ 		databaseUnassignedProjectsUnsubscribe() }
				if ( typeof databaseUnreadNotificationsUnsubscribe === 'function' ){ 		databaseUnreadNotificationsUnsubscribe() }
				if ( typeof databaseUnreadMessagesUnsubscribe === 'function' ){ 			databaseUnreadMessagesUnsubscribe() }
				if ( typeof databaseOpenTasksUnsubscribe === 'function' ){ 					databaseOpenTasksUnsubscribe() }
				if ( typeof databaseUnreadRemindersUnsubscribe === 'function' ){ 			databaseUnreadRemindersUnsubscribe() }
			}
		}, [ ur_forceRerender, uc_rootData_AuthenticatedUserKey ])

		useEffect(() => {
			let authenticatedUnsubscribe: TsType_DismissFunction
			// Verify if user is authenticated
			authenticatedUnsubscribe = onAuthStateChanged(getAuth(), ( user ) => {
				if ( user && user.uid != null ) {
					uc_setRootData_AuthenticatedUserKey( user.uid )
				}
			})
			return () => {
				// Close database listeners on component unmount - I don't think this gets used in production (just on hot reload during development)
				if ( typeof authenticatedUnsubscribe === 'function' ){ 						authenticatedUnsubscribe() }
			}
		}, [ ur_forceRerender ])

		// Context
		let Context_AppData_UrlHistory_Passthrough = 					{ uc_AppData_UrlHistory, 						uc_setAppData_UrlHistory }
		let Context_RootData_AuthenticatedUser_Passthrough = 			{ uc_RootData_AuthenticatedUser, 				uc_setRootData_AuthenticatedUser }
		let Context_RootData_ClientKey_Passthrough = 					{ uc_RootData_ClientKey, 						uc_setRootData_ClientKey }
		let Context_RootData_ClientPermissions_Passthrough = 			{ uc_RootData_ClientPermissions, 				uc_setRootData_ClientPermissions }
		let Context_RootData_ClientUser_Passthrough = 					{ uc_RootData_ClientUser, 						uc_setRootData_ClientUser }
		let Context_RootData_GlobalUser_Passthrough = 					{ uc_RootData_GlobalUser, 						uc_setRootData_GlobalUser }
		let Context_RootData_UserPermissions_Passthrough = 				{ uc_RootData_UserPermissions, 					uc_setRootData_UserPermissions }
		let Context_UserInterface_AlertDialog_Passthrough = 			{ uc_UserInterface_AlertDialogDisplay, 			uc_setUserInterface_AlertDialogDisplay }
		let Context_UserInterface_ConfirmDialog_Passthrough = 			{ uc_UserInterface_ConfirmDialogDisplay, 		uc_setUserInterface_ConfirmDialogDisplay }
		let Context_UserInterface_CustomDialog_Passthrough = 			{ uc_UserInterface_CustomDialogDisplay, 		uc_setUserInterface_CustomDialogDisplay }
		let Context_UserInterface_ErrorDialog_Passthrough = 			{ uc_UserInterface_ErrorDialogDisplay, 			uc_setUserInterface_ErrorDialogDisplay }
		let Context_UserInterface_FormDialog_Passthrough = 				{ uc_UserInterface_FormDialogDisplay, 			uc_setUserInterface_FormDialogDisplay }
		let Context_UserInterface_LoadingBar_Passthrough = 				{ uc_UserInterface_LoadingBarDisplay, 			uc_setUserInterface_LoadingBarDisplay }
		let Context_UserInterface_NavBadges_Passthrough = 				{ uc_UserInterface_NavBadges, 					uc_setUserInterface_NavBadges }
		let Context_UserInterface_NavBar_Passthrough = 					{ uc_UserInterface_NavBarDisplay, 				uc_setUserInterface_NavBarDisplay }
		let Context_UserInterface_PromptDialog_Passthrough = 			{ uc_UserInterface_PromptDialogDisplay, 		uc_setUserInterface_PromptDialogDisplay }
		let Context_UserInterface_Snackbar_Passthrough = 				{ uc_UserInterface_SnackbarDisplay, 			uc_setUserInterface_SnackbarDisplay }

		// Handle Dialogs
		let alertDialogJSX: TsType_JSX = 		<></>
		let confirmDialogJSX: TsType_JSX = 		<></>
		let customDialogJSX: TsType_JSX = 		<></>
		let errorDialogJSX: TsType_JSX = 		<></>
		let formDialogJSX: TsType_JSX = 		<></>
		let promptDialogJSX: TsType_JSX = 		<></>
		let snackbarJSX: TsType_JSX = 			<></>

		// Alerts
		const potentiallyRenderAlertDialog = () => {
			if ( uc_UserInterface_AlertDialogDisplay.display === true ){
				alertDialogJSX = AlertDialog(
					uc_UserInterface_AlertDialogDisplay.alert,
					() => { uc_setUserInterface_AlertDialogDisplay( UserInterface_Default_AlertDialogDisplayState ) }
				)
			}
		}
		potentiallyRenderAlertDialog()

		// Confirms
		const potentiallyRenderConfirmDialog = () => {
			if ( uc_UserInterface_ConfirmDialogDisplay.display === true ){
				confirmDialogJSX = ConfirmDialog(
					uc_UserInterface_ConfirmDialogDisplay.confirm,
					() => { uc_setUserInterface_ConfirmDialogDisplay( UserInterface_Default_ConfirmDialogDisplayState ) },
					uc_UserInterface_ConfirmDialogAction,
					uc_setUserInterface_ConfirmDialogAction
				)
			}
		}

		potentiallyRenderConfirmDialog()

		// Custom Dialog
		const potentiallyRenderCustomDialog = () => {
			if ( uc_UserInterface_CustomDialogDisplay.display === true ){
				customDialogJSX = CustomDialog(
					uc_UserInterface_CustomDialogDisplay.dialog,
					() => { uc_setUserInterface_CustomDialogDisplay( UserInterface_Default_CustomDialogDisplayState ) },
				)
			}
		}

		potentiallyRenderCustomDialog()

		// Errors
		const potentiallyRenderErrorDialog = () => {
			if ( uc_UserInterface_ErrorDialogDisplay.display === true ){
				errorDialogJSX = ErrorDialog(
					uc_UserInterface_ErrorDialogDisplay.error,
					() => { uc_setUserInterface_ErrorDialogDisplay( UserInterface_Default_ErrorDialogDisplayState ) },
				)
			}
		}

		potentiallyRenderErrorDialog()

		// Forms
		const potentiallyRenderFormDialog = () => {
			if ( uc_UserInterface_FormDialogDisplay.display === true ){
				formDialogJSX = FormDialog(
					uc_UserInterface_FormDialogDisplay.form,
					() => { uc_setUserInterface_FormDialogDisplay( UserInterface_Default_FormDialogDisplayState ) },
					uc_UserInterface_FormDialogAction,
					uc_setUserInterface_FormDialogAction
				)
			}
		}

		potentiallyRenderFormDialog()

		// Prompts
		const potentiallyRenderPromptDialog = () => {
			if ( uc_UserInterface_PromptDialogDisplay.display === true ){
				promptDialogJSX = PromptDialog(
					uc_UserInterface_PromptDialogDisplay.prompt,
					() => { uc_setUserInterface_PromptDialogDisplay( UserInterface_Default_PromptDialogDisplayState ) },
					uc_UserInterface_PromptDialogAction,
					uc_setUserInterface_PromptDialogAction
				)
			}
		}

		potentiallyRenderPromptDialog()

		// Snackbars
		const potentiallyRenderSnackbar = () => {
			snackbarJSX = SnackbarDisplay(
				uc_UserInterface_SnackbarDisplay.display,
				uc_UserInterface_SnackbarDisplay.snackbar,
				() => {
					let copiedSnackbarDisplay = JSON.parse(JSON.stringify(uc_UserInterface_SnackbarDisplay))
					copiedSnackbarDisplay.display = false
					uc_setUserInterface_SnackbarDisplay( copiedSnackbarDisplay )
					// uc_setUserInterface_SnackbarDisplay( UserInterface_Default_SnackbarDisplayState )
				}
			)
		}

		potentiallyRenderSnackbar()

		// Render
		return (
			<StyledEngineProvider injectFirst>
				{/* @ts-expect-error */}
				<CombinedAppContext.Provider value={{
					Context_AppData_UrlHistory_Passthrough,
					Context_RootData_AuthenticatedUser_Passthrough,
					Context_RootData_ClientKey_Passthrough,
					Context_RootData_ClientPermissions_Passthrough,
					Context_RootData_ClientUser_Passthrough,
					Context_RootData_GlobalUser_Passthrough,
					Context_RootData_UserPermissions_Passthrough,
					Context_UserInterface_AlertDialog_Passthrough,
					Context_UserInterface_ConfirmDialog_Passthrough,
					Context_UserInterface_CustomDialog_Passthrough,
					Context_UserInterface_ErrorDialog_Passthrough,
					Context_UserInterface_FormDialog_Passthrough,
					Context_UserInterface_LoadingBar_Passthrough,
					Context_UserInterface_NavBadges_Passthrough,
					Context_UserInterface_NavBar_Passthrough,
					Context_UserInterface_PromptDialog_Passthrough,
					Context_UserInterface_Snackbar_Passthrough,
				}}>
					<ThemeProvider theme={DefaultAppTheme}>
						<CssBaseline />
						<BrowserRouter>
							<ScrollToTop />
							{alertDialogJSX}
							{confirmDialogJSX}
							{customDialogJSX}
							{errorDialogJSX}
							{formDialogJSX}
							{promptDialogJSX}
							{snackbarJSX}
							<Routes>
								<Route path={ ApplicationPages.UnauthenticatedLinksPage.url() } 					element={ <UnauthenticatedLinksPage/> } 									/>
								<Route path={ ApplicationPages.UnauthenticatedLoginPage.url() } 					element={ <UnauthenticatedLoginPage/> } 									/>
								<Route path={ ApplicationPages.HomePage.url() } 									element={ <HomePage/> } 													/>
								<Route path={ ApplicationPages.UserSettingsPage.url() } 							element={ <UserSettingsPage/> } 											/>
								<Route path={ ApplicationPages.GeoguesserPage.url() } 								element={ <GeoguesserPage/> } 												/>
								<Route path={ ApplicationPages.PirateGameDesktopStartPage.url() } 					element={ <PirateGameDesktopStartPage/> } 									/>
								<Route path={ ApplicationPages.PirateGameDesktopLobbyPage.url(":id" ) } 			element={ <PirateGameDesktopLobbyPage/> } 									/>
								<Route path={ ApplicationPages.PirateGameDesktopGamePage.url(":id" ) } 				element={ <PirateGameDesktopGamePage/> } 									/>
								<Route path={ ApplicationPages.PirateGameMobileJoinPage.url(":id" ) } 				element={ <PirateGameMobileJoinPage/> } 									/>
								<Route path={ ApplicationPages.PirateGameMobileGamePage.url(":id", ":uid" ) } 		element={ <PirateGameMobileGamePage/> } 									/>
								<Route path={ ApplicationPages.SuperClientPermissionsManagementPage.url() } 		element={ <SuperClientPermissionsManagementPage/> } 						/>
								<Route path={ ApplicationPages.SuperTestLabPage.url() } 							element={ <SuperTestLabPage/> } 											/>
								<Route path={ ApplicationPages.BoilerplateDocsPage.url() } 							element={ <BoilerplateDocsPage/> } 											/>
								<Route path="*" 																	element={ <Navigate to={ApplicationPages.HomePage.url()} replace /> } 		/>
							</Routes>
						</BrowserRouter>
					</ThemeProvider>
				</CombinedAppContext.Provider>
			</StyledEngineProvider>
		)
	}

	export default App