//////////////////////////////////////////
//		  ooOOOO BOILERPLATE FILE		//
//		 oo		 _____					//
//		_I__n_n__||_|| ________			//
//	  >(_________|_7_|-|______|			//
//	   /o ()() ()() o   oo  oo			//
//////////////////////////////////////////

///////////////////////////////
// Description
///////////////////////////////

	/*
		DESCRIPTION / USAGE:
			Provides easy to use

		TODO:

	*/


///////////////////////////////
// Imports
///////////////////////////////

import {
	TsInterface_TableAdditionalData,
	TsInterface_TableColumn,
	TsInterface_TableDataRow,
	TsInterface_TableHooks,
	TsType_TableSortBy
} from 'rfbp_core/components/table'
import {
	returnFormattedPhoneNumberString
} from 'rfbp_core/services/helper_functions'
import {
	TsType_JSX,
	TsType_String
} from 'rfbp_core/typescript/global_types'

///////////////////////////////
// Typescript
///////////////////////////////


///////////////////////////////
// Variables
///////////////////////////////

	// Displayed Translatable Strings
	// { sort-start } - displayed text - scoped sort plugin

	// { sort-end } - displayed text


///////////////////////////////
// Functions
///////////////////////////////


///////////////////////////////
// Exports
///////////////////////////////

	export const TableCellPhone = ( propKey: TsType_String, propName: TsType_String | TsType_JSX, sortBy: TsType_TableSortBy ): TsInterface_TableColumn => {
		let basicCell = {
			header: {
				header_jsx: ( tableAdditionalData: TsInterface_TableAdditionalData ) => {
					return <>{ propName }</>
				},
				header_sort_by: sortBy
			},
			cell: {
				cell_jsx: ( rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks ) => {
					return <>{ returnFormattedPhoneNumberString( rowData[propKey] as TsType_String ) }</>
				},
			}
		}
		return basicCell
	}