//////////////////////////////////////////
//		  ooOOOO BOILERPLATE FILE		//
//		 oo		 _____					//
//		_I__n_n__||_|| ________			//
//	  >(_________|_7_|-|______|			//
//	   /o ()() ()() o   oo  oo			//
//////////////////////////////////////////

///////////////////////////////
// Description
///////////////////////////////

	/*
		DESCRIPTION / USAGE:


		TODO:
		Probably ,move out of boilerplate and make this whole file a custom param for chat header settings

	*/


///////////////////////////////
// Imports
///////////////////////////////

import {
	useContext,
	useEffect,
	useReducer,
	useState
} from 'react'
import {
	Trans
} from 'react-i18next'
import {
	themeVariables
} from 'rfbp_aux/config/app_theme'
import {
	DatabaseRef_MessageThread_Document
} from 'rfbp_aux/services/database_endpoints/database_endpoints'
import {
	TsInterface_InputHooksObject
} from 'rfbp_core/components/form/form_types'
import {
	Icon
} from 'rfbp_core/components/icons'
import {
	returnJSX_HighlightedSearchString,
	SearchInput
} from 'rfbp_core/components/search'
import {
	Context_RootData_ClientKey,
	Context_UserInterface_ConfirmDialog,
	Context_UserInterface_CustomDialog,
	Context_UserInterface_ErrorDialog,
	UserInterface_Default_CustomDialogDisplayState
} from 'rfbp_core/services/context'
import {
	DatabaseGetLiveDocument,
	DatabaseSetMergeDocument,
	DatabaseUpdateDocument
} from 'rfbp_core/services/database_management'
import {
	getProp,
	objectToArray
} from 'rfbp_core/services/helper_functions'
import {
	getClientKey
} from 'rfbp_core/services/user_authentication'
import {
	TsInterface_UnspecifiedObject,
	TsType_JSX,
	TsType_Null,
	TsType_Number,
	TsType_String,
	TsType_Void,
	TsType_VoidFunction
} from 'rfbp_core/typescript/global_types'
import {
	AppBar,
	Box,
	Button,
	Dialog,
	DialogContent,
	Divider,
	IconButton,
	Toolbar,
	Typography
} from '@mui/material/'

///////////////////////////////
// Typescript
///////////////////////////////

	interface TsInterface_ChatMembershipEditDialog {
		chatThreadKey: TsType_String
		chatThread: TsInterface_UnspecifiedObject
		// chatThreadEndpoint: TsType_Any
	}


///////////////////////////////
// Variables
///////////////////////////////

	// Displayed Translatable Strings
	// { sort-start } - displayed text - scoped sort plugin
	const s_ADD_USER = 																	<Trans>Add User</Trans>
	const s_ARE_YOU_SURE_THAT_YOU_WANT_TO_REMOVE_THIS_CUSTOM_THREAD_PARTICIPANT = 		<Trans>Are you sure that you want to remove this custom thread participant?</Trans>
	const s_AUTOMATIC_THREAD_MEMBERS = 													<Trans>Automatic Thread Members</Trans>
	const s_CUSTOM_THREAD_MEMBERS = 													<Trans>Custom Thread Members</Trans>
	const s_DELETE = 																	<Trans>Delete</Trans>
	const s_DELETE_CUSTOM_THREAD_MEMBER = 												<Trans>Delete Custom Thread Member</Trans>
	const s_EDIT_MESSAGE_THREAD_MEMBERSHIP = 											<Trans>Edit message thread membership</Trans>
	const s_EMAIL = 																	<Trans>Email</Trans>
	const s_PHONE = 																	<Trans>Phone</Trans>
	const s_UPDATED_TO_REFLECT_TEAM_ASSIGNMENTS_EACH_TIME_A_MESSAGE_IS_SENT = 			<Trans>Updated to reflect team assignments each time a message is sent</Trans>
	const s_USER = 																		<Trans>User</Trans>
	// { sort-end } - displayed text


///////////////////////////////
// Functions
///////////////////////////////


///////////////////////////////
// Component
///////////////////////////////

	export const ChatMembershipEditCustomDialog: React.FC<TsInterface_ChatMembershipEditDialog> = ({ chatThreadKey, chatThread }): TsType_JSX => {

		// Props

		// Hooks - useContext, useState, useReducer, other
		// { sort-start } - hooks
		const [ us_selectedChatThread, us_setSelectedChatThread ] = 						useState<TsInterface_UnspecifiedObject>( chatThread )
		const [ us_selectedCustomThreadMembers, us_setSelectedCustomThreadMembers ] = 		useState<TsInterface_UnspecifiedObject>( {} )
		const [ us_selectedDefaultThreadMembers, us_setSelectedDefaultThreadMembers ] = 	useState<TsInterface_UnspecifiedObject>( {} )
		const ur_forceRerender = 															useReducer(() => ({}), {})[1] as () => TsType_Void
		const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = 						useContext( Context_RootData_ClientKey )
		const { uc_setUserInterface_ConfirmDialogDisplay } = 								useContext( Context_UserInterface_ConfirmDialog )
		const { uc_setUserInterface_CustomDialogDisplay } = 								useContext( Context_UserInterface_CustomDialog )
		const { uc_setUserInterface_ErrorDialogDisplay } = 									useContext( Context_UserInterface_ErrorDialog )
		// { sort-end } - hooks

		// Hooks - useEffect
		useEffect(() => {
			let unsubscribeLiveData: TsType_VoidFunction
			const updateLiveData = ( newData: TsInterface_UnspecifiedObject ) => {
				us_setSelectedChatThread( newData )
				ur_forceRerender()
			}
			getClientKey( uc_RootData_ClientKey, uc_setRootData_ClientKey ).then(( res_GCK ) => {

				// TODO - pass endpoint in settings probably

				unsubscribeLiveData = DatabaseGetLiveDocument( DatabaseRef_MessageThread_Document( res_GCK.clientKey, chatThreadKey ), updateLiveData )
			}).catch(( rej_GCK ) => {
				console.error( rej_GCK )
			})
			return () => {
				if (typeof unsubscribeLiveData === 'function'){
					unsubscribeLiveData()
				}
			}
		}, [ uc_RootData_ClientKey, ur_forceRerender, uc_setRootData_ClientKey, chatThreadKey ])

		useEffect(() => {
			let TEMP_customThreadMembers: TsInterface_UnspecifiedObject = {}
			if( us_selectedChatThread != null && us_selectedChatThread.additional_thread_members != null ){
				for( let loopUserKey in us_selectedChatThread.additional_thread_members ){
					let loopUserName = us_selectedChatThread.additional_thread_members[ loopUserKey ]
					TEMP_customThreadMembers[ loopUserKey ] = {
						key: loopUserKey,
						name: loopUserName,
					}
				}
			}
			us_setSelectedCustomThreadMembers( TEMP_customThreadMembers )
			let TEMP_defaultThreadMembers: TsInterface_UnspecifiedObject = {}
			if( us_selectedChatThread != null && us_selectedChatThread.default_thread_members != null ){
				for( let loopUserKey in us_selectedChatThread.default_thread_members ){
					let loopUserName = us_selectedChatThread.default_thread_members[ loopUserKey ]
					TEMP_defaultThreadMembers[ loopUserKey ] = {
						key: loopUserKey,
						name: loopUserName,
					}
				}
			}
			us_setSelectedDefaultThreadMembers( TEMP_defaultThreadMembers )
		}, [ us_selectedChatThread, us_setSelectedCustomThreadMembers, us_setSelectedDefaultThreadMembers ])

		// Other Variables

		// Functions

		// JSX Generation
		const returnJSX_ThreadMember = ( member: TsInterface_UnspecifiedObject ): TsType_JSX => {
			let memberJSX = <></>
			if(
				member != null &&
				member.key != null &&
				getProp( us_selectedCustomThreadMembers, member.key, null ) == null
			){
				memberJSX =
				<Typography  variant="subtitle1">
					<Box className="tw-inline-block" sx={{ color: themeVariables.primary_main }}>
						<Icon icon="user" className="tw-mr-2"></Icon>
					</Box>
					<Box className="tw-inline-block">{ member.name }</Box>
				</Typography>
			}
			return memberJSX
		}

		const returnJSX_SearchResultWithStatus = (
			option: TsInterface_UnspecifiedObject,
			searchInputValue: TsType_String | TsType_Null,
			inputHooks: TsInterface_InputHooksObject,
			additionalSearchData: TsInterface_UnspecifiedObject,
		): TsType_JSX => {
			let searchResultJSX =
			<Box sx={{ marginLeft: "8px",  marginRight: "8px"  }}>
				<Typography>{ s_USER }: { returnJSX_HighlightedSearchString( searchInputValue, option.name ) } </Typography>
				<Typography>{ s_EMAIL }: { returnJSX_HighlightedSearchString( searchInputValue, option.email ) } </Typography>
				<Typography>{ s_PHONE }: { returnJSX_HighlightedSearchString( searchInputValue, option.phone ) } </Typography>
				<Button
					color={ "info" }
					onClick={ () => {
						if ( option.id != null ){
							getClientKey( uc_RootData_ClientKey, uc_setRootData_ClientKey ).then(( res_GCK ) => {

								// setSearchSelectedUserKey( option.id )


								let updateObject: TsInterface_UnspecifiedObject = {
									additional_thread_members: {}
								}
								updateObject["additional_thread_members"][ option.id ] = option.name

								// TODO - pass endpoint in settings probably

								DatabaseSetMergeDocument( DatabaseRef_MessageThread_Document( res_GCK.clientKey, chatThreadKey ), updateObject, {} ).then((res_DSMD) => {
									inputHooks.us_setSearchInputValue(null)
								}).catch((rej_DSMD) => {
									uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
								})


							}).catch(( rej_GCK ) => {
								uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
							})
						}
					} }
					variant="contained"
					sx={{ marginBottom: "5px" }}
				>
					<Icon icon="magnifying-glass" />
					{ s_ADD_USER }
				</Button>
				<Divider />
			</Box>
			return searchResultJSX
		}

		const returnJSX_MeilisearchSearchInput = (): TsType_JSX => {
			let inputJSX = <></>
			if( uc_RootData_ClientKey != null ){
				inputJSX =
				<SearchInput
					clientKey={ uc_RootData_ClientKey }
					searchIndexKey={ "users" }
					searchFilters={ [ "user_role != customer" ] }
					searchResultRenderer={ returnJSX_SearchResultWithStatus }
					additionalSearchData={ {} }
				/>

			}
			return inputJSX
		}

		const returnJSX_Dialog = (): TsType_JSX => {
			let dialogJSX =
			<Box>
				<Dialog
					className="bp_dialog_xl_width"
					keepMounted
					onClose={ () => {
						uc_setUserInterface_CustomDialogDisplay( UserInterface_Default_CustomDialogDisplayState )
					} }
					open={ true }
				>
					<AppBar position="static" color="inherit" sx={{ backgroundColor: themeVariables.info_main }}>
						<Toolbar>
							<IconButton
								aria-label="menu"
								color="inherit"
								disabled
								edge="start"
								size="large"
								sx={{ mr: 2, color: "#fff !important" }}
							>
								<Icon icon="users" />
							</IconButton>
							<Typography component={'span'} variant={ 'h6' } sx={{ flexGrow: 1 }}>
								<Box className="tw-inline-block">
									{ s_EDIT_MESSAGE_THREAD_MEMBERSHIP } - { us_selectedChatThread.thread_name_override_desktop }
								</Box>
							</Typography>
						</Toolbar>
					</AppBar>
					<DialogContent sx={{ padding: "0px" }}>
						<Box className="tw-p-4">
							<Box>
								<Typography variant="h5" className="">{ s_AUTOMATIC_THREAD_MEMBERS }</Typography>
								<Typography variant="body2" className="tw-mb-2 tw-opacity-30 tw-ml-4">*{ s_UPDATED_TO_REFLECT_TEAM_ASSIGNMENTS_EACH_TIME_A_MESSAGE_IS_SENT }</Typography>
								<Box className="tw-ml-4">
									{ objectToArray( us_selectedDefaultThreadMembers ).map(( member: TsInterface_UnspecifiedObject, memberIndex: TsType_Number ) => (
										<Box key={ memberIndex }>
											{ returnJSX_ThreadMember( member ) }
										</Box>
									))}
								</Box>
							</Box>
							<Box>
								<Typography variant="h5" className="tw-mb-2 tw-mt-6">{ s_CUSTOM_THREAD_MEMBERS }</Typography>
								<Box className="tw-ml-4">
									{ objectToArray( us_selectedCustomThreadMembers ).map(( member: TsInterface_UnspecifiedObject, memberIndex: TsType_Number ) => (
										<Typography  variant="subtitle1" key={ memberIndex }>
											<Box className="tw-inline-block" sx={{ color: themeVariables.warning_dark }}>
												<Icon icon="user" className="tw-mr-2"></Icon>
											</Box>
											<Box className="tw-inline-block">{ member.name }</Box>
											<Box className="tw-inline-block" sx={{ color: themeVariables.error_main }} onClick={ () => {
												uc_setUserInterface_ConfirmDialogDisplay({
													display: true,
													confirm: {
														color: "error",
														header: s_DELETE_CUSTOM_THREAD_MEMBER,
														icon: <Icon icon="trash" type="solid"/>,
														submit_text: s_DELETE,
														text: s_ARE_YOU_SURE_THAT_YOU_WANT_TO_REMOVE_THIS_CUSTOM_THREAD_PARTICIPANT,
														submit_callback: () => {
															return new Promise( ( resolve, reject ) => {
																getClientKey( uc_RootData_ClientKey, uc_setRootData_ClientKey ).then(( res_GCK ) => {
																	let updateObject: TsInterface_UnspecifiedObject = { additional_thread_members: getProp( us_selectedChatThread, "additional_thread_members", {} ) }
																	if(
																		updateObject != null &&
																		updateObject["additional_thread_members"] != null &&
																		updateObject["additional_thread_members"][ member.key ] != null
																	){
																		delete updateObject["additional_thread_members"][ member.key ]
																	}


																	// TODO - pass endpoint in settings probably


																	DatabaseUpdateDocument( DatabaseRef_MessageThread_Document( res_GCK.clientKey, chatThreadKey ), updateObject, {} ).then((res_DUD) => {
																		resolve(res_DUD)
																	}).catch((rej_DUD) => {
																		uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DUD.error })
																		resolve(rej_DUD)
																	})
																}).catch(( rej_GCK ) => {
																	uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
																	reject(rej_GCK)
																})
															})
														},
													}
												})
											}}>
												<Icon icon="trash" className="tw-ml-2 tw-cursor-pointer"></Icon>
											</Box>
										</Typography>
									))}
								</Box>
								<Box className="tw-ml-4 tw-mt-2">
									{ returnJSX_MeilisearchSearchInput() }
								</Box>
							</Box>
						</Box>
					</DialogContent>
				</Dialog>
			</Box>
			return dialogJSX
		}

		return <>{returnJSX_Dialog()}</>
	}