//////////////////////////////////////////
//		  ooOOOO BOILERPLATE FILE		//
//		 oo		 _____					//
//		_I__n_n__||_|| ________			//
//	  >(_________|_7_|-|______|			//
//	   /o ()() ()() o   oo  oo			//
//////////////////////////////////////////

///////////////////////////////
// Description
///////////////////////////////

	/*
		DESCRIPTION / USAGE:
			Chat Display Component

		TODO:
			[ ] Manage Dark Theme

	*/


///////////////////////////////
// Imports
///////////////////////////////

import {
	CSSProperties,
	useEffect,
	useState
} from 'react'
import {
	themeVariables
} from 'rfbp_aux/config/app_theme' // OUTSIDE BOILERPLATE
import {
	stringAvatar,
	TsInterface_ChatItem,
	TsInterface_ChatMessages,
	TsInterface_ChatMessageSettings,
	TsInterface_ChatThread
} from 'rfbp_core/components/chat'
import {
	dynamicSort,
	getProp,
	millisecondsPerDay,
	objectToArray,
	returnFormattedDate,
	returnFormattedDateKey,
	returnTimestampFromUnknownDateFormat
} from 'rfbp_core/services/helper_functions'
import {
	TsType_JSX,
	TsType_Null,
	TsType_Number,
	TsType_String
} from 'rfbp_core/typescript/global_types'
import {
	Avatar,
	Box,
	Divider,
	Typography
} from '@mui/material/'
import {
	stringAvatarWithSpecificColor
} from './chat_helper_functions'

///////////////////////////////
// Typescript
///////////////////////////////

	interface TsInterface_ComponentProps {
		chatThread: TsInterface_ChatThread
		chatMessages: TsInterface_ChatMessages
		chatSettings: TsInterface_ChatMessageSettings
	}


///////////////////////////////
// Variables
///////////////////////////////

	// Displayed Translatable Strings
	// { sort-start } - displayed text - scoped sort plugin

	// { sort-end } - displayed text


///////////////////////////////
// Functions
///////////////////////////////


///////////////////////////////
// Component
///////////////////////////////

	export const ChatMessagesDisplay = ( props: TsInterface_ComponentProps ): TsType_JSX => {

		// Props
		let pr_chatSettings: TsInterface_ComponentProps["chatSettings"] = 				getProp( props, "chatSettings", {} )
		let pr_chatThread: TsInterface_ComponentProps["chatThread"] = 					getProp( props, "chatThread", {} )
		let pr_unformattedChatMessages: TsInterface_ComponentProps["chatMessages"] = 	getProp( props, "chatMessages", {} )

		// Hooks - useContext, useState, useReducer, other
		// { sort-start } - hooks
		const [ us_chatItems, us_setChatItems ] = 			useState< TsInterface_ChatItem[] >( [] )
		const [ us_chatMessages, us_setChatMessages ] = 	useState< TsInterface_ChatMessages >( {} )
		// { sort-end } - hooks

		// Hooks - useEffect
		useEffect(() => {
			let formattedMessages: TsInterface_ChatMessages = {}
			for( let loopMessageKey in pr_unformattedChatMessages){
				let loopMessage = pr_unformattedChatMessages[ loopMessageKey ]
				if( loopMessage["timestamp"] != null ){
					loopMessage["timestamp"] = returnTimestampFromUnknownDateFormat( loopMessage["timestamp"] )
				}
				formattedMessages[ loopMessageKey ] = loopMessage
			}
			us_setChatMessages( formattedMessages )
			return () => { }
		}, [ pr_unformattedChatMessages ])

		useEffect(() => {
			// Instantiate Variables
			let cleanMessageArray: TsInterface_ChatItem[] = []
			let orderedMessages = objectToArray( us_chatMessages ).sort( dynamicSort( "timestamp", null ))
			let previousSender: TsType_String | TsType_Null = null
			let previousTimestamp: TsType_Number = 0
			// Loop through messages
			for ( let orderedMessageIndex in orderedMessages ){
				// Instantiate Loop Variables
				let orderedMessage = orderedMessages[ orderedMessageIndex ]
				orderedMessage["element_type"] = "message"
				orderedMessage["index"] = orderedMessageIndex
				let previousMessageDayKey = returnFormattedDateKey( new Date( previousTimestamp ) )
				let currentMessageDayKey = returnFormattedDateKey( new Date( orderedMessage.timestamp ) )
				// If the message thread is swapping senders, include an avatar on message
				if ( previousSender !== orderedMessage["associated_sender_key"] ){
					orderedMessage["include_avatar"] = true
				}
				// If it's been 15 minutes since previous message, include an avatar on message
				if ( orderedMessage["timestamp"] - ( millisecondsPerDay / 24 / 60 * 15 ) > previousTimestamp ){
					orderedMessage["include_avatar"] = true
				}
				// If day changes between messages, add in a date divider
				if ( previousMessageDayKey !== currentMessageDayKey ){
					cleanMessageArray.push({
						associated_sender_key: "",
						associated_sender_name: "",
						element_type: "timestamp_divider",
						include_avatar: false,
						index: "t" + orderedMessageIndex,
						message: "",
						timestamp: orderedMessage["timestamp"],
					})
					orderedMessage["include_avatar"] = true
				}
				// If the last read timestamp occurs, include a last read divider line

				// Get Unread Count
				let lastReadTimestamp: TsType_Number | TsType_Null = null
				if (
					pr_chatSettings != null &&
					pr_chatSettings["associated_viewer_key"] != null &&
					pr_chatThread != null &&
					pr_chatThread["associated_member_unread_count"] != null &&
					pr_chatThread["associated_member_unread_count"][ pr_chatSettings["associated_viewer_key"] ] != null &&
					!isNaN( pr_chatThread["associated_member_unread_count"][ pr_chatSettings["associated_viewer_key"] ]  )
				){
					lastReadTimestamp = pr_chatThread["associated_member_unread_count"][ pr_chatSettings["associated_viewer_key"] ]
				}
				if ( lastReadTimestamp != null && previousTimestamp < lastReadTimestamp && lastReadTimestamp < orderedMessage["timestamp"] ){
					cleanMessageArray.push({
						associated_sender_key: "",
						associated_sender_name: "",
						element_type: "last_read_divider",
						include_avatar: false,
						index: "u" + orderedMessageIndex,
						message: "",
						timestamp: orderedMessage["timestamp"],
					})
					orderedMessage["include_avatar"] = true
				}
				// Set Previous data for next loop and push message into clean array
				previousSender = orderedMessage["associated_sender_key"]
				previousTimestamp = orderedMessage["timestamp"]
				cleanMessageArray.push( orderedMessage )
			}
			us_setChatItems( cleanMessageArray )
			return () => { }
		}, [us_chatMessages, pr_chatSettings, pr_chatThread])

		// Other Variables
		let senderColor: string = 	getProp( pr_chatSettings["theme_colors"], "sender_color", themeVariables.white )
		let senderLight: string = 	getProp( pr_chatSettings["theme_colors"], "sender_light", themeVariables.info_main )
		let senderDark: string = 	getProp( pr_chatSettings["theme_colors"], "sender_dark", themeVariables.info_dark )
		let otherColor: string = 	getProp( pr_chatSettings["theme_colors"], "other_color", themeVariables.gray_700 ) //
		let otherLight: string = 	getProp( pr_chatSettings["theme_colors"], "other_light", themeVariables.gray_100 ) //
		let otherDark: string = 	getProp( pr_chatSettings["theme_colors"], "other_dark", themeVariables.gray_200 ) //
		let unreadMain: string = 	getProp( pr_chatSettings["theme_colors"], "unraid_main", themeVariables.error_main )

		// Functions
		// const setThemeColors = (): void => {
		// 	if (themeVariables.mode === "dark"){
		// 		otherColor = themeVariables.white
		// 		otherLight = themeVariables.gray_800
		// 		otherDark = themeVariables.gray_900
		// 	} else {
		// 		otherColor = themeVariables.gray_700
		// 		otherLight = themeVariables.gray_100
		// 		otherDark = themeVariables.gray_200
		// 	}
		// }


		// setThemeColors()

		// JSX Generation
		const returnJSX_SenderAvatar = ( chatItem: TsInterface_ChatItem ): TsType_JSX => {
			let avatarJSX = <></>
			if( pr_chatSettings.avatar_color_override == null ){
				avatarJSX = <Avatar {...stringAvatar( chatItem["associated_sender_name"] )} />
			} else {
				avatarJSX = <Avatar {...stringAvatarWithSpecificColor( chatItem["associated_sender_name"], pr_chatSettings.avatar_color_override )} />
			}
			return avatarJSX
		}

		const returnJSX_Message = ( chatItem: TsInterface_ChatItem ): TsType_JSX => {
			// Instantiate Variables
			let messageJSX: TsType_JSX = <></>
			let sx = {
				p: 1,
				border: 1,
				m: 0.5
			}
			let styles: CSSProperties = {}
			let avatarGridStyles = { display: "inline-block", width: "50px"}
			let messageGridStyles = { display: "inline-block", width: "calc(100% - 50px)", verticalAlign: "top"}
			// If the message
			if ( chatItem.element_type === "message" ){
				if ( pr_chatSettings["associated_viewer_key"] === chatItem["associated_sender_key"] ){
					styles.float = "left"
					styles.clear = "both"
					styles.color = senderColor
					styles.borderColor = senderLight
					styles.background = "linear-gradient(to right bottom, " + senderLight + ", " + senderDark + " 120%)"
					styles.borderTopLeftRadius = "0px"
					styles.borderTopRightRadius = "16px"
					styles.borderBottomRightRadius = "16px"
					styles.borderBottomLeftRadius = "16px"
					styles.maxWidth = "85%"
				} else {
					styles.float = "left"
					styles.clear = "both"
					styles.color = otherColor
					styles.borderColor = otherDark
					styles.background = "linear-gradient(to right bottom, " + otherLight + ", " + otherDark + " 120%)"
					styles.borderTopLeftRadius = "0px"
					styles.borderTopRightRadius = "16px"
					styles.borderBottomRightRadius = "16px"
					styles.borderBottomLeftRadius = "16px"
					styles.maxWidth = "85%"
				}
				if ( chatItem.include_avatar ){
					messageJSX =
					<Box className="tw-mt-2">
						<Box style={ avatarGridStyles }>
							{ returnJSX_SenderAvatar( chatItem ) }
						</Box>
						<Box style={ messageGridStyles }>
							<Box>
								<Typography sx={{pl: 1}} style={{display: "inline-block"}} variant="body2">{ chatItem["associated_sender_name"] }</Typography>
								<Typography sx={{pl: 1}} style={{display: "inline-block", opacity: 0.5}} variant="body2">{ returnFormattedDate(chatItem.timestamp, 'h:mm a' ) }</Typography>
							</Box>
							<Box component='div' sx={ sx } style={ styles }>
								<Typography variant="body1">{ chatItem["message"] }</Typography>
							</Box>
						</Box>
					</Box>
				} else {
					messageJSX =
					<Box>
						<Box style={ avatarGridStyles }></Box>
						<Box style={ messageGridStyles }>
							<Box component='div' sx={ sx } style={ styles }>
								<Typography variant="body1">{ chatItem["message"] }</Typography>
							</Box>
						</Box>
					</Box>
				}
			} else if ( chatItem.element_type === "last_read_divider" ) {
				messageJSX =
				<Box className="tw-mt-4 tw-mb-2">
					<Divider
						sx={{
							'&.MuiDivider-root': {
								'&::before': {
									borderTop: `2px solid ${unreadMain}`
								},
								'&::after': {
									borderTop: `2px solid ${unreadMain}`
								}
							}
						}}
						variant="middle"
					>
						<Typography variant="body1" color="error">Unread Messages</Typography>
					</Divider>
				</Box>
			} else if ( chatItem.element_type === "timestamp_divider" ) {
				messageJSX =
				<Box className="tw-mt-4 tw-mb-2">
					<Divider>
						<Typography variant="body1" style={{ opacity: 0.5 }}>{ returnFormattedDate(chatItem.timestamp, 'dddd, D MMMM YYYY' ) }</Typography>
					</Divider>
				</Box>
			}
			return messageJSX
		}

		const returnJSX_Component = (): TsType_JSX => {
			let componentJSX =
			<Box>
				{objectToArray( us_chatItems ).map(( chatItem ) => (
					<Box style={{clear: "both"}} key={ chatItem.index }>
						{returnJSX_Message( chatItem )}
					</Box>
				))}
			</Box>
			return componentJSX
		}

		// Render
		return <>{ returnJSX_Component() }</>
	}