//======================================//
//		  ooOOOO BOILERPLATE TEMPLATE   //
//		 oo		 _____					//
//		_I__n_n__||_|| ________			//
//	  >(_________|_7_|-|______|			//
//	   /o ()() ()() o   oo  oo			//
//======================================//

///////////////////////////////
// Description
///////////////////////////////

	/*
		DESCRIPTION / USAGE:
			the boilerplate docs page showcases a bunch of examples of components so that they can easily be copied and pasted into your project

		TODO:

	*/


///////////////////////////////
// Imports
///////////////////////////////

import React from 'react'
import {
	Trans
} from 'react-i18next'
import {
	themeVariables
} from 'rfbp_aux/config/app_theme'
import {
	AuthenticatedContainer
} from 'rfbp_aux/containers/authenticated_container'
import {
	ApplicationPages
} from 'rfbp_aux/data/application_structure'
import {
	ExampleCalendars
} from 'rfbp_core/components/calendar'
import {
	ExampleChat
} from 'rfbp_core/components/chat'
import {
	ExampleCodeDisplays
} from 'rfbp_core/components/code_display'
import {
	ExampleDataFilter
} from 'rfbp_core/components/data_filter'
import {
	ExampleDialogs
} from 'rfbp_core/components/dialog'
import {
	ExampleForm
} from 'rfbp_core/components/form'
import {
	ExampleMaps
} from 'rfbp_core/components/map'
import {
	ExampleTables
} from 'rfbp_core/components/table'
import {
	ExampleTabs,
	TabsVertical
} from 'rfbp_core/components/tabs'
import {
	ExampleTimeline
} from 'rfbp_core/components/timeline'
import {
	TsType_JSX,
	TsType_String
} from 'rfbp_core/typescript/global_types'
import {
	Box,
	Grid,
	Typography
} from '@mui/material/'

///////////////////////////////
// Typescript
///////////////////////////////


///////////////////////////////
// Variables
///////////////////////////////

	// Authenticated Nav Data
	const pageKey: TsType_String = ApplicationPages["BoilerplateDocsPage"]["key"]

	// Displayed Translatable Strings
	// { sort-start } - displayed text - scoped sort plugin
	// const s_BOILERPLATE: TsType_JSX = 						<Trans>Boilerplate</Trans>
	const s_BOILERPLATE_DOCS: TsType_JSX = 					<Trans>Boilerplate Docs</Trans>
	const s_BREADCRUMBS: TsType_JSX = 						<Trans>Breadcrumbs</Trans>
	const s_CALENDARS: TsType_JSX = 						<Trans>Calendars</Trans>
	const s_CHAT: TsType_JSX = 								<Trans>Chat</Trans>
	const s_CODE_DISPLAY: TsType_JSX = 						<Trans>Code Display</Trans>
	const s_DATA_FILTERS: TsType_JSX = 						<Trans>Data Filters</Trans>
	const s_DIALOGS: TsType_JSX = 							<Trans>Dialogs</Trans>
	const s_DRAWER: TsType_JSX = 							<Trans>Drawer</Trans>
	const s_FORMS: TsType_JSX = 							<Trans>Forms</Trans>
	const s_FORM_EDITOR: TsType_JSX = 						<Trans>Form Editor</Trans>
	const s_FORUM: TsType_JSX = 							<Trans>Forum</Trans>
	const s_LIST: TsType_JSX = 								<Trans>List</Trans>
	const s_LOGIC_EDITOR: TsType_JSX = 						<Trans>Logic Editor</Trans>
	const s_MAPS: TsType_JSX = 								<Trans>Maps</Trans>
	const s_NO_CONTENT_YET: TsType_JSX = 					<Trans>No Content Yet</Trans>
	const s_PDF_TEMPLATE: TsType_JSX = 						<Trans>PDF Template</Trans>
	const s_SPREADSHEET_TEMPLATE: TsType_JSX = 				<Trans>Spreadsheet Template</Trans>
	const s_STEPPER: TsType_JSX = 							<Trans>Stepper</Trans>
	const s_STRIPE_PAYMENTS: TsType_JSX = 					<Trans>Stripe Payments</Trans>
	const s_TABLES: TsType_JSX = 							<Trans>Tables</Trans>
	const s_TABS: TsType_JSX = 								<Trans>Tabs</Trans>
	const s_THEME: TsType_JSX = 							<Trans>Theme</Trans>
	const s_TIMELINE: TsType_JSX = 							<Trans>Timeline</Trans>
	// { sort-end } - displayed text

///////////////////////////////
// Functions
///////////////////////////////


///////////////////////////////
// Container
///////////////////////////////

	export const Container: React.FC = (): TsType_JSX => {

		// Props

		// Hooks - useContext, useState, useReducer, other
		// { sort-start } - hooks
		// { sort-end } - hooks

		// Hooks - useEffect

		// Other Variables
		const header_Breadcrumbs =
		<Box component="span" className="tw-opacity-30 tw-whitespace-nowrap2">
			<Typography className="tw-font-bold tw-inline-block tw-align-top tw-normal-case">{ s_BREADCRUMBS }</Typography>
		</Box>
		const header_Calendars =
		<Box component="span" className="tw-whitespace-nowrap2">
			<Typography className="tw-font-bold tw-inline-block tw-align-top tw-normal-case">{ s_CALENDARS }</Typography>
		</Box>
		const header_Chat =
		<Box component="span" className="tw-whitespace-nowrap2">
			<Typography className="tw-font-bold tw-inline-block tw-align-top tw-normal-case">{ s_CHAT }</Typography>
		</Box>
		const header_CodeDisplay =
		<Box component="span" className="tw-whitespace-nowrap2">
			<Typography className="tw-font-bold tw-inline-block tw-align-top tw-normal-case">{ s_CODE_DISPLAY }</Typography>
		</Box>
		const header_DataFilters =
		<Box component="span" className="tw-whitespace-nowrap2">
			<Typography className="tw-font-bold tw-inline-block tw-align-top tw-normal-case">{ s_DATA_FILTERS }</Typography>
		</Box>
		const header_Dialogs =
		<Box component="span" className="tw-whitespace-nowrap2">
			<Typography className="tw-font-bold tw-inline-block tw-align-top tw-normal-case">{ s_DIALOGS }</Typography>
		</Box>
		const header_Drawer =
		<Box component="span" className="tw-opacity-30 tw-whitespace-nowrap2">
			<Typography className="tw-font-bold tw-inline-block tw-align-top tw-normal-case">{ s_DRAWER }</Typography>
		</Box>
		const header_FormEditor =
		<Box component="span" className="tw-opacity-30 tw-whitespace-nowrap2">
			<Typography className="tw-font-bold tw-inline-block tw-align-top tw-normal-case">{ s_FORM_EDITOR }</Typography>
		</Box>
		const header_Forms =
		<Box component="span" className="tw-whitespace-nowrap2">
			<Typography className="tw-font-bold tw-inline-block tw-align-top tw-normal-case">{ s_FORMS }</Typography>
		</Box>
		const header_Forum =
		<Box component="span" className="tw-opacity-30 tw-whitespace-nowrap2">
			<Typography className="tw-font-bold tw-inline-block tw-align-top tw-normal-case">{ s_FORUM }</Typography>
		</Box>
		const header_List =
		<Box component="span" className="tw-opacity-30 tw-whitespace-nowrap2">
			<Typography className="tw-font-bold tw-inline-block tw-align-top tw-normal-case">{ s_LIST }</Typography>
		</Box>
		const header_LogicEditor =
		<Box component="span" className="tw-opacity-30 tw-whitespace-nowrap2">
			<Typography className="tw-font-bold tw-inline-block tw-align-top tw-normal-case">{ s_LOGIC_EDITOR }</Typography>
		</Box>
		const header_Maps =
		<Box component="span">
			<Typography className="tw-font-bold tw-inline-block tw-align-top tw-normal-case">{ s_MAPS }</Typography>
		</Box>
		const header_PdfTemplateEditor =
		<Box component="span" className="tw-opacity-30 tw-whitespace-nowrap2">
			<Typography className="tw-font-bold tw-inline-block tw-align-top tw-normal-case">{ s_PDF_TEMPLATE }</Typography>
		</Box>
		const header_SpreadsheetTemplateEditor =
		<Box component="span" className="tw-opacity-30 tw-whitespace-nowrap2">
			<Typography className="tw-font-bold tw-inline-block tw-align-top tw-normal-case">{ s_SPREADSHEET_TEMPLATE }</Typography>
		</Box>
		const header_Stepper =
		<Box component="span" className="tw-opacity-30 tw-whitespace-nowrap2">
			<Typography className="tw-font-bold tw-inline-block tw-align-top tw-normal-case">{ s_STEPPER }</Typography>
		</Box>
		const header_StripePayments =
		<Box component="span" className="tw-opacity-30 tw-whitespace-nowrap2">
			<Typography className="tw-font-bold tw-inline-block tw-align-top tw-normal-case">{ s_STRIPE_PAYMENTS }</Typography>
		</Box>
		const header_Tables =
		<Box component="span" className="tw-whitespace-nowrap2">
			<Typography className="tw-font-bold tw-inline-block tw-align-top tw-normal-case">{ s_TABLES }</Typography>
		</Box>
		const header_Tabs =
		<Box component="span" className="tw-whitespace-nowrap2">
			<Typography className="tw-font-bold tw-inline-block tw-align-top tw-normal-case">{ s_TABS }</Typography>
		</Box>
		const header_Timeline =
		<Box component="span" className="tw-whitespace-nowrap2">
			<Typography className="tw-font-bold tw-inline-block tw-align-top tw-normal-case">{ s_TIMELINE }</Typography>
		</Box>
		const header_Theme =
		<Box component="span" className="tw-whitespace-nowrap2">
			<Typography className="tw-font-bold tw-inline-block tw-align-top tw-normal-case">{ s_THEME }</Typography>
		</Box>
		const noContentYesJSX =
		<Box className="tw-p-4 tw-text-center">
			<Typography className="tw-font-bold">{ s_NO_CONTENT_YET }</Typography>
		</Box>

		// Functions

		// Call Functions

		// JSX Generation
		const returnJSX_Page = (): TsType_JSX => {
			let pageJSX =
			<AuthenticatedContainer pageHeader={ s_BOILERPLATE_DOCS } pageKey={ pageKey } content={
				<Box className="">
					<TabsVertical tabsSettings={{ nav_width: "240px" }} tabs={[
						{	tabHeader: header_Breadcrumbs,						tabContent: <Box className="tw-px-3">{ noContentYesJSX }</Box> 					},
						{	tabHeader: header_Calendars,						tabContent: <Box className="tw-px-3"><ExampleCalendars/></Box>					},
						{	tabHeader: header_Chat,								tabContent: <Box className="tw-px-3"><ExampleChat/></Box> 						},
						{	tabHeader: header_CodeDisplay,						tabContent: <Box className="tw-px-3"><ExampleCodeDisplays/></Box>				},
						{ 	tabHeader: header_DataFilters, 						tabContent: <Box className="tw-px-3"><ExampleDataFilter/></Box>					},
						{ 	tabHeader: header_Dialogs, 							tabContent: <Box className="tw-px-3"><ExampleDialogs/></Box>					},
						{	tabHeader: header_Drawer,							tabContent: <Box className="tw-px-3">{ noContentYesJSX }</Box> 					},
						{	tabHeader: header_Forms,							tabContent: <Box className="tw-px-3"><ExampleForm/></Box>			 			},
						{	tabHeader: header_FormEditor,						tabContent: <Box className="tw-px-3">{ noContentYesJSX }</Box> 					},
						{	tabHeader: header_Forum,							tabContent: <Box className="tw-px-3">{ noContentYesJSX }</Box> 					},
						{	tabHeader: header_List,								tabContent: <Box className="tw-px-3">{ noContentYesJSX }</Box> 					},
						{	tabHeader: header_LogicEditor,						tabContent: <Box className="tw-px-3">{ noContentYesJSX }</Box> 					},
						{	tabHeader: header_Maps,								tabContent: <Box className="tw-px-3"><ExampleMaps/></Box> 						},
						{	tabHeader: header_PdfTemplateEditor,				tabContent: <Box className="tw-px-3">{ noContentYesJSX }</Box> 					},
						{	tabHeader: header_SpreadsheetTemplateEditor,		tabContent: <Box className="tw-px-3">{ noContentYesJSX }</Box> 					},
						{	tabHeader: header_Stepper,							tabContent: <Box className="tw-px-3">{ noContentYesJSX }</Box> 					},
						{	tabHeader: header_StripePayments,					tabContent: <Box className="tw-px-3">{ noContentYesJSX }</Box> 					},
						{	tabHeader: header_Tables,							tabContent: <Box className="tw-px-3"><ExampleTables/></Box> 					},
						{	tabHeader: header_Tabs,								tabContent: <Box className="tw-px-3"><ExampleTabs/> </Box>						},
						{	tabHeader: header_Timeline,							tabContent: <Box className="tw-px-3"><ExampleTimeline/></Box> 					},
						{
							tabHeader: header_Theme,
							tabContent:
							<Box className="tw-px-3">
								<Grid container spacing={1}>
									{/* Primary */}
									<Grid item xs={3}>
										<Box className="tw-p-1" sx={{ backgroundColor: themeVariables.primary_dark }}>
											<Typography sx={{ color: themeVariables.white }}>primary_dark</Typography>
											<Typography sx={{ color: themeVariables.white }}>{themeVariables.primary_dark}</Typography>
										</Box>
									</Grid>
									<Grid item xs={3}>
										<Box className="tw-p-1" sx={{ backgroundColor: themeVariables.primary_main }}>
											<Typography sx={{ color: themeVariables.white }}>primary_main</Typography>
											<Typography sx={{ color: themeVariables.white }}>{themeVariables.primary_main}</Typography>
										</Box>
									</Grid>
									<Grid item xs={3}>
										<Box className="tw-p-1" sx={{ backgroundColor: themeVariables.primary_light }}>
											<Typography>primary_light</Typography>
											<Typography>{themeVariables.primary_light}</Typography>
										</Box>
									</Grid>
									<Grid item xs={3}>
										<Box className="tw-p-1" sx={{ backgroundColor: themeVariables.primary_wash }}>
											<Typography>primary_wash</Typography>
											<Typography>{themeVariables.primary_wash}</Typography>
										</Box>
									</Grid>
									{/* Secondary */}
									<Grid item xs={3}>
										<Box className="tw-p-1" sx={{ backgroundColor: themeVariables.secondary_dark }}>
											<Typography sx={{ color: themeVariables.white }}>secondary_dark</Typography>
											<Typography sx={{ color: themeVariables.white }}>{themeVariables.secondary_dark}</Typography>
										</Box>
									</Grid>
									<Grid item xs={3}>
										<Box className="tw-p-1" sx={{ backgroundColor: themeVariables.secondary_main }}>
											<Typography sx={{ color: themeVariables.white }}>secondary_main</Typography>
											<Typography sx={{ color: themeVariables.white }}>{themeVariables.secondary_main}</Typography>
										</Box>
									</Grid>
									<Grid item xs={3}>
										<Box className="tw-p-1" sx={{ backgroundColor: themeVariables.secondary_light }}>
											<Typography>secondary_light</Typography>
											<Typography>{themeVariables.secondary_light}</Typography>
										</Box>
									</Grid>
									<Grid item xs={3}>
										<Box className="tw-p-1" sx={{ backgroundColor: themeVariables.secondary_wash }}>
											<Typography>secondary_wash</Typography>
											<Typography>{themeVariables.secondary_wash}</Typography>
										</Box>
									</Grid>
									{/* Accent */}
									<Grid item xs={3}>
										<Box className="tw-p-1" sx={{ backgroundColor: themeVariables.accent_dark }}>
											<Typography sx={{ color: themeVariables.white }}>accent_dark</Typography>
											<Typography sx={{ color: themeVariables.white }}>{themeVariables.accent_dark}</Typography>
										</Box>
									</Grid>
									<Grid item xs={3}>
										<Box className="tw-p-1" sx={{ backgroundColor: themeVariables.accent_main }}>
											<Typography sx={{ color: themeVariables.white }}>accent_main</Typography>
											<Typography sx={{ color: themeVariables.white }}>{themeVariables.accent_main}</Typography>
										</Box>
									</Grid>
									<Grid item xs={3}>
										<Box className="tw-p-1" sx={{ backgroundColor: themeVariables.accent_light }}>
											<Typography>accent_light</Typography>
											<Typography>{themeVariables.accent_light}</Typography>
										</Box>
									</Grid>
									<Grid item xs={3}>
										<Box className="tw-p-1" sx={{ backgroundColor: themeVariables.accent_wash }}>
											<Typography>accent_wash</Typography>
											<Typography>{themeVariables.accent_wash}</Typography>
										</Box>
									</Grid>
									{/* Info */}
									<Grid item xs={3}>
										<Box className="tw-p-1" sx={{ backgroundColor: themeVariables.info_dark }}>
											<Typography sx={{ color: themeVariables.white }}>info_dark</Typography>
											<Typography sx={{ color: themeVariables.white }}>{themeVariables.info_dark}</Typography>
										</Box>
									</Grid>
									<Grid item xs={3}>
										<Box className="tw-p-1" sx={{ backgroundColor: themeVariables.info_main }}>
											<Typography sx={{ color: themeVariables.white }}>info_main</Typography>
											<Typography sx={{ color: themeVariables.white }}>{themeVariables.info_main}</Typography>
										</Box>
									</Grid>
									<Grid item xs={3}>
										<Box className="tw-p-1" sx={{ backgroundColor: themeVariables.info_light }}>
											<Typography>info_light</Typography>
											<Typography>{themeVariables.info_light}</Typography>
										</Box>
									</Grid>
									<Grid item xs={3}>
										<Box className="tw-p-1" sx={{ backgroundColor: themeVariables.info_wash }}>
											<Typography>info_wash</Typography>
											<Typography>{themeVariables.info_wash}</Typography>
										</Box>
									</Grid>
									{/* Success */}
									<Grid item xs={3}>
										<Box className="tw-p-1" sx={{ backgroundColor: themeVariables.success_dark }}>
											<Typography sx={{ color: themeVariables.white }}>success_dark</Typography>
											<Typography sx={{ color: themeVariables.white }}>{themeVariables.success_dark}</Typography>
										</Box>
									</Grid>
									<Grid item xs={3}>
										<Box className="tw-p-1" sx={{ backgroundColor: themeVariables.success_main }}>
											<Typography sx={{ color: themeVariables.white }}>success_main</Typography>
											<Typography sx={{ color: themeVariables.white }}>{themeVariables.success_main}</Typography>
										</Box>
									</Grid>
									<Grid item xs={3}>
										<Box className="tw-p-1" sx={{ backgroundColor: themeVariables.success_light }}>
											<Typography>success_light</Typography>
											<Typography>{themeVariables.success_light}</Typography>
										</Box>
									</Grid>
									<Grid item xs={3}>
										<Box className="tw-p-1" sx={{ backgroundColor: themeVariables.success_wash }}>
											<Typography>success_wash</Typography>
											<Typography>{themeVariables.success_wash}</Typography>
										</Box>
									</Grid>
									{/* Warning */}
									<Grid item xs={3}>
										<Box className="tw-p-1" sx={{ backgroundColor: themeVariables.warning_dark }}>
											<Typography sx={{ color: themeVariables.white }}>warning_dark</Typography>
											<Typography sx={{ color: themeVariables.white }}>{themeVariables.warning_dark}</Typography>
										</Box>
									</Grid>
									<Grid item xs={3}>
										<Box className="tw-p-1" sx={{ backgroundColor: themeVariables.warning_main }}>
											<Typography sx={{ color: themeVariables.white }}>warning_main</Typography>
											<Typography sx={{ color: themeVariables.white }}>{themeVariables.warning_main}</Typography>
										</Box>
									</Grid>
									<Grid item xs={3}>
										<Box className="tw-p-1" sx={{ backgroundColor: themeVariables.warning_light }}>
											<Typography>warning_light</Typography>
											<Typography>{themeVariables.warning_light}</Typography>
										</Box>
									</Grid>
									<Grid item xs={3}>
										<Box className="tw-p-1" sx={{ backgroundColor: themeVariables.warning_wash }}>
											<Typography>warning_wash</Typography>
											<Typography>{themeVariables.warning_wash}</Typography>
										</Box>
									</Grid>
									{/* Error */}
									<Grid item xs={3}>
										<Box className="tw-p-1" sx={{ backgroundColor: themeVariables.error_dark }}>
											<Typography sx={{ color: themeVariables.white }}>error_dark</Typography>
											<Typography sx={{ color: themeVariables.white }}>{themeVariables.error_dark}</Typography>
										</Box>
									</Grid>
									<Grid item xs={3}>
										<Box className="tw-p-1" sx={{ backgroundColor: themeVariables.error_main }}>
											<Typography sx={{ color: themeVariables.white }}>error_main</Typography>
											<Typography sx={{ color: themeVariables.white }}>{themeVariables.error_main}</Typography>
										</Box>
									</Grid>
									<Grid item xs={3}>
										<Box className="tw-p-1" sx={{ backgroundColor: themeVariables.error_light }}>
											<Typography>error_light</Typography>
											<Typography>{themeVariables.error_light}</Typography>
										</Box>
									</Grid>
									<Grid item xs={3}>
										<Box className="tw-p-1" sx={{ backgroundColor: themeVariables.error_wash }}>
											<Typography>error_wash</Typography>
											<Typography>{themeVariables.error_wash}</Typography>
										</Box>
									</Grid>
								</Grid>

							</Box>
						},
					]} />
				</Box>
			}/>
			return pageJSX
		}

		// Render
		return <>{ returnJSX_Page() }</>

	}