//////////////////////////////////////////
//		  ooOOOO BOILERPLATE FILE		//
//		 oo		 _____					//
//		_I__n_n__||_|| ________			//
//	  >(_________|_7_|-|______|			//
//	   /o ()() ()() o   oo  oo			//
//////////////////////////////////////////

///////////////////////////////
// Description
///////////////////////////////

	/*
		DESCRIPTION / USAGE:
			Basic Data Display

		TODO:

	*/


///////////////////////////////
// Imports
///////////////////////////////

import {
	Trans
} from 'react-i18next'
import {
	TsInterface_DataViewerAdditionalData,
	TsInterface_DataViewerData,
	TsInterface_DataViewerLine
} from 'rfbp_core/components/data_viewer'
import {
	TsInterface_FormAdditionalData,
	TsInterface_FormData,
	TsInterface_FormHooksObject,
	TsInterface_FormInputs,
	TsInterface_FormSettings,
	TsInterface_FormSubmittedData,
	TsType_FormSubmission
} from 'rfbp_core/components/form'
import {
	Icon
} from 'rfbp_core/components/icons'
import {
	getProp,
	returnStringFromValue
} from 'rfbp_core/services/helper_functions'
import {
	TsType_Boolean,
	TsType_JSX,
	TsType_Number,
	TsType_String
} from 'rfbp_core/typescript/global_types'
import {
	Box,
	Typography
} from '@mui/material/'
import {
	TsInterface_DataViewerHooksObject
} from '../data_viewer_types'

///////////////////////////////
// Typescript
///////////////////////////////

interface TsInterface_DataViewerEditSettings {
	editable?: TsType_Boolean
	formInputs?: TsInterface_FormInputs
	formSubmission?: TsType_FormSubmission
	formHeader?: TsType_String | TsType_JSX
	showMissingText?: TsType_Boolean
}

///////////////////////////////
// Variables
///////////////////////////////

	// Displayed Translatable Strings
	// { sort-start } - displayed text - scoped sort plugin
	const s_EDIT: TsType_JSX =					<Trans>Edit</Trans>
	const s_MISSING: TsType_JSX =				<Trans>Missing</Trans>
	// { sort-end } - displayed text


///////////////////////////////
// Functions
///////////////////////////////


///////////////////////////////
// Exports
///////////////////////////////

	export const ViewerLineBasicData= ( label: TsType_String | TsType_JSX, propKey: TsType_String, editableSettings: TsInterface_DataViewerEditSettings ): TsInterface_DataViewerLine => {
		let viewerLine = {
			line_jsx: ( viewerData: TsInterface_DataViewerData, viewerAdditionalData: TsInterface_DataViewerAdditionalData, viewerHooks: TsInterface_DataViewerHooksObject ) => {
				let propValue = ""
				if ( propKey != null && viewerData != null && viewerData[ propKey ] != null ){
					propValue = returnStringFromValue( viewerData[ propKey ] as TsType_String | TsType_Number | TsType_Boolean )
				}
				let editIconJSX = <></>
				if(
					editableSettings != null &&
					editableSettings.editable === true &&
					editableSettings.formInputs != null &&
					editableSettings.formSubmission != null
				){
					editIconJSX =
					<Icon
						icon="pen-to-square"
						className="tw-opacity-30 hover:tw-opacity-100 tw-cursor-pointer tw-ml-2"
						tooltip={ s_EDIT }
						tooltipPlacement='right'
						onClick={ () => {
							viewerHooks.uc_setUserInterface_FormDialogDisplay({
								display: true,
								form: {
									form: {
										formAdditionalData: viewerAdditionalData,
										formData: { ...viewerData },
										formInputs: editableSettings.formInputs,
										formOnChange: ( formAdditionalData: TsInterface_FormAdditionalData, formData: TsInterface_FormData, formInputs: TsInterface_FormInputs, formSettings: TsInterface_FormSettings ) => {},
										formSettings: {},
										formSubmission: ( formSubmittedData: TsInterface_FormSubmittedData, formAdditionalData: TsInterface_FormAdditionalData, formHooks: TsInterface_FormHooksObject ) => {
											return new Promise( ( resolve, reject ) => {
												if( editableSettings.formSubmission != null ){
													editableSettings.formSubmission( formSubmittedData, formAdditionalData, formHooks ).then( ( res_FS ) => {
														resolve( res_FS )
													}).catch( ( rej_FS ) => {
														reject( rej_FS )
													})
												}
											})
										},
									},
									dialog: {
										formDialogHeaderColor: "success",
										formDialogHeaderText: <>{ getProp( editableSettings, "formHeader", s_EDIT ) }</>,
										formDialogIcon: <Icon type="solid" icon="pen-to-square"/>
									}
								}
							})
						}}
					/>
				}
				let missingTextJSX = <></>
				if(
					editableSettings != null &&
					editableSettings.showMissingText === true &&
					(
						propValue == null ||
						propValue === ""
					)
				){
					missingTextJSX =
					<Box component="span" className="tw-opacity-30 tw-italic">
						{ s_MISSING }
					</Box>
				}
				let lineJSX =
				<Typography className="tw-ml-4">
					<Box component="span">{ label }: </Box>
					<Box component="span" className="tw-opacity-50">{ propValue }</Box>
					{ missingTextJSX }
					{ editIconJSX }
				</Typography>
				return lineJSX
			},
			line_css: ( viewerData: TsInterface_DataViewerData, viewerAdditionalData: TsInterface_DataViewerAdditionalData ) => { return "" }
		}
		return viewerLine
	}