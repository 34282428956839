//////////////////////////////////////////
//		  ooOOOO BOILERPLATE FILE		//
//		 oo		 _____					//
//		_I__n_n__||_|| ________			//
//	  >(_________|_7_|-|______|			//
//	   /o ()() ()() o   oo  oo			//
//////////////////////////////////////////

///////////////////////////////
// Description
///////////////////////////////

	/*
		DESCRIPTION / USAGE:
			Header for displays

		TODO:

	*/


///////////////////////////////
// Imports
///////////////////////////////


import {
	TsInterface_DataViewerAdditionalData,
	TsInterface_DataViewerData,
	TsInterface_DataViewerLine
} from 'rfbp_core/components/data_viewer'
import {
	TsType_JSX,
	TsType_String
} from 'rfbp_core/typescript/global_types'
import {
	Typography
} from '@mui/material/'
import {
	TsInterface_DataViewerHooksObject
} from '../data_viewer_types'

///////////////////////////////
// Typescript
///////////////////////////////


///////////////////////////////
// Variables
///////////////////////////////

	// Displayed Translatable Strings
	// { sort-start } - displayed text - scoped sort plugin

	// { sort-end } - displayed text


///////////////////////////////
// Functions
///////////////////////////////


///////////////////////////////
// Exports
///////////////////////////////

	export const ViewerLineHeader = ( header: TsType_String | TsType_JSX ): TsInterface_DataViewerLine => {
		let viewerLine = {
			line_jsx: ( viewerData: TsInterface_DataViewerData, viewerAdditionalData: TsInterface_DataViewerAdditionalData, viewerHooks: TsInterface_DataViewerHooksObject ) => {
				return <Typography variant="h6" className="tw-font-bold">{ header }</Typography>
			},
			line_css: ( viewerData: TsInterface_DataViewerData, viewerAdditionalData: TsInterface_DataViewerAdditionalData ) => { return "" }
		}
		return viewerLine
	}