///////////////////////////////
// Description
///////////////////////////////

	/*
		DESCRIPTION / USAGE:
			containers are pages / views used in the app and are made up of components and can interact with services and models

		TODO:

	*/


///////////////////////////////
// Imports
///////////////////////////////

// @ts-expect-error
import logoSrc from 'app/pages/pirate_game/assets/logo.png'
import {
	useContext,
	useEffect,
	useReducer,
	useState
} from 'react'
import {
	Trans
} from 'react-i18next'
import {
	useNavigate,
	useParams
} from 'react-router-dom'
import {
	ApplicationPages
} from 'rfbp_aux/data/application_structure'
import {
	DatabaseRef_Game_Document
} from 'rfbp_aux/services/database_endpoints/games'
import {
	Context_UserInterface_ErrorDialog
} from 'rfbp_core/services/context'
import {
	DatabaseGetLiveDocument,
	DatabaseSetMergeDocument
} from 'rfbp_core/services/database_management'
import {
	generateRandomString
} from 'rfbp_core/services/helper_functions'
import {
	TsInterface_UnspecifiedObject,
	TsType_Any,
	TsType_Boolean,
	TsType_JSX,
	TsType_String,
	TsType_Void,
	TsType_VoidFunction
} from 'rfbp_core/typescript/global_types'
import {
	Box,
	Button,
	FormControl,
	TextField,
	Typography
} from '@mui/material/'
import Grid2 from '@mui/material/Unstable_Grid2'

///////////////////////////////
// Typescript
///////////////////////////////


///////////////////////////////
// Variables
///////////////////////////////

	// Authenticated Nav Data

	// Displayed Translatable Strings
	// { sort-start } - displayed text - scoped sort plugin
	const s_FAILED_TO_JOIN_GAME: TsType_JSX = 		<Trans>Failed to join game</Trans>
	const s_SET_SAIL: TsType_JSX = 					<Trans>Set Sail</Trans>
	const s_SIGN_UP: TsType_JSX = 					<Trans>Sign Up</Trans>
	const s_TRY_AGAIN: TsType_JSX = 				<Trans>Try again</Trans>
	const se_ENTER_YOUR_NAME: TsType_String = 		"Enter your name"
	// { sort-end } - displayed text


///////////////////////////////
// Functions
///////////////////////////////


///////////////////////////////
// Container
///////////////////////////////

	export const Container: React.FC = (): TsType_JSX => {

		// Props
		const params = useParams()
		const gameKey: TsType_String = params.id as TsType_String

		// Hooks - useContext, useState, useReducer, other
		// { sort-start } - hooks
		const [ us_newUserName, us_setNewUserName ] = 			useState<TsType_String>("")
		const [ us_rootGame, us_setRootGame ] = 				useState<TsInterface_UnspecifiedObject>({})
		const [ us_submittingForm, us_setSubmittingForm ] = 	useState<TsType_Boolean>(false)
		const [ us_userKey, us_setUserKey ] = 					useState<TsType_String>("")
		const un_routerNaviation = 								useNavigate()
		const ur_forceRerender = 								useReducer(() => ({}), {})[1] as () => TsType_Void
		const { uc_setUserInterface_ErrorDialogDisplay } = 		useContext( Context_UserInterface_ErrorDialog )
		// { sort-end } - hooks

		// Hooks - useEffect
		useEffect(() => {
			let userKey = generateRandomString( 5, null )
			us_setUserKey( userKey )
		}, [])

		useEffect(() => {
			let unsubscribeLiveData: TsType_VoidFunction
			const updateLiveData = ( newData: TsInterface_UnspecifiedObject ) => {
				us_setRootGame( newData )
				ur_forceRerender()
			}
			unsubscribeLiveData = DatabaseGetLiveDocument( DatabaseRef_Game_Document( gameKey ), updateLiveData )
			return () => {
				if (typeof unsubscribeLiveData === 'function'){
					unsubscribeLiveData()
				}
			}
		}, [gameKey, ur_forceRerender])

		// Other Variables

		// Functions
		const joinGame = () => {
			// Generate User Key
			us_setSubmittingForm( true )
			// See if the user key already exists
			if(
				us_rootGame.players == null ||
				us_rootGame.players[ us_userKey ] == null
			){
				let updateObject: TsInterface_UnspecifiedObject = {
					players: {
						[ us_userKey ]: {
							name: us_newUserName,
							key: us_userKey,
							timestamp_joined: new Date().getTime()
						}
					}
				}
				DatabaseSetMergeDocument( DatabaseRef_Game_Document( gameKey ), updateObject, {} ).then( ( res_DSMD ) => {
					un_routerNaviation( ApplicationPages.PirateGameMobileGamePage.url( gameKey, us_userKey ) )
					us_setSubmittingForm( false )
				}).catch( ( rej_DSMD ) => {
					uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
					us_setSubmittingForm( false )
				})
			} else {
				uc_setUserInterface_ErrorDialogDisplay({ display: true, error: {
					message: s_FAILED_TO_JOIN_GAME,
					details: s_TRY_AGAIN,
					code: "ER-D-PMJ-JG-01"
				}  })
				us_setSubmittingForm( false )
			}
		}

		// Call Functions

		// JSX Generation
		const returnJSX_Page = (): TsType_JSX => {
			let pageJSX =
			<Box
				sx={{
					height: "100vh",
					width: "100%",
					backgroundColor: "#000"
				}}
			>
				<Box
					className="tw-p-4"
					sx={{
						width: "100%",
						height: "100vh",
						background: "#71482E",
					}}
				>
					<Box
						sx={{
							maxWidth: "500px",
							margin: "auto",
						}}
					>
						<Box
							sx={{
								maxWidth: "500px",
								textAlign: "center",
								margin: "auto",
								marginTop: "15vh"
							}}
						>
							<img
								src={ logoSrc }
								alt=""
								loading="lazy"
								width={"100%"}
							/>
						</Box>
						<Grid2 container>
							<Grid2 xs={12} sm={12} md={12} lg={12} className="tw-inline-block" sx={{ background: "#F5D3A0" }}>
								<Box className="tw-text-center tw-py-2">
									<Typography className="font-pirata-one" sx={{ color: "#000", fontSize: "32px" }}>
										{ s_SIGN_UP }
									</Typography>
								</Box>
								<Box className="tw-px-2">
									<FormControl  fullWidth>
										<TextField
											sx={{ background: "rgba(113, 72, 46, .4)" }}
											color="primary"
											value={ us_newUserName || "" }
											margin="normal"
											placeholder={ se_ENTER_YOUR_NAME }
											onChange={ (event: TsType_Any) => {
												if ( event != null && event.target != null && event.target.value != null ){
													us_setNewUserName( event.target.value )
												}
											} }
											variant="outlined"
											InputLabelProps={{ shrink: true }}
										/>
									</FormControl>
								</Box>
								<Box className="tw-text-center tw-py-2">
									<Button
										variant="contained"
										color="primary"
										disabled={ us_newUserName.length < 3 || us_submittingForm || us_rootGame.status !== "new" }
										onClick={ () => {
											joinGame()
										}}
									>
										{ s_SET_SAIL }
									</Button>
								</Box>
							</Grid2>
						</Grid2>
					</Box>
				</Box>
			</Box>
			return pageJSX
		}

		// Render
		return <>{returnJSX_Page()}</>

	}